//
// Quote container
//-------------------------------------
body.quote {
    .customer-quote-content {
        .quote-container {
            .confirmation-container {
                .step-headers-container {    
                    /*styles on parent containers are different on confirmation page, so we need to override with this
                    so that the page width does not go over 100%*/                
				    left: 0;
				    padding-left: 15px;
				    padding-right: 15px;
                }
            }
        } 
    } 
}
	//
	// Quote Confirmation
	//-------------------------------------
	.confirmation-container{
	
        .form-header-column {
            /* the form headline and 'show documents' button cannot reside side-by-side because there
            isn't enough horizontal room and the button spills into the gutter which causes the viewport to exceed
            100% of device width (causes issues for sticky header). See PL-16402 and PL-14075 */
            @include make-col-ready();
            @include make-col(12);
            order: 2;
        }
        
        .show-documents-button-column {
            /* the form headline and 'show documents' button cannot reside side-by-side because there
            isn't enough horizontal room and the button spills into the gutter which causes the viewport to exceed
            100% of device width (causes issues for sticky header). See PL-16402 and PL-14075 */
            @include make-col-ready();
            @include make-col(12);
            order: 1;
	    }

		h1 {

			.confirmation-success-check {

				font-size: $confirmation-success-check-font-size;
				margin-right: .3em;
			}
		}

		//
		// Panel Section
		//-------------------------------------
		.confirmation-panel-section {			

	   		.panel {

	   			border-radius : 5px;
	   			border-width : 2px;

	   			.panel-heading {

	   				font-weight: 600;
	   				padding: 5px;
	   			}

	   			.panel-body {

	   				color: $confirmation-panel-body-color;
	   				min-height: 64px;

	   				a {

	   					&.id-cards {

	   						cursor:				pointer;
							text-decoration:	underline;

							&.disabled {
								cursor:				none;
								text-decoration:	none;
							}
	   					}
	   				}
	   			}
	   		}

	   		@include media-breakpoint-up(lg) {
                .flex {
                    display: flex;
                }

                .flex-row {
                    display: flex;
                    flex-direction: row;
                }

                //Width needs to be 100% for ie to know how to use it properly.
	            .flex-col {
	                display: flex;
	                flex-direction: column;
	                width: 100%;

	                .panel-heading {
	                    flex: 1 0 auto;
	                }

	                .panel-body {
	                    display: flex;
	                    flex: 2 0 auto;
	                    align-items: center;
	                    justify-content: center;
	                    width: 100%;

	                    //This is for ie 10 to wrap the text properly.
	                    span {
	                        -ms-flex: 0 1 auto;
	                        display: block;
	                    }
	                }
	            }
	   		}
		}

		//
		// Steps Section
		//-------------------------------------
		.confirmation-steps {

			margin-bottom: 25px;

			.media {

				border: 2px solid $confirmation-step-media-border;

				.media-middle {

					background : $confirmation-step-media-background;

					.label-circle {

						font-size: 18px;
						color : $confirmation-step-media-circle-color;
					}
				}

				.media-body {

					padding: 15px 10px;

					h4 {

						&.media-heading {

							color: $confirmation-step-media-heading-color;
							font-weight : 600;
							padding-left: 0;
							font-size: 1.6rem;
						}
					}

					a:not(.icon-QTE-tooltip) {
						cursor: pointer;
						text-decoration: underline;
					}

					.enrollment-info-header {
						font-weight : bold;
					}
				}
			}
		}

		//
		// Renters insurance information
		//-------------------------------------
		.renters-insurance {
			
			i.fa {
				font-size: 8em;
			}

			p.disclaimer {
			 font-style: italic;
			 font-size: 12px;
			}
		}

		//
		// Confirmation details
		//-------------------------------------
		.confirmation-list-details {

			.list-group {

				.list-group-item {

					border-color: $confirmation-list-border;
				    border-style: solid none none;
				    border-width: 1px 0 0;
				    padding-bottom: 15px;
	    			padding-top: 15px;
	    			padding-left: 0;
					padding-right: 0;

					&.active {

						font-weight : 600;
						border-radius : 5px;
						border-color: $confirmation-list-border-active;
						font-size: 18px;
						padding: 10px 15px;
					}
				}
			}
		}

		.confirmation-list-offset {
			margin-left: 20px;
		}

		//
		// Confirmation disclaimer content
		//-------------------------------------
		.confirmation-disclaimer-content {

			hr {

				border-width: $confirmation-disclaimer-content;

			}
		}
	}

	//
	// Quote Affix
	//-------------------------------------
	.quote-affix {

		.quote-sidebar {

			h4 {
				font-size: $quote-sidebar-h4-font-size;
			}

			.no-data {
				font-size: 14px;
			}

			.print-all-button-container {
				text-align: $print-all-button-container-text-align;
			}

			button {

				cursor:				pointer;

				&.form-link {

					color: $confirmation-forms-color;
					display: block;
					margin-top: 5px;
					margin-bottom: 5px;
					text-align: left;
					font-size: 15px;
					padding-top: 5px;
					padding-bottom: 5px;
					white-space: inherit;
				}

				&.form-link-print-all {

					color: 				$confirmation-forms-print-all;
					font-size: 			$confirmation-forms-font-size;
					line-height: 		1.5;
					padding: 			$confirmation-forms-padding;

					&:hover {

						color:			$confirmation-forms-print-all-hover;
					}
				}
			}

			a {
				text-decoration: underline;
				color: $confirmation-forms-color;
		}

			.forms-response-message {
				display: block;
    			margin-top: 5px;
    			margin-bottom: 5px;
    			text-align: left;
    			font-size: 14px;
	}
		}
	}

.modal {

	.pending-discounts-followups {

		dt {

			padding-bottom: 5px;
		}

		dd {

			padding-bottom: 5px;
		}
	}
}

.language-preference-row{
	margin-top: 15px;
}