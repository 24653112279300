//---------------------------------
// Typography
//---------------------------------
$quote-affix-quote-number-font-size: 18px !default;
$quote-affix-list-font-size:         10px !default;
$font-size-h1:                       35px !default;
$quote-sidebar-h4-font-size:         $font-size-h1 !default;

//---------------------------------
// Form Controls
//---------------------------------
$quote-question-letter-spacing: normal !default;
$formquestion-copy-fontsize: 15px !default;
$dynamic-repeatable-padding-left: 26px !default;
$dynamic-repeatable-font-size: $formquestion-copy-fontsize !default;
$dynamic-repeatable-font-family: inherit !default;

//---------------------------------
// Splash Screens
//---------------------------------
$splash-h2-color: $primary !default;
$splash-input-readonly-opacity: .5 !default;
$splash-h2-text-shadow: 2px 2px 2px rgba(0, 0, 0, 1) !default;
$summary-cards-sm-min-height: 70px !default;
$border-width: 1px !default;
$container-edit-delete-position: absolute;
$card-edit-delete-padding: 0px !default;

//---------------------------------
// AJAX
//---------------------------------
$ajax-loading-width-xs: 330px !default;
$empty-select-color: #999999 !default;
$btn-add-entity-color: #333333 !default;

//---------------------------------
// Buttons
//---------------------------------
$toggle-button-width: 50px !default;
$btn-radio-default-bg-color-active-box-shadow:	transparent !default;
$btn-radio-active-box-shadow: inset 0px 0px 4px 0px $btn-radio-default-bg-color-active-box-shadow !default;
$has-error-button-group-height: 56px !default;

//---------------------------------
// Footer
//---------------------------------
$footer-item-padding-left: 10px !default;
$footer-item-padding-right: 10px !default;
$footer-anchor-text-decoration: underline !default;

//---------------------
// Nav
//---------------------
$contact-us-button-font-size: 13px !default;
$contact-us-button-padding-xs: 0 !default;
$navbar-bg: $primary !default;
$navbar-max-height-mobile: 85px !default;
$navbar-padding-top-md: 2.1rem !default;
$navbar-padding-bottom-md: 1.15rem !default;
$navbar-padding-bottom: $navbar-padding-y !default;
$referral-agency-margin-left: auto !default;
$referral-agency-font-size: 0.875rem !default;
$navbar-contact-column-flex-direction: column !default;
$navbar-transition: all 0.2s ease !default;

//---------------------------------
// Sidebar
//---------------------------------
$quote-affix-sidebar-item-margin-top: 15px !default;
$quote-affix-sidebar-ul-margin-left: 15px !default;
$quote-affix-sidebar-list-data-color: #ffffff !default;
$quote-affix-sidebar-active-list-data-color: $quote-affix-sidebar-list-data-color !default;
$quote-affix-sidebar-item-padding-left: 20px !default;
$quote-affix-sidebar-item-padding-top: 5px !default;
$quote-affix-sidebar-item-padding-bottom: 5px !default;
$quote-affix-xs-sm-btn-close-summary-right: 30px !default;
$quote-affix-xs-sm-btn-close-summary-top: 10px !default;
$quote-affix-sidebar-purcahse-summary-h4-font-size: 32px !default;
$confirmation-forms-font-size: 12px !default;
$confirmation-forms-padding: 5px 10px !default;

//----------------------------
// Payment
//----------------------------
$payment-iframe-height: 1125px !default;
$payment-iframe-height-lg-up: 570px !default;
$form-add-btn-align: middle !default;

//----------------------------
// Colors for Coverages
//----------------------------
$disable-btn-color: #6e869d !default;
$primary-medium-gray: #A1A1A1 !default;

//--------------------------
// Coverage Page
//--------------------------
$coverage-form-margin-right-sm: -30px !default;
$coverage-form-margin-left-sm: -5px !default;
$coverage-form-margin-right: -25px !default;

//-----------------
// Final Sale Page
//-----------------
$paperless-checkbox-addon-width: 140px !default;
$bill-reminder-checkbox-addon-width: 150px !default;
$bill-reminder-select-addon-width: 150px !default;
$docnotifications-checkbox-addon-width: 200px !default;
$account-settings-custom-control-position-fix-top: 3px !default;
$container-iframe-height: 1575px !default;
$container-iframe-height-md: 1075px !default;
$selected-indicator-icon-width: 30px !default;
$selected-indicator-icon-height: 30px !default;
$selected-indicator-icon-width-lg: 30px !default;
$selected-indicator-icon-height-lg: 30px !default;

//-----------------
// Driver page
//-----------------
$incidents-info-border: 1px solid #666 !default;

//------------------------
// Driver Assignment page
//------------------------
$driver-assignment-font-size: 18px !default;
$driver-assignment-font-family: $font-family-sans-serif !default;

//------------------
// Page scroll
//------------------
$scrolled-phone-font-size: 0.9375rem !default;
$scrolled-padding-top: 1.085rem !default;
$scrolled-padding-bottom: 1.085rem !default;
$scrolled-max-height: 65px !default;

//-----------------
// Modal
//-----------------
$modal-content-min-height: 0 !default;

//-----------------
// Effective Date
//-----------------
$icon-calendar-font-size: 27px !default;
$icon-calendar-right: 18px !default;
$icon-calendar-top: 2px !default;

//-----------------
// Post Bind page
//-----------------
$preference-enrollment-checkbox-top:    -1rem !default;
$preference-enrollment-checkbox-left:   -1.1rem !default;