//
// Coverages Page Styles 
//------------------------------

.coverage-form {

    .row-form-header {
        @include make-row();
        
        .coverage-form-header-headline-container {
            @include make-col-ready();
            @include make-col(8);

            @include media-breakpoint-up(sm) {
                @include make-col(9);
            }
        }

        .coverage-form-header-summary-button-container {
            @include make-col-ready();
            @include make-col(4);

            @include media-breakpoint-up(sm) {
                @include make-col(3);
            }        
        }
    }

    .offering-button {
        
        @include make-row();

        .offering-label {
            
			@include make-col-ready();
			@include make-col(6);
            
            &.customized {
				@include make-col(12);
            }

			@include media-breakpoint-up(md) {
				@include make-col(12);
			}
        }
    }

    .summary-cards-group {

        @include make-row();

        .nav-item {

			@include make-col-ready();
			@include make-col(12);
			@include media-breakpoint-up(md) {
				@include make-col(6);
			}
			@include media-breakpoint-up(lg) {
   	 			@include make-col(3);
  			}
        }
    }

    .multiterm-label-duplicate {
        display: none;
    }
}

.customize-package-container {
	.customize-package-image {
		max-height: 100px;
		margin: 20px 0;
	}
	.customize-package-name {
		font-weight: bold;
	}
}
.coverage-container {

	.golf-cart-disclaimer {
		display: none;
	}

	.hideVehicleCoverages {
		.lien-coverage-suggestion, .coverage-section-header-container {
			display: none;
		}

		.golf-cart-disclaimer {
			display: block;
		}
	}

	.edit-coverage-term-container {

		display	: none;

		.form-check-inline {
			display				: block;
		    position			: relative;
		    margin-bottom		: 15px;
		    cursor				: pointer;
		    font-size			: 15px;
		    user-select			: none;
		    padding-left		: 0;
		    color				: $primary-dark-gray;
		}

		/* Hide the browser's default radio button */
		.form-check-inline input {
		    position			: absolute;
		    opacity				: 0;
		    cursor				: pointer;
		}

		.label-circle {
			background-color	: #FFFFFF;
		  	border-radius		: 50px;
		  	border-style		: solid;
		}

		/* Create a custom radio button */
		.icon-radio-fab {
    		background-color	: #FFFFFF;
		    width				: 1.5em;
		    height				: 1.5em;
		    border-color		: $primary-medium-gray;
		    margin-right		: 11px;
		}

		/* Create the indicator (the dot/circle - hidden when not checked) */
		.label-circle:after {
			content			: "";
			position		: absolute;
			display			: none;
		}

		.form-check-inline input:checked ~ .label-circle:after {
			display		: block;
			content		: "\e90d";
			color		: $primary-dark-blue;
			top 		: -6px;
		}
		
		.form-check-inline input:checked ~ .icon-radio-fab {
			background-color	: $secondary-yellow;
			border-color		: $secondary-yellow;
		}

		.term-premium-amount {
			font-size	: 14px;
    		color		: $primary-dark-gray;
		}

		.select-coverage-premium-label-container{
			padding-left	: 18px;
			padding-right	: 10px;
			padding-top		: 21px;
			padding-bottom	: 15px;
		}

		.edit-coverage-term-wrapper {
			@include media-breakpoint-down(md) {
	    		padding-left	: 18px;
				padding-right	: 10px;
			}
			@include media-breakpoint-up(md) {
				padding-left: 40px;
			}
		}
	}

	.edit-coverage-term-button-container{

		padding-right	: 25px;
		padding-top		: 55px;
		padding-bottom	: 25px;
		display			: none;

		@include media-breakpoint-down(md) {
			padding-top		: 30px;
		}

		.edit-coverage-term-cancel {
			font-weight		: bold;
		    font-size		: 15px;
		    color			: $secondary;
		    padding-top		: 15px;
			vertical-align	: middle;
			cursor			: pointer;
			padding			: 6px 12px;
		}

		.edit-coverage-term-clear-all-term {
			font-weight		: bold;
		    font-size		: 15px;
		    color			: $secondary;
		    padding-top		: 15px;
		    padding-left	: 18px;
		    vertical-align	: middle;
		    cursor			: pointer;
		    display			: inline-block;
		    @include media-breakpoint-down(md) {
   				padding-right	: 0;
   				padding-top		: 45px;
   				float			: right;
			}
		}

		.edit-coverage-term-update, .edit-coverage-term-next {
			font-size		: 15px;
			height			: 48px;
			width			: 95px;
		}

		.disabled {
			background-color	: $disable-btn-color;
			color				: $primary-button-color;
			border-color		: $disable-btn-color;
		}
		
		.cancel-text {
			padding-right	: 17px;
		}
	}

	.edit-link-container {
		display			: inline-block;
		vertical-align	: top;
		width			: 35px;
	}

	.edit-offering-coverage-link-container {
		display			: inline-block;
		width			: 35px;
	}

	.edit-coverage-link, .edit-offering-coverage-link {
		text-decoration	: none;
		cursor			: pointer;
		font-size		: 15px;
		font-weight		: bold;
		color			: $quote-coverage-edit-link-color;
		padding-left	: 0;
    	padding-top		: 8px;
		display			: inline-block;

		@include media-breakpoint-down(sm) {
			padding-top: calc(#{$input-padding-y} + #{$input-border-width});
			line-height: 1.5;
		}
	}

    .coverage-term-input {
    	border			: 0;
    	box-shadow		: none;
    	width			: 140%;
    	font-size		: 15px;
		color			: $primary-dark-gray;
    	@include media-breakpoint-up(md) {
    		padding: 6px 0;
		}
    }

	.state-minimum-container {
		.min-required {
			padding-left 	: 42px;
			color			: $primary-medium-gray;
		}
	}

	.min-required-container{
		padding-left 	: 40px;
	}

	.static-coverage-detail	{
		@include media-breakpoint-down(md) {
			.tooltip-and-required {
				top : 0;
			}

			.coverage-header {
				padding-bottom: 0px;
			}

			.readonly-coverage-selection {
			    padding-left: 15px;
                padding-right: 15px;
			}
		}
		/* coverage terms are children of coverages and should pad out a bit all screen except small */
		.coverage-term {
			@include media-breakpoint-down(md) {
				padding-left: 0px;
			}
		}
		.offering-coverage-term-wrapper {
			padding-top	: 7px;
			span {
				font-size	: 15px;
			}
		}
		.state-minimum-wrapper {
			span {
				color	: $primary-medium-gray;
			}
		}
		.offering-premium-amount-wrapper {
			padding-top	: 7px;
			span {
				font-size	: 15px;	
			}
		}
	}

	.edit-coverage-row {

		.multiple-term-edit-message-container {
			padding-bottom	: 22px;
		}

		.multiple-term-edit-message-wrapper {
			border		: 2px solid $primary-dark-blue;
    		padding		: 10px 0;
		}

		.multiple-term-edit-message {
			font-weight		: bold;
    		padding-left	: 20px;
    		display			: inline-block;
    		color			: $primary-dark-blue;
		}

		.coverage-options{
			@include media-breakpoint-down(md) {
				padding-left	: 0;
				padding-right	: 0;
			}
		}

		.select-empty-value {	
			color : $primary-dark-gray;
		}
		@include media-breakpoint-down(sm) {
			.tooltip-and-required {
				top : 0;
			}
		}
		.not-available {
			padding-left	: 4px;
			font-size 		: 14px;
    		color			: $primary-dark-gray;
    		@include media-breakpoint-down(sm) {
    			padding-left	: 15px;
    		}
		}
		
		/* coverage terms are children of coverages and should pad out a bit all screen except small */
		.coverage-term {
			/* bootstrap text-aligns this right but we want it left */
			text-align: left;

			padding-left: 0px;

			@include media-breakpoint-up(sm) {
				/* differential this label from the coverage label */
				padding-left: 20px;
			}

			/* bootstrap labels are strong but styleguide dictates these coverage term labels should not be */
			font-weight: normal;
		}

		.edit-view {
			background-color	: #EBECED;
			border-radius		: 0;
			margin-bottom		: 26px;
			@include media-breakpoint-down(sm) {
				margin-bottom		: 31px;
			}

			.coverage-term {
				font-weight	: bold;
				padding-top	:	0;
			}

			.coverage-term-view-container {
				display	: none;
			}

			.edit-coverage-term-container, .edit-coverage-term-button-container, .edit-coverage-term-header{
				display	: block;
			}

			.coverage-row {
				padding-top	: 15px;
			}

			.coverage-term-wrapper{
				@include media-breakpoint-down(sm) {
					padding-left	: 18px;
					padding-right	: 15px;
				}
			}

			.tooltip-and-required {
				top: -10px;
			}
		}

		.edit-coverage-term-header {
			display	: none;
		}

		.yourpremium-header {
			padding-left	: 20px;
		}
	}

	.coverage-row {
		padding-top	: 0;
	}

	.coverage-term-view-container {
		display	: block;
		.controls {
			display	:inline-block;
		}
	}

	#quote-coverage-layout-form,
	#quote-confirmcoverages-layout-form {
		.calculate-coverage-button.disabled,
		.continue-billplan-purchase.disabled {
			background-color	: $disable-btn-color;
			color				: $primary-button-color;
			border-color		: $disable-btn-color;
		}

		
	}

	/* follow the same disabling logic for the "find an agent" button, but reverse the 
	scheme compared to the Online/Recalculate buttons, as they are the call to action */
	.findanagent-button-container {
		.findanagent-button-coverages.disabled {
			background-color	: $primary-button-color;
			color				: $disable-btn-color;
			border-color		: $disable-btn-color;
		}
	}


	#coverage-billplan-container, .coverage-billplan-container {
		h3 {
			margin-top:0;
			span {
				display:block;
				margin-top: 8px;
				margin-bottom: 0;
			}
		}
	}

	/* coverage offering buttons at the top of the page */
	#offering-buttons {

		input[type="radio"][name="offeringName"] {
			position: absolute;
			opacity: 0;
		}
		
		/* offering button option at top of page */
		.offering-button {
			  outline:none;
			/* bootstrap gives button groups a border radius to the first and last button options, we don't want that for these buttons */
			border-radius: 0px;
			box-shadow: none;
			
			/* checkmark icon that shows for the selected offering */
			.card-selected {
				bottom: 6px;
			}
			
			/* we want to wrap text */
			.summary-card {
				white-space: normal;
				padding-bottom: 20px;
				padding-left: 3px;
				padding-right: 3px;
				min-height: 80px;
			}
		}
	}

	.coverage-form {
		/* offset the padding that is set on the quote container */
		margin-right: $coverage-form-margin-right;
		
		/* The below margin is used for mobile screens to align the 
		form with the header and footer */
		@include media-breakpoint-down(sm) {
			margin-right: $coverage-form-margin-right-sm;
    		margin-left: $coverage-form-margin-left-sm;
		}
	}
	
	#offering-accordion {

		.vehicle-coverages {
			.readonly-coverage-selection {
				.coverage-row:not(:first-child) {
					padding-top: 15px;
				}
			}
		}
		
		.panel-title {
			font-size: $font-size-base;
			@include media-breakpoint-up(md) {
				font-size: $font-size-h2;
			}				
		}
		
		.offering-panel {
		
			border-top: 0;
			box-shadow: none;
			background-color: transparent;
			
			@include media-breakpoint-up(md) {
				/* on desktop view the collapse doesn't look like an accordion so we remove the bottom margin */
				margin-bottom: 0;
			}
			
			.checkmark-container {
				position: absolute;
				top: -5px;
				display: none;
			}
			&.active {
				.checkmark-container {
					display:block;
					color: $secondary-orange;
				    font-size: 30px;
				}
				
				.panel-heading {
					border-color: $secondary-orange;
				}
			}
			
			.reset-coverage-container {
				margin-top: 5px;

				.reset-coverage-link {
					text-decoration: underline;
					color: $link-reset-coverage;
					cursor: pointer;
					font-size: 16px;
					
					i {
						text-decoration: none;
						margin-right: 5px;
					}
				}
			}

			
			
			.panel-heading {
			
				cursor: pointer;
				position: relative;
				border: 3px solid $brand-primary;
				border-radius: 5px;
				box-shadow: none;
				padding: 10px 0;
			}
		}
		
	}
	
	/* on the you choose option the select inputs make the content height taller than the read only equivilants, so we will add the min-height to these so the heights match as the user tabs between offerings */
	.readonly-coverage-selection {
		min-height: 31px;
	}
	
	/* static coverage details content section */
	.static-coverage-detail {
		margin-bottom: 15px;

		&[data-coverage-name="NSASpecialEquipCompCov"] {
			display: none;
		}
	}

	/* on the verified rate info there's are labels we want to show/hide */
	.verifiedrate {
		display:none;
	}
	&.confirm-coverages {
		.quoterate {
			display:none;
		}
		.verifiedrate {
			display:inline;
		}
	}
	
	/* per marketing: Increase spacing after copy to give a little more room around the buttons */
	.top-actions {
		margin-top: 6px;
	}
	

	/* in between the header content section and the form there is a border */
	.coverage-category-label {
		border-top: $coverage-category-label-border-top;
		padding-top: 20px;
	}
	
	/* labels that tell what the columns are (Coverage Type/Min Required/Coverages) */
	.coverage-section-heading {
		padding-top: 		10px;
		padding-bottom: 	10px;
		
		/* typography alignment per style guide */
		font-size: $formheader-fontsize;
		color: $formheader-color;
		background-color: $formheader-bg-color;
		font-weight: $formheader-weight;
	}
	
	#coverage-billplan-container,
	.down-today-content,
	.coverage-billplan-container {
		h2 {
			margin-top: 0;
		}
	}
	
	/* header for all coverage terms of a particular category */
	.coverage-header {
		
		/* horizontal defaults text-alignment to right, we need it to be left alignment*/
		text-align: left;
		/* consistent margin-bottom with form-group margin-bottom */
		margin-bottom: 15px;
	}
	
	/* coverage terms are children of coverages and should pad out a bit */
	.coverage-term {
		/* bootstrap text-aligns this right but we want it left */
		text-align: left;
		
		@include media-breakpoint-up(md) {
			/* differential this label from the coverage label */
			padding-left: 20px;			
		}
		
		/* bootstrap labels are strong but styleguide dictates these coverage term labels should not be */
		font-weight: normal;
	}
	
	/* per requirements we don't show any optional indication on the coverages page */
	.optional-indicator {
		display: none;
	}

	/* certain inputs are disabled in a special manner that causes a label to show "Not available" */
	.is-disabled {
		.not-available {
			display				: block;
		}
		.available {
			display				: none;
		}
	}	
	.not-available {
		display					: none;
	}
	
	/* we will force the coverage row to be relatively position so the tooltip help messages will display
	absolutely to that rather than the column */
	.coverage-row {
		position: relative;
	}		
	.coverage-column {
		position: relative;
	}
	//ToolTips for the Coverage Page
	.tooltip-and-required{
		z-index: 5;
		left: auto;

		@include media-breakpoint-down(sm) {
			left: 100%;
			top: 0;
		}
	}

	.policy-coverages {
		.lien-coverage-suggestion {
			display			: none;
		}

		.coverage-category-label {
			@include media-breakpoint-down(sm) {
				padding-bottom	: 15px;
			}
		}
		
	}
	
	.minimum-requirements-link {
		padding-top		: 23px;
		text-align		: right;
	}
	
	//remove all light colored no value selections for coverages.
	.select-empty-value {	
		color: $input-color;
		option {
			color: $input-color;
			&[value=""] {
				color: $input-color;
			}
		}
	}

	//need to hide select drop down carat
	.controls {
		overflow : hidden;
	}
}
    
/* this minimum required label header doesn't display for vehicle collection set groups */
.vehicle-coverages {
    .minimum-required {
        display:none;
    }
    
    /* vehicle name headers */
    .coverage-entity-label {
        font-weight:    $tooltip-header-weight;
        font-size:      $tooltip-header-fontsize;
        color:          $tooltip-header-color;
    }
}