.navbar {
    &.scrolled {
        @include media-breakpoint-down(md) {
            padding-top: $scrolled-padding-top;
            padding-bottom: $scrolled-padding-bottom;
            max-height: $scrolled-max-height;
            .navbar-main {
                .navbar-logo-column { 
                    @include make-col(5);
                }   
                .navbar-contact-column {
                    @include make-col(7);
                }
                
                .navbar-main-content {
                    a.btn-pl-phone {
                        font-size: $scrolled-phone-font-size;
                    }
                }
            }        
        }
    }
}