//
// Quote Summary
//------------------------------
#summary-accordion {
	border : $accordion-border;
}
.panel-group .panel.quote-summary-section,
.quote-summary-section {
	
	padding-bottom : 0;
	margin-top:0;
	border:0;
	border-top: $accordion-border;
	border-bottom: 2px solid $gray-lighter;
	
	border-radius: 0;
	
	.add-entity-row {
		padding-bottom: 16px;
		padding-top: 10px;
	}	
	.accordion-header {
		margin-left			: 0;
		margin-right		: 0;
		padding: 8px 0;
	}
	
	//Driver, Vehicle Quote Summary Table
	.table-accordion-summary {
	    .column-edit-delete{
	        width: 1%;
	        button{
	            background-color: transparent;
	        }
	    }
	    thead tr{
	        font-weight: bold;
	    }
	    .vehicle-info-header{
    	    li {
    	        &:not(:last-child){
                    border-right:solid 1px #DEDEDE;
    	        }
    	    }
	    }
	    .broad-from-vehicle-vehicle-info-header{
	        width: 35%;   
	    }
	    .quote-policy-summary{
	        margin: 6px 0 0 0;
            display: block;    
	    }
	}
	
	.form-edit-underwriting{
	    button{
	        margin: 10px 0;
	    }    
	}
	
	.coverages {
	
		position	: relative;
		
		.coverage-column {
			
			padding-right : 0;
			
			&:last-child {
				padding-left	: 15px;
			}
		}
	}
	
	// styles for quote summary if there are rate errors we need to highlight which sections the errors are contained in
	&.has-error {			
		border: 4px solid $brand-danger;
		.header {
			background : $brand-danger;
			border-color : $brand-danger;
		}
		a.collapsed{
			.header{
				background:$brand-danger;
				color : white;
				border-color : $brand-danger;
			}
		}
	}
	
	&:first-child {
		border-top : 0;
	}
	
	a.collapsed {
		.header {
			color : $label-color;
			background : $gray-lighter;
		}			
	}
	.header {		
		color : $fontcolor-brand-primary-bg;
		background : $brand-primary;
		border-bottom : 0;
	}
	
	.panel-group {		
		margin-bottom : 0;
	}
	
	.force-height {			
		height : 95px;
	}
}
	
.quote-summary-section-header {
	color : $brand-secondary;
	text-decoration : none;
	margin-top:0;
	
	&:hover {
		background : $brand-secondary;
		color : white;
	}
}

.summary-step {
	.driver-info-card {
		border-width: 0;
		border-color: transparent;
		box-shadow: 0 0 3px #aaa;
		padding: 10px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.tooltip-icon {
		font-size: 20px;
	}
	.small-font {
		font-size: 15px !important;
		padding: 0px !important;
		margin: 0px;
	}
	.card-body {
		color: black;
	}
}