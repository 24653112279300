.splash {
	.form-control[disabled],
	.form-control[readonly],
	.readonly {
        opacity: $splash-input-readonly-opacity;
	}
	
	h2 {
       text-align: center;
	   color: $splash-h2-color;
	   text-shadow: $splash-h2-text-shadow;
	}
}