@import "../variables/vehicleone.variables.scss";
#quote-vehicleone-layout-form {

    .disclaimer-container {

        a {
            display: inline-block;
        }

        h2 {
            @extend %disclaimer-text;
        }
    }

    .effective-date-container,
    .displacement-group,
    .electricBike-group,
    .vehicle-input-group.electricBikePower-group {

        h2 {
            font-family: $effective-date-container-h4-font-family;
            font-weight: $effective-date-container-h4-font-weight;
            font-size: $effective-date-container-h4-font-size;
			padding-top: $effective-date-container-h4-padding-top;
            text-transform: $effective-date-container-text-transform;
        }
    }

    .DealerResults {
	
        button {
            background-color: $vehicleone-dealerresults-button-background-color;
            border-color: $vehicleone-dealerresults-button-border-color;
            font-family: $vehicleone-dealerresults-button-font-family;

            &::after {
                top: 50%;
            }
        }
    }
}