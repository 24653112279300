//Affix Container for the Right Side (displayed in MD and LG Screen) & XS and SM Screens
.row-quote-container{
    position: relative;
	.container-affix{

		// when we get hard stop on render because the affix isn't built yet the
		// js that normally removes these buttons can't target the edit links
		// because they don't exist on the page yet; we use this class in in order
		// to keep the edit links away from the user
		&.hide-edit-buttons {
			button[type="submit"],
			.btn-link {
				display: none;
				opacity: 0;
				pointer-events: none;
			}
		}
        //Display Affix in the Mobile and Small Screen on the Top of the Quote Container
	    position: absolute;
        top: $container-affix-top;
        z-index: 16;

        //Display Affix in the Medium and Large Screen on the Right
        @include media-breakpoint-up(lg) {
            position: relative;
            top: $container-affix-top-sm;
            z-index: 9;
        }
	}
}

//
// Quote Affix Sidebar for Desktop
//-----------------------

.quote-affix-column {
	position: static;
}

.quote-affix {

	margin-left : -$grid-gutter-width;

	/* the affix ui loader needs some padding on top */
	.ui-loader {
		margin-top: 15px;
	}

	.purchase-flow-collapse {
		.label-expand {
			display:none;
		}
		.label-collapse {
			display:inline;
		}

		&.collapsed {
			.label-expand {
				display:inline;
			}
			.label-collapse {
				display:none;
			}
		}
	}

    //Header
    .quote-summary-header {
    	text-align: $quote-summary-header-text-align;
    }

    //Summary Label
    .affix-quote-summary {
        text-align: $affix-quote-summary-text-align;
    }

	//Affix Summary Displayed in the XS and SM Screens
	&.quote-affix-xs-sm{
        margin-left: 0;

    	background: 		$quote-affix-xs-sm-background;
    	box-shadow:			0 3px 8px $quote-summary-affix-box-shadow;

        //[x] Button
        .btn-close-summary{
            position: absolute;
            right: $quote-affix-xs-sm-btn-close-summary-right;
            top: $quote-affix-xs-sm-btn-close-summary-top;
        }

        //Header
        .quote-summary-header{
        	text-align: $quote-summary-header-text-align;
            .affix-quote-number{
                border: none;
            }
            hr{
                border-color: #fff;
            }
        }

        //Summary Label
        .affix-quote-summary{
            color: #fff;
            text-align: $affix-quote-summary-text-align;
        }

        //Hide Chevron
        .quote-sidebar .sidebar-item.sidebar-active:before{
            content: none;
        }
	}

	//
	// Quote Sidebar
	//-----------------------
	.quote-sidebar {

		color: 				$quote-affix-color;
		padding:			$quote-affix-padding;

		//
		// Sidebar titles
		//-----------------------
		h4 {
			color: 		$quote-affix-h4-color;

			&.affix-quote-number {

				font-size: $quote-affix-quote-number-font-size;
				border-bottom: 1px solid $quote-affix-sidebar-header-border-color;
				padding-top: 5px;
				padding-bottom: 10px;
			}
		}

		//
		// Separate containers
		//-----------------------
		.container-fluid {

			hr {

				border-color : $quote-affix-sidebar-header-border-color;
			}

		}

		//
		// Sidebar list item
		//-----------------------
		.sidebar-item {

			margin-top: 	$quote-affix-sidebar-item-margin-top;
			min-height: 	35px;
			padding-bottom:	$quote-affix-sidebar-item-padding-bottom;
			padding-left: 	$quote-affix-sidebar-item-padding-left;
			padding-top: 	$quote-affix-sidebar-item-padding-top;
			position:		relative;

			&.sidebar-flow-completed {
				.quote-affix-numbers {
					display: none;
				}
			}

			&.sidebar-complete {
				color: 			$label-inactive-color;
				border-right:	$label-inactive-highlight solid $sidebar-border-right-width;

				h5{

					color: 			$label-inactive-color;
				}
			}
			&.sidebar-active {
				color: 			$label-active-color;
				border-right:	$label-active-highlight solid $sidebar-border-right-width;
				background:		$sidebar-active-bg;


				h5 {

					color: 			$label-active-color;
				}

				ul {
				    &.sidebar-entry-list {
	                    li {
	                        color: $quote-affix-sidebar-active-list-data-color;
				        }
				    }
	            }
			}
			&.sidebar-future {
				color: 			$label-disabled-color;
				border-right:	$label-disabled-highlight solid $sidebar-border-right-width;

				h5{

					color: 			$label-disabled-color;
				}
			}

			h5{
			    font-size: 18px;
				font-weight: 600;
				line-height: 20px;
				margin: 0;
				padding-top: 5px;
			}

			ul {

				&.sidebar-entry-list {

					margin-bottom: 0;
				    margin-left: $quote-affix-sidebar-ul-margin-left;
				    padding-bottom: 0;

				    li {

				    	color: $quote-affix-sidebar-list-data-color;
						font-size: $quote-affix-list-font-size;
					    word-wrap: break-word;
						span {

							&.year {

								padding-right: 0.5em;
							}

							&.make {

								padding-right: 0.5em;
							}
						}

						&:not(:first-child) {
							padding-top: 15px;
						}
					}
				}

				&.sidebar-you-entry {

					address {

					margin : 0;

						span {

							&.about-you-add-1 {

								padding-right: 0.5em;
							}

							&.about-you-state {

								padding-left: 0.5em;
								padding-right: 0.5em;
							}
						}
					}
				}

				&.sidebar-driver-entry {

					span {

						&.driver-firstname {

							padding-right: 0.5em;
						}

						&.driver-lastname {

							padding-right: 0.5em;
						}
					}
				}
			}

			//
			// Sidebar number circles
			//-----------------------
			.quote-affix-numbers{

				display: inline-block;
				margin-left: 2%;
				vertical-align: top;
			width: 14%;

				span {

					&.label-circle{
						font-size:		16px;
						border: $quote-affix-label-circle-border;
						border-radius: 40px;
						line-height: 28px;

						.icon-checkmark {
							&:before {
								font-size: $icon-checkmark-font-size;
							}
						}
					}

				    &.label-circle-active {
						background:  		$label-active-circle-background;

						.circle-text {
							color:			$label-active-circle-color;
						}
					}
					&.label-circle-complete {
						background-color: 	$label-inactive-circle-background;
						color:				$label-inactive-circle-color;
					}
					&.label-circle-future {
						background-color:	$label-disabled-circle-background;
						color:				$label-disabled-circle-color;
					}
				}
			}

			.quote-affix-number-desc{

				display: inline-block;
				float: right;


				width: 75%;
				@include media-breakpoint-up(xl) {
				    width: 80%;
				}

				h5 {

					span {

						&.header-title {

							display: 		inline-block;
							width:			78%;
						}
					}

					button {

						&.btn-edit-link {

							color : 		$quote-affix-edit-link-color;
							display: 		inline-block;
							padding:		0;

							&:active,
							&:hover,
							&:focus {
					        	color : 	$quote-affix-edit-link-hover-color;
							}
						}
					}

				}
			}

			.quote-affix-purchase-summary-desc{

				display: inline-block;

				h5 {

					color: $quote-affix-sidebar-purchase-summary-headers-color;
				}
			}
		}

		//
		// Purchase Flow Quote Container
		//-------------------------------------
		.purchaseflow-quote-container {

			//
			// Sidebar titles
			//-----------------------
			h4 {
				color: 		$quote-affix-sidebar-purchase-summary-headers-color;

				&.affix-quote-summary {

					font-size : 32px;
					margin-top : 0;
				}
			}

			.purchase-flow-collapse-container {
				color: 		$quote-affix-sidebar-purchase-summary-headers-color;
				font-size : $quote-affix-sidebar-purcahse-summary-h4-font-size;
				margin-top : 0;

				a {

					&.purchase-flow-collapse {

						color:	$quote-affix-sidebar-purchase-summary-headers-color;
						font-size: 10px;

						i {

							&.da-collapse-angle{

								font-family : 'FontAwesome';
								font-size: 16px;
								margin-left: 0.3em;

								&:before {

									content:"\f106";
								}
							}
						}

						&.collapsed {

							i {

								&.da-collapse-angle{

									font-family : 'FontAwesome';

									&:before {

										content:"\f107";
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

//
// Quote Affix Header for Mobile
//-----------------------
.quote-header-affix {

	//
	// Quote Headbar
	//-----------------------
	.quote-headbar {

		color: 				#fff;

		//
		// Headbar list item
		//-----------------------
		.headbar-item {

			display: 		inline-block;
		    min-height: 	68px;
		    padding-bottom: 10px;
		    padding-top: 	10px;
		    position: 		relative;

			//
			// Headbar list item width
			//-----------------------
			&.headbar-item-1 {
				width: 100%;
			}
			&.headbar-item-2 {
				width: 50%;
			}
			&.headbar-item-3 {
				width: 33.3%;
			}
			&.headbar-item-4 {
				width: 25%;
			}
			&.headbar-item-5 {
				width: 20%;
			}
			&.headbar-item-6 {
				width: 16.6%;
			}
			&.headbar-item-7 {
				width: 14.2%;
			}
			&.headbar-item-8 {
				width: 12.5%;
			}

			//
			// Headbar list item status
			//-----------------------
			&.sidebar-complete {
				color: 			$label-inactive-color;

				h5{

					color: 			$label-inactive-color;
				}
			}
			&.sidebar-active {

				color: 				$label-active-color;
				background-color:	$sidebar-active-bg;

				&:before {
					content: '';
					width: 0px;
					height: 0px;
					border-style: solid;
					border-width: 8px;
					border-color: transparent transparent $quote-affix-sidebar-active-arrow-color transparent;
					display: inline-block;
					vertical-align: middle;
					margin-right: 0;
					position: absolute;
					left: 50%;
					margin-left:-8px;
					bottom: 0;
				}

				h5 {

					color: 			$label-active-color;
				}
			}
			&.sidebar-future {
				color: 			$label-disabled-color;

				h5{

					color: 			$label-disabled-color;
				}
			}

			h5{
				font-size: 10px;
				font-weight: 600;
				line-height: 14px;
				margin: 0;
				padding-top: 5px;

				@include media-breakpoint-up(sm){

					font-size: 12px;

				}
			}

			//
			// Headbar number circles
			//-----------------------
			.quote-affix-numbers{

				display: block;
				vertical-align: top;

				span {

					&.label-circle{
						font-size:		16px;
					}

				    &.label-circle-active {
						color:				$label-active-circle-color;

						i {

							font-family:'FontAwesome';
							color : 		$label-active-circle-background;

							&:before {

								content:"\f111";
							}
						}
					}
					&.label-circle-complete {
						background-color: 	$label-inactive-circle-background;
						color:				$label-inactive-circle-color;

						i {

							font-family:'FontAwesome';

							&:before {

								content:"\f1db"
							}
						}
					}
					&.label-circle-future {
						background-color:	$label-disabled-circle-background;
						color:				$label-disabled-circle-color;

						i {

							font-family:'FontAwesome';

							&:before {

								content:"\f1db"
							}
						}
					}
				}
			}

			.quote-affix-number-desc{

				display: block;
			}
		}
	}
}
