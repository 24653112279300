//
// FOOTER STYLES
//--------------------------------------

.footer {
	background: $footer-bg;
	font-size: 9px;
	padding-top: 8px;

	color: $footer-setting-color;
	position: relative;
	z-index: 1;

	.list-inline {
		height: 100%;
		margin-left: -10px;

		>li {
			padding: 0px;
			border-right: 1px solid $pre-border-color ;
			padding-right: $footer-item-padding-right;
			padding-left: $footer-item-padding-left;
			vertical-align: middle;

			&:last-child {
				border-right: none;
				text-decoration: none;
			}
		}
	}

	.version {
		font-size: 10px;

		&.PROD {

			display: none !important;
		}
	}

	a {
		color: $footer-link-color;
		text-decoration: $footer-anchor-text-decoration;
	}
	
	#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
		color: 	$footer-link-color;
		border: unset;
		font-size: unset;
		line-height: unset;
		transition: unset;
		background-color: unset;
		padding: unset;
	}
	
	#ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover {
		background-color: unset;
		color: 	$footer-link-color;
		cursor: pointer;
	}

	.sentry-copyright {
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: left;
		}

		.copyright {
			border-right: 0;
		}
	}
}

//
// Sticky Footer
//-------------------------------
html,
body {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
}

.page-wrap {
	flex: 1 0 auto;

	&:after {
		content: "";
		display: block;
	}
}

.footer {
	flex-shrink: 0;
	padding-top: 20px;
}