$incidents-info-border: none;
$incidents-info-padding: 0;
$row-incident-info-box-shadow: 0px 0px 4px 0px $btn-radio-active-box-shadow;
$addincident-padding: 30px;
$addincident-border: 1px dashed $hd-hard-grey;
$addincident-color: $hd-hard-grey;
$addincident-align-items: center;
$addincident-display: flex;
$addincident-justify-content: center;
$addincident-font-family: $font-family-regular;
$addincident-font-weight: 700;
$addincident-icon-font-size: $add-another-thing-button-font-size;
$addincident-icon-margin-right: 4px;

$row-indcident-info-close-button-font-size: 1.75rem;
$row-indcident-info-close-button-color: $hd-burnt-orange;
$addincident-font-size: 1rem;