@import "../variables/drivertwo.variables.scss";
#quote-content {
	.driver-form {
		.form-header{ 
			word-break: break-word;		
		}
	}
}

#quote-drivertwo-layout-form {

	.address1 {
		display: $address-display-type;
	}
	.address2 {
		min-height: $address-two-min-height;
		display: $address-display-type;
	}
	
	.icon-mkt-homeowner {
		font-size: $home-icon-size;
		display: $home-icon-display;
		align-items: $home-icon-align;
	}
	.garaging-address {
		font-size: $address-font-size;
		word-break: break-word;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.currentaddressquestion.form-group:focus-within {
		.col-form-label {
			color: $current-address-label-focus-color;
			
		}
	}
	
}