@keyframes whiteoutSlideIn {
    0% {left: 100%;}
    100% {left: 0;}
}
@keyframes coveragePageSlideOut {
    0% {left: 0%;}
    100% {left: -100%;}
}

body.noscroll {
    margin: 0 !important;
    height: 100% !important;
    overflow: hidden !important;
    position: absolute;
    max-width: 100%;
    background: white;

    animation-name: coveragePageSlideOut;
    animation-fill-mode: both;
    animation-duration: .25s;
    -webkit-animation-name: coveragePageSlideOut;
    -webkit-animation-fill-mode: both;
    -webkit-animation-duration: .25s;
}

.coverage-container .whiteout-view {
    position: fixed;
    top: 0;
    animation-name: whiteoutSlideIn;
    animation-fill-mode: both;
    animation-duration: .25s;
    animation-delay: .25s;
    -webkit-animation-name: whiteoutSlideIn;
    -webkit-animation-fill-mode: both;
    -webkit-animation-duration: .25s;
    -webkit-animation-delay: .25s;
    height: 100%;
    background: white;
    z-index: 1030;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    .multiterm-label.multiterm-label-duplicate {
        display: block;
    }

    .edit-coverage-term-wrapper-label, 
    .edit-coverage-term-wrapper-controls > .form-check-inline {
        padding-bottom: 0;
        margin-bottom: 0;
        font-size: 1rem;
    }

    .edit-coverage-term-wrapper-label {
        font-weight: bold;
    }

    .tooltip-and-required {
        display: none;
    }
    
    .coverage-term-column {
        order: 0;
    }

    .coverage-term-container {
        background-color: white;
        border-top: 0;

        .edit-coverage-term-wrapper {
            padding-left: 0;
            padding-right: 0;
            margin-left: 15px;
            margin-right: 15px;
            border-top: 1px solid black;
            padding-top: 15px;
        }
    }

    .edit-coverage-row {
        box-shadow: none;

        .edit-view {

            .coverage-term {
                display: none;
            }

            .coverage-row {
                padding-top: 0;
            }

            .edit-coverage-term-container,
            .coverage-term-wrapper {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.whiteout-view {
	.edit-coverage-form {
		  @include media-breakpoint-up(md) {
			    flex: 0 0 75%;
    			max-width: 75%;
		  }
  		  @include media-breakpoint-up(xl) {
			    flex: 0 0 40%;
    			max-width: 40%;
		  }

		  padding-top: 75px;

	}
	
	&.coverage-and-all-terms-container {
		display: flex;
	}
}