body.post-bind {

    footer.footer {
                
        a {
        
            &.quote-phone-number {
                display: none;
            }
                        
            &.bind-phone-number {						
                display: block;
            }
        }
    }
}

.preferences-paperless-tooltip::before {
	color: $primary !important;
}