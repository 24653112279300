/**
  *  Harley Davidson Quote Coverage Overrides
  */

// Customized Offering Text Size Decrease
body.quote {
	.quote-container {

		.summary-card.active {
			.customized::after {
				display: none;
			}
		}
		.tab-headline-not-customized {
			&.customized {
				font-size: 18px;
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
}

// Offerings Accordion Overrides
body.quote {
	.coverage-container {
		#offering-accordion {
			.offering-panel {
				&.active {
					.panel-heading {
						border: 3px solid $hd-burnt-orange;
						background: $hd-white;
					}
				}
				.panel-heading {
					background: #e5e5e5;
					border: none;
				}
			}
			span.h1 {
				font-family: "HD Brand Bold Cond", "Arial Narrow", Arial, sans-serif;
			}
		}
	}
}

body.quote {
	.vehicle-container {
		.vehicle-list-summary {
			margin-left: 0;
		}
	}
}

// Offerings Tiles Overrides
body.quote {
	.customer-quote-content {
	    .quote-container {
	        .coverage-container {
	            .step-headers-container {
	                padding-right: 20px;
	            }
	        }
	    }
	}
	.quote-container {
		.coverage-container {

			.step-headers-container {
				.col-6 {
					// I am not removing the column class from the html so that it still functions correctly in Dairyland
					flex-basis: 100%;
					max-width: 100%;
				}

				.form-header-col {
					order: 1;
				}

				.coverage-quick-links {
					order: 0;

					.btn-show-summary {
						position: absolute;
						right: 40px;
						top: -15px;

						@include media-breakpoint-up(lg) {
							top: 0;
							right: 0;
							position: relative;
						}
					}
				}

				.coverage-subheader {
					padding-top: 8.5px;
					font-size: 17px;
				}

				.form-header {

					h2 {
						
						@include media-breakpoint-up(md) {
							width: auto;
						}
					}
				}

				.confirm-coverages-label {

					p {
						&.text-secondary-orange {
							width: 55%;

							@include media-breakpoint-up(md) {
								width: auto;
							}
						}
					}
				}
			}

			.coverage-category-label {
				margin-top: 17px;
				margin-bottom: 40px;
			}

			#offering-buttons {
				.nav-item {
						border-radius: 0;

						@include media-breakpoint-up(md) {
							min-height: 100px;
							border-radius: 0;
						}

						&:hover {
							cursor: pointer;
						}

						.down-payment {
							margin-top: 10px;
							font-weight: initial;

							@include media-breakpoint-up(md) {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
		.summary-cards-group.offering-buttons {
			.nav-item {
				.summary-card {

					background-color: $hd-white;
					color: $secondary;

					&.active {
						border: 1px solid $hd-hard-grey;
                        color: white;

						.container-fluid .card-selection-mark {
							opacity: 1;
						}

						label.down-payment {
							color: $white;
						}
					}

					.container-fluid {
						padding: 0;

						.card-selection-mark {
							position: relative;
							opacity: 0;
						}

						label.down-payment {
							margin-bottom: 0;
						}
					}

					label {
						font-weight: bold;
					}
				}
			}
		}
}

body.quote {
	.form-group.coverage-options {
		padding-left: 0;

		.coverage-header {
			font-weight: bold;
		}
	}

	.modal {
		.coverage-container {
			label {
				&.coverage-header {
					font-weight: 600;
					margin-bottom: 0;
				}
			}
		}
	}
}


#coverage-billplan-container, .coverage-billplan-container  {
	h3 {
		margin-top: 0;
		font-family: "HD Brand Norm", "Arial Narrow", Arial, sans-serif;
	}
}