//
//Cards
//-----------------------
.quote-container {

    // Add Cards Button
    //----------------
    .summary-card{

        .card-info{
            padding: 5px 10px;

            @include media-breakpoint-up(md){
                padding-left: 15px;
                padding-right: 15px;
            }

            @include media-breakpoint-down(sm){
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }

    	&.container-add-btn{

	        /*
	         * in order to vertically align the Add New Entity button in the middle we have
	         * to set the display property to table
	         */
	        display: table;
	        width: 100%;

	        .form-add-btn{

	            display: table-cell;
	            vertical-align: $form-add-btn-align;
	            text-align: center;

	            //Add Button
	            .add-card{

	                color: 		$btn-add-card-color;
	                background:	transparent;
	                font-size: 	$header2-fontsize;

	                /* margin-top for xs screen */
	                margin-top: 15px;

	                @include media-breakpoint-up(md){
	                	/* no margin top for sm, md, and lg screen */
	                    margin-top: 0;
	                }

	                /* hover style for add entity button */
	                &:hover {
	                    color: $btn-add-card-color-hover;
	                }
	            }
	        }
	    }
	}

    //Container of the Summary Cards in Driver, Vehicle & Additional Pages
    .entity-card,
    .summary-cards-group {
    
    	&.error {
    		.summary-card {
    			border-color: $brand-danger;
    		}
    	}
    	

		//
		// entity card status
		//----------------------------
		.label-entry-incomplete {
			display: block;
		}
		.label-entry-complete {
			display: none;
        }

        //If Selected, Display "Check" Sign
        .card-selected {
            display:none;
        }

        //If Not Selected, Display "Chevron-Down" Sign
        .card-not-selected {
            display: block;
        }
        
        &.active {
            //If Selected, Display "Check" Sign
            .card-selected {
                .icon-checkmark{
                    color: $card-status-selected-checkmark-color;
                }
                display:block;
            }

            //If Not Selected, Display "Chevron-Down" Sign
            .card-not-selected {
                display: none;
            }
            
            &.complete {
	            //If Selected, Display "Check" Sign
	            .card-selected {
	                display:block;
	            }
	            //If Not Selected, Display "Chevron-Down" Sign
	            .card-not-selected {
	                display: none;
	            }
			}
        }

		/* if entity-card also contains complete class we swap the display props of the labels */
		&.complete {
			.label-entry-incomplete {
				display: none;
			}
			.label-entry-complete {
				display: block;
            }

            //If Selected, Display "Check" Sign
            .card-selected {
                display:block;
            }
            
            //If Not Selected, Display "Chevron-Down" Sign
            .card-not-selected {
                display: none;
            }
		}

        //Summary Cards
        //-------------
        .summary-card {
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $card-border-color;
            position: relative;
            background-color: $card-bg;

            @include media-breakpoint-up(md){
            	min-height: $summary-cards-sm-min-height;
            }


            //Driver: FName, LName, MI, Suffix Vehicle: Year, Make & Model
            .card-header-summary {
                // padding: 5px;
                display: block;
                position: relative;
                font-weight: bolder;
            }

			/* complete and incomplete status strings */
			.label-status {
				line-height: 	$card-status-leading;
				font-size: 		$card-status-fontsize;
				color: 			$card-status-color-complete;
				margin-bottom: 	$card-status-marginbottom;
			}

            //Incomplete Status
            .label-incomplete {
                color: $card-status-color-inactive;
            }

            //Container for Edit & Delete Links
            .container-card-edit-delete {

                z-index: 1;

                .btn-link{

                    text-decoration: none;
                }

                //Font Awesome Edit & Delete
                .fa-pencil,
                .fa-trash {

                    color: $card-edit-icon-color;
                }

                .remove-padding-xs-screen{
                    padding: 0;
                    text-align: center;
                }
            }

            /* these checks and chevrons are used on the additional info screen and differ from quote
            and drivers in that there is no edit/remove buttons at this point */
            //Check and Chevron Down Icons
            .card-selection-mark {
                position: 	absolute;
                width:		100%;
                bottom: 	0px;
                margin: 	0 auto;
                left:		0px;

                &.card-selected .icon-checkmark{
                    font-size: 1.8em;


               		/* the checkmark icon handed off from marketing has slightly different size attributes
               		from font awesome fonts so we need to bring it down just a bit so that it lines up with
               		where the chevron is replaced at */
				    margin-bottom: -8px;
                }
                //For Chevron Down Icon
                &.card-not-selected {
                     bottom: 0px;
                }
            }
            //Link inside the Card
            a{
                color: $card-link-color;
                height:100%;
            }
        }

        //Parsley Error
        .summary-card .card-header-summary.hasError{
            color: $card-status-has-error-color;
            .icon-checkmark{
                color: $card-status-has-error-color;
            }

            .info-header {
            	color: $card-status-has-error-color;
            }

            .label-entry-incomplete {
            	color: $card-status-has-error-color;
            }
        }

        //Hover over Active and Non-Active Cards
        //--------------------------------------

        //To Show Thick Border While Hovering
        & .summary-card:before{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid $card-summary-border-color;
            border-left-width: $card-active-border-width;
            border-top-width: $card-active-border-width;
        }
        & .summary-card:hover{
            border-color: $card-summary-border-color;
            color: $card-summary-color;
            border-radius: $card-border-radius;

            /* hover state for info header */
            .info-header {
            	color: $card-header-color-hover;
            }

            /* hover state for info subhead */
            .info-sub-header {
            	color: $card-subhead-color-hover;
            }

            /* hover state for status text */
            .label-status {
            	color: $card-status-color-hover;
            }
        }
        & .summary-card:hover:before{
            content: '';
        }

        // Active Cards
        //-------------
        &.active .summary-card,
        & .active .summary-card,
        & .summary-card.active {
            color: $card-header-color;
            background-color: $card-active-bg;
            border-radius: $card-border-radius;
            box-shadow: $summary-card-active-box-shadow;

            a {
                color: $card-header-color;
            }
            
            

            /* active card info header */
            .info-header {
            	color: $card-header-color;
            }

            /* active card info subhead */
            .info-sub-header {
            	color: $card-subhead-color;
            }

            /* active card complete status */
            .label-complete {
            	color: $card-status-color-complete;
            }

            /* active card incomplete status */
            .label-incomplete {
            	color: $card-status-color-incomplete;
            }
        }

        // On Active Card, this will show as Thick Border on the Left
        &.active .summary-card:before,
        & .active .summary-card:before,
        & .summary-card.active:before{
            content: '';
        }

        //summary-cards Display from Small Screen
        @include media-breakpoint-up(md){
            .summary-card{
            	padding: $card-padding;
                border-width: $border-width;
                border-style: solid;
                border-color: $card-border-color;
                color: $primary-dark-gray;
                margin-bottom: 15px;

                p{
                    text-align: center;
                }

                //Header Container for Driver, Vehicle Information
                .card-header-summary {
                	text-align: center;
                }

               	/* cards with short headers such as drivers headers */
               	.info-header {
               		line-height: $card-header-leading;
               		margin-bottom: $card-header-marginbottom;
               		font-size: $card-header-fontsize;
               		color: $card-header-color-inactive;
                    display: block;
	                overflow: hidden;
	                text-overflow: ellipsis;
               	}

                /* cards with more detailed headers such as vehicle headers display differently */
                .info-sub-header {
               		line-height: $card-subhead-leading;
               		margin-bottom: $card-subhead-marginbottom;
               		font-size: $card-subhead-fontsize;
               		color: $card-subhead-color-inactive;
                	display: block;
                }

                //Container for Edit & Delete Links
                .container-card-edit-delete{
                    position: $container-edit-delete-position;
                    bottom: 0;
                    left: 0;
                    padding: $card-edit-delete-padding;

                    //Links : Edit & Remove
                    .link-edit.hidden-xs,
                    .link-remove.hidden-xs{
                        color: $card-status-selected-checkmark-color;
                        text-decoration: underline;
                        text-decoration-color: $card-status-selected-checkmark-color;
                    }
                    .remove-padding-xs-screen{
                        padding: $remove-padding-xs-screen-padding;
                    }
                    .form-remove-vehicle, .form-remove-driver{
                        float: right;
                    }
                    .form-edit-vehicle, .form-edit-driver{
                        float: left;
                    }
                }
            }

            //Hover over Active and Non-Active Cards
            //--------------------------------------
            .summary-card:hover{
                border-radius: 0;
            }

            .summary-card:hover:before{
                content: none;
                border-width: 0px;
            }

            // Active Cards
            //-------------
            &.active .summary-card,
            & .active .summary-card,
            .summary-card.active{
                border-color: $card-border-color-active;
            }

            &.active .summary-card:before,
            & .active .summary-card:before,
            .summary-card.active:before{
                content: '';
                border-width: $card-active-border-width;
            }
        }
    }
}
