//
// Remove the padding on either side of the form for mobile devices
//-------------------------------------
@include media-breakpoint-down(md) {

	#customer-quote-container,
	#quoteflow-header-container,
	#customer-bind-container,
	#page-error-container,
	.groups-container {

		padding-left: 0;
		padding-right: 0;
	}

	.row-quote-header,
	.row-quote-container {

		margin-left: 0;
		margin-right: 0;
	}
}

//
// Special spacing for checkbox for automatic payments
//-------------------------------------
input[name="eft-first-payment-different-method"] {
	margin: 20px 0 0;
}

//
// Overlay for (Summary Affix) for Small Screen
//-------------------------------------
.overlay{
    position: absolute;
    left: 0;
    top: 0;
    background: #000000;
    width: 100%;
    height: 100%;
    z-index: 15;
    zoom: 1;
    filter: alpha(opacity=50);
    opacity: 0.5;
    @include box-shadow(0 0 20px rgba(0, 0, 0, 0.5));
}

//
// Urgent Notification Banner
//-------------------------------------
#urgent-notification-banner {
	text-align: center;
	padding: 16px 24px;
}

//
// Quote Navigation
//-------------------------------------
.quote-navigation {

	.referral-agency {

		color : #fff;
	}
}

//
// Post Content Footer (Save and Exit, Continue, etc.)
//---------------------------------
.post-content-buttons {

	/* on driver and vehicle page we are including an "Add Entity" button next to the continue button
	so we want to give some padding between the two buttons */
	.add-entity-submit {
		margin-right: $add-entity-submit-margin-right;
        color: $btn-add-new-entity-color;
        background: $btn-add-entity-bg;
        border: $btn-add-entity-border;
        font-size: $add-entity-submit-font-size;
	}
}

//
// Quote Content
//-------------------------------------
.customer-quote-content {
	background-color: $quote-content-background-color;
	box-shadow: $quote-content-box-shadow;

	//
	// content loading styles
	//--------------------------------
		/* this will inherit a bunch of styles from .ui-loader, we need to tune it for section specific area */
		.ui-loader {

			position: absolute;
			left: 0;
			top: 0;
			margin-left: 0px;
			margin-top: 0px;
			width: 100%;

			.ui-icon {
				background-repeat: no-repeat;
				background-position-x: center;
				}

				/* optional message that displays beneath the ui loader gif */
				.ui-loader-text {
					width: 100%;
					margin-top: 20px;
				}
			}

		/* the content loader blocker */
		.ui-blocker {
			position: absolute;
			top: 0;
			left: 0;
		}


	//
	// Page and section headers
	//------------------------
		/* page headers */
		.form-header {
			h2, .h2 {
				font-size: 32px;
				font-weight: normal;
			}
		}

		/* special section headers: "Pay Today" header on coverages */
		h2, .h2{
			font-size: 27px;
			font-weight: bold;
		}

		/* section headers */
		h3, .h3 {
			font-size: $header2-fontsize;
			font-weight: $header2-weight;
			color: $header2-color;
		}


	//
	// Quote Container
	//-------------------------------------
	.quote-container {

		/* if a form doesn't have many inputs the sidebar can be taller than the quote content so we'll define
		a min height so we don't get weird white space below the continue/save-and-exit buttons -- it also
		makes the page not */
		@include media-breakpoint-up(lg) {
		    min-height: $quote-container-min-height;
		}

		/* design specs show more than the standard gutter width between edge of content section and actual
		content, this padding matches it up per requirements */
		padding: 15px 30px 15px 5px;

		.optional-indicator {
		    position: absolute;
		    bottom: 4px;
		    left: 20px;
		    font-size: .7rem;
		    font-style: italic;
		}

		.option-message {
			margin: 8.5px 0;
			color: $option-message-color;
		}

		@include media-breakpoint-up(md) {
			.inputs-group-container {
				margin-bottom: 13px;
			}
		}

		.errors {
			color			: $brand-failure;

			.errors-list-container {
				margin-bottom: 0px;

				.forms-errors-list {
					margin-bottom: 0px;

					li:last-child {
						margin-bottom: 15px;
					}
				}
			}

			label,
			strong {
				padding-top		: 10px;
				padding-bottom	: 10px;
				margin-bottom	: 0;
				font-size		: larger;
			}

			label {
				padding-left	: 0;
			}
		}

		/* group labels that sort of act as input labels though they're not really */
			h4 {
				font-size: $label-font-size-mobile;
				color: $quote-question-label-color;
				padding-left:10px;
				&:not(.tooltip-subheader) {
				    letter-spacing: $quote-question-letter-spacing;
				}
				@include media-breakpoint-up(md) {
					font-size: $label-font-size-desktop;
				}
			}

		//
		// Disclaimer information (About You and Final Sale)
		//-------------------------------------
		.disclaimer-container {
			h4 {
				line-height: normal;
				font-weight: normal;
				font-size:	$disclaimer-fontsize;
			}
			a {
				text-decoration: underline;
			}
		}

		//Tooltips for yes/no buttons, per UX design
		.form-group{
			&.radio-buttons{

				//radio buttons have an error
				&.has-error {

					.controls {

						.btn-group[data-toggle="buttons"] {

							height: $has-error-button-group-height;

							.btn {

								border: $radio-button-error-border;
								color: $state-danger-text;
							}
						}
					}
				}
			}
		}

		.fa-ul {
			position: relative;
		}
	}

	/* the calendar icons markup will maintain what bootstrap wants us to do in that
	it will be a input-group-btn but marketing styleguide requires us to show the button
	as if it is container within the input, so we will absolutely position it to
	give it that illusion */
	.icon-calendar {
		font-size: 	$icon-calendar-font-size;
		position: 	absolute;
		right: 		$icon-calendar-right;
		top: 		$icon-calendar-top;
		z-index: 	2;
		background:	$input-bg;
	}
}

.ctrl-date-picker {
	.input-group-addon{
		padding: 0;
		border: 0px;
		color: $calendar-icon-color;
	}
	display : block;
}

/* calendar icon that appears in date fields */
.calendar-icon-container {

	/* because the input field is the item that inits the date picker we want
	to force this container to not process any clicks */
	pointer-events: none;
	background-color: transparent;
}
.no-date-picker {
	.calendar-icon-container {
		display:none;
	}
}

/* save and exit button */
button.save-and-exit {
	font-weight: bold;
}

//Summary Button in the XS and SM Screen to Display the Summary
.row-form-header{
	margin-bottom: 10px;
	.btn-show-summary{
	    margin-top: $summary-button-margin-top;
		width: 90px;
		font-size: 14px;
		min-width: 1px;
	}
}

/* per marketing: Should be Heavy text weight*/
#quicklink-print-quote {
	font-weight: bold;
}

.tooltip-inner-header {
	font-weight: 	$tooltip-header-weight;
	font-size:		$tooltip-header-fontsize;
	color: 			$tooltip-header-color;
}


//
// quote modal styles
//----------------------------------------------
.modal {

	/* override bootstrap minimum height */
	.modal-content {
		min-height: $modal-content-min-height;
	}

	.input-group-addon {

		/* overriding bootstrap padding here so it lines up with content above and below that aren't form elements */
		background: transparent;
		padding-right: 2px;

		/* overriding bootstrap styles on the label here to match marketing style guide specs */
		.col-form-label {
			font-weight:	$formquestion-copy-weight;
			font-size: 		$formquestion-copy-fontsize;
			color: 			$modal-control-label-color;
		}
	}
	
	.paperless-email-row, .paperless-phone-row  {
	    .paperless-checkbox-addon {
	        width: $paperless-checkbox-addon-width;
		}
		
		.error-container {
			padding-left: 0;
			@include media-breakpoint-up(md) {
				padding-left: $paperless-checkbox-addon-width;			
			}
		}
	}
	.bill-reminder-email-row, .bill-reminder-phone-row {
	    .bill-reminder-checkbox-addon {
	        width: $bill-reminder-checkbox-addon-width;
		}
		
		.error-container {
			padding-left: 0;
			@include media-breakpoint-up(md) {
				padding-left: $bill-reminder-checkbox-addon-width;			
			}
		}
	}
	.docnotifications-email-row, .docnotifications-phone-row {
	    .docnotifications-checkbox-addon {
	        width: $docnotifications-checkbox-addon-width;
		}
		
		.error-container {
			padding-left: 0;
			@include media-breakpoint-up(md) {
				padding-left: $docnotifications-checkbox-addon-width;			
			}
		}
	}

	.language-preference-row {
		.error-container {
			padding-left: 0;
			@include media-breakpoint-up(md) {
				padding-left: $bill-reminder-select-addon-width;	
			}
		}
	}
	.paperless-email-row, 
	.paperless-phone-row, 
	.bill-reminder-email-row, 
	.bill-reminder-phone-row,
	.docnotifications-email-row, 
	.docnotifications-phone-row {
	    .checkbox-label::before, 
	    .custom-control-label::before,
	    .checkbox-label::after, 
	    .custom-control-label::after {
	        top: $account-settings-custom-control-position-fix-top;
	    }
	}

	.bill-reminder-label-addon, .paperless-label-addon, .docnotifications-label-addon {
		background-color: transparent;
		width: 150px;
		text-align: left;
	}

	.bill-reminder-checkbox-addon, .paperless-checkbox-addon, .docnotifications-checkbox-addon {
		background-color: transparent;
		min-width: 37px;
	}

	.bill-reminder-phone-row, .paperless-phone-row, .docnotifications-phone-row {
		margin-top: 15px;
	}

	label.terms-conditions {

		font-weight:	$formquestion-copy-weight;
		font-size: 		$formquestion-copy-fontsize;
		color: 			$formquestion-copy-color;
		/* giving this label some space so it lines up with the input group addons in the form above */
	    padding-top: 4px;
	    margin-left: 0;
	}

	.arbor-day-logo-modal {
		width: 100%;	
	}

	.paperless-modal-header {
		font-weight: $paperless-modal-header-weight;
		font-size: $paperless-modal-header-font-size;
		margin-top: $paperless-modal-header-margin;
		margin-bottom: $paperless-modal-header-margin;
	}
}

//
// Payment option radio/button selections
//----------------------------------------

/* to achieve the functionality we want bootstrap requires us to wrap the radio button
and label in a btn group and to give the label a class of btn, this causes lots of
overriding with the list-group styling we are looking for so we will have to manually
override what we need to here */
.fancy-options-list-group-item {

	/* various active list group class styles */
	&.active {
		box-shadow: 0px 0px 5px 0px #000;
		border-color: $secondary-yellow;

		/* thicker border for active list group per marketing specs */
		&:before {
			content: " ";
			border-radius: 2px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 4px solid $secondary-yellow;
		}

		/* in an active list group we want to show the checkmark icon and change th color */
		.active-indicator,
		.inputs .fa {

			border-radius: 50%;
			box-shadow: 0px 0px 5px 0px #000;

			/* when plan is selected on small screen we change the way the icon looks by hiding
			the thin circle and just showing the checkmark with a bg color, on desktop the icon
			colors change */
			@include media-breakpoint-up(md) {

				/* undo some small screen stuff per design doc */
				box-shadow: none;

				/* undo some small screen stuff per design doc */
				background-color: transparent;

				/* selected color */
				color: $secondary-yellow,
			}

			/* show it if it's the active radio input */
			display: block,
		}

		.fa.disabled-when-active {
			/* we don't show the circle thin when we are active */
			display: none;

			@include media-breakpoint-up(lg) {

				display: block;
				/* selected color */
				color: $secondary-yellow;
			}
		}
	}


	/* radio checkmark indicator will reflect the current radio label that is active */
	.active-indicator {

		/* we want to hide the active indicator by default */
		display: none;
	}


	/* remove margins from headers */
	h3, .h3 {
		margin-top: 0;
		margin-bottom: 0;
	}

	/* all the h5s in this layout proceed an h3 so we don't want to eliminate the margin-top here */
	h5, .h5 {
		margin-bottom: 0;

		/* aligning font size with styleguide doc */
		font-size: 9px;
	}
}
.fancy-options-list-group {
	display: block;

	/* reduce the distance between the list group and any applicable disclaimers */
	margin-bottom : 10px;

	.payment-option-tooltip {
		position: relative;
	    top: -5.5rem;
	}

	address {
		margin-bottom: 0;
	}

	.fancy-options-list-group-item,
	.fancy-options-list-group-item:first-child:not(:last-child),
	.fancy-options-list-group-item.active {

		/* bootstrap overrides */

			/* weird IE8 bug fix (list-group that contains a row doesn't stretch the whole way) */
			width: 100%;

			/* override btn border radius */
			border-radius: 2px;

			/* override default border color */
			border-color: transparent;
			@include media-breakpoint-up(lg) {
				border-color: $finalsale-payment-option-inactive-border-color;
			}

			/* override active state background */
			background: $finalsale-payment-option-bg;

			/* override btn elements want to be centered */
			text-align: left;

			/* override btn elements dont wrap */
			white-space: inherit;

			/* override active list group font color */
			color: inherit;

			/* hover problems with the list group and borders */
			&:hover {
				margin-bottom: 20px;
			}
			margin-bottom: 20px;
	}
}

.dynamic-repeatable-label {
	font-family: $dynamic-repeatable-font-family;
    font-size: $formquestion-copy-fontsize;
    font-weight: normal;
    text-align: left;
    padding-left: $dynamic-repeatable-padding-left;
}

/* middle align the list group items, make sure to eliminate white space in between
the column divs (use comments)... best bootstrap vertical align:middle hack ever
!important declarations are required as this is a utility class, so needs to circumvent
unintended overrides due to more precise selections for shared classes on the element */
.vcenter {
    display: inline-block !important;
    vertical-align: middle !important;
}

.view-summary-button-container, .printlink-container {
	margin-top:15px;
}

.coverage-quick-links > .view-summary-button-container {
	float: right;
}

/* fixes bootstrap 3 bug with tall modals http://stackoverflow.com/questions/7508576/twitter-bootstrap-modal-scrolling-issue */
.modal-open .modal {
	overflow-y: scroll;
}

.never-show{
	display : none;
}

.row-btn-incident,
.add-additional-interest {
	margin-bottom: 20px;
}

//
// Vehicle and Driver Summary
//-------------------------------------
.entity-summary {

	button {
		width : 100%;
	}

	li {
		margin-bottom : 10px;

		div {

			&:first-child {
				text-align : right;
			}
		}
	}
}
label.readonly {
	opacity: .7;
}

//
// Bootstrap Select overrides
//-------------------------------------

#customer-quote-container .bootstrap-select .dropdown-toggle .filter-option {
	display: inline-flex;
    position: initial;
    padding: 0;
}

#customer-quote-container .dropdown-toggle::after {
	vertical-align: initial;
	position: absolute;
	top: 15%;
	right: 6px;
}
