@import "../variables/reconcile.variables.scss";

.quote-step-orderreports {
    .btn-remove-entity {
        color: $summary-delete-icon-color;
    }
    
    .form-remove-entity {
        position: absolute;
        top: 0;
        right: 0;
    }

    .summary-entity-icon {
        color: $summary-entity-icon-color;
        font-size: $summary-entity-font-size;
        min-width: $summary-entity-min-width;
        margin-right: $summary-entity-margin-right;
        text-align: $summary-entity-text-align;
    }

    .card {
        margin-bottom: $summary-card-margin-bottom;
    }

	.entity-card {
		.summary-card {
	    	border-width: $summary-card-border-width;
			padding-top: $summary-card-top-padding;
			padding-bottom: $summary-card-bottom-padding;
            text-decoration: none;

			&:hover {
				border-color: $summary-card-border-color;
			}
		}
		
		.complete {
			.label-entry-incomplete {
				display: none;
			}
		
			.label-entry-complete {
				display: inline-block;
		    }
		}
		
		.label-entry-incomplete {
			display: inline-block;
		}
	}
	
	.head-card {
		padding: 0px;
	}
	
}