.hard-stop-container,
.soft-stop-container {

	.error-image {
		max-height: 200px;
		margin: 20px 0;
	}

	/* hardcoded height of the page for the hard stop error page, only for md/lg screens */
	@include media-breakpoint-up(lg) {
		height: 700px;
	}
}
