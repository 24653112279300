/**
  * Harley Davidson Quicklinks Overrides
  */  
.print-link {		
	.btn-print {			
		color: $hd-burnt-orange;
		text-decoration: none;			
		&:before {				
			font-family : 'DairylandMarketingFont';
			font-size: 20px;
			content:"\e909";
		}
	}
}