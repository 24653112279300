//
// Quote Wizard - Vehicle Step
//-------------------------------
.broadformvehicle-form{
    .label-or{
		width:50%;
        margin: 25px 0;
        text-align: center;
        font-weight: bolder;
        font-size: 1.2em;
    }
}
//
// Broad Form Vehicle Summary
//-------------------------------
.broadformvehicle-summary {
	
	h4 {
		//
		span.vin {
			margin-left	: 2em;
		}
	}
	
	button {
		
		&.edit-broadformvehicle,
		&.remove-broadformvehicle {
			@include media-breakpoint-down(md) {
				width	: 100%;
			}
		}
	}
	
	.broadformvehicle {
		padding-bottom	: 14px;
		border-bottom	: 1px solid #EEE;		
	}
}