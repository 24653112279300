//Reconcile
.reconcile-form{
	h3.additional-info{
		color				:	#4d4d4d;
	}
	.tooltip-excluded-driver.tooltip-and-required{
	    position: relative;
	    top: 0;
	    left: 0;
    	a.tooltips-link{
    		text-decoration		: underline;
    	}
	}
}