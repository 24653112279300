/**
  * Harley Davidson Order Reports Overrides
  */
@import "../variables/orderreports.variables.scss";

.orderreports-container {

	.orderreports-form {

        a.tooltips-link {

            position: static;

            .tooltiptext {
                position: absolute;
                width: $orderreports-tooltiptext-width;
                left: $orderreports-tooltiptext-left;
                top: $orderreports-tooltiptext-top;
                font-size: $orderreports-tooltiptext-font-size;
                color: $orderreports-tooltiptext-color;
                background: $orderreports-tooltiptext-background;
                border: $orderreports-tooltiptext-border;
                box-shadow: $orderreports-tooltiptext-box-shadow;

                &:before, 
                &:after {
                    display: none;
                }
            }
        }

		.additional-info {
			border: $orderreports-additionalinfo-border;
		}		

		.report-table {

			&.even {
				background-color: $orderreports-report-table-striped-background-color;
			}
		}

		.incident-details {

			.incident-label {
				color: $orderreports-incidentdetails-label-color;
			}
		}
	}
}
