//
// Exception styling
// --------------------------------------------------
.exception-content {
	background : white;
	padding : 15px;
	min-height: 100vh;

	.exception-image-content {

		color : 						$brand-danger;

		.error-image {
			height: 128px;
			margin: 32px 0;

			@include media-breakpoint-up(md) {
				margin-top: 48px;
				min-height: 200px;
			}
		}

		span {

			display :					block;
			text-transform : 			uppercase;
		}
	}



	@include media-breakpoint-up(md) {
		min-height: 50vh;
	}
}

.row-error-container {

	@include media-breakpoint-down(md) {
		margin-left: 0;
		margin-right: 0;
	}
}

.has-error {

	.form-control {

		border-color: $brand-danger;
	}

}