.navbar {
    &.scrolled {
        @include media-breakpoint-down(md) {
            .navbar-main {
                .navbar-logo-column {
                    @include make-col(7);
                }   
                .navbar-contact-column {
                    @include make-col(5);
                }
            }        
        }
    }
}