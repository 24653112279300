$uwaddinfo-vehicle-container-margin-bottom: 20px;

$uwaddinfo-entity-container-border: 1px solid $hd-light-grey-1;
$uwaddinfo-entity-container-padding: 0 15px;

$uwaddinfo-entity-card-border: 0 transparent;

$uwaddinfo-tab-buttons-border-bottom: 1px solid $hd-light-grey-1;
$uwaddinfo-tab-buttons-padding-bottom: 15px;
$uwaddinfo-tab-buttons-margin-bottom: 15px;

$uwaddinfo-summary-card-active-border: 1px solid $hd-burnt-orange;
$uwaddinfo-summary-card-active-box-shadow: $hd-burnt-orange;
$uwaddinfo-summary-card-active-color: $hd-burnt-orange;