@import "../variables/quote.drivers.variables.scss";
.driver-form {

    .incidents-info-question {
        padding-left: $incidents-info-padding;
        padding-right: $incidents-info-padding;
        border-top: $incidents-info-border;
        border-left: $incidents-info-border;
        border-right: $incidents-info-border;

        &:not(.contains-incidents) {
            border-bottom: $incidents-info-border;
            padding-bottom: $incidents-info-padding;
        }
    }

    .incidents-info {
        padding-left: $incidents-info-padding;
        padding-right: $incidents-info-padding;
        border-left: $incidents-info-border;
        border-right: $incidents-info-border;
    }

    .incidents-info-add {
        padding-left: $incidents-info-padding;
        padding-right: $incidents-info-padding;
        border-left: $incidents-info-border;
        border-right: $incidents-info-border;
        border-bottom: $incidents-info-border;
    }

    .row-incident-info > .row {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        padding-top: 10px;
        box-shadow: $row-incident-info-box-shadow;

        .btn-remove-entry {
            position: absolute;
            top: 0;
            right: 0;
            text-decoration: none;
            padding: 6px 10px;

            i.fa {
                display: block;
                font-size: $row-indcident-info-close-button-font-size;
                color: $row-indcident-info-close-button-color;
            }
        }
    }

    .addincident {
        width: 100%;
        border: $addincident-border;
        color: $addincident-color;
        border-radius: 0;
        padding-top: $addincident-padding;
        padding-bottom: $addincident-padding;
        font-size: $addincident-font-size;
        font-family: $addincident-font-family;
        font-weight: $addincident-font-weight;
        align-items: $addincident-align-items;
        display: $addincident-display;
        justify-content: $addincident-justify-content;

        &:hover {
            color: $addincident-color;
        }

        .icon-circleadd:before {
            font-family: 'cfonts';
            content: "\e802";
            font-size: $addincident-icon-font-size;
            margin-right: $addincident-icon-margin-right;
        }
    }

    .incidentsquestion {
        margin-bottom: 0;
    }

    .quote-bottom-links {

        hr {
            border-top: none;
        }
    }
}