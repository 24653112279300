.modal-coverage-tooltips {
	
	@media (max-width: 576px) {
	    position: fixed;
	    top: 0;
	    left: 0;
	    background: white;
	    padding: 0;
	    margin: 0;
	    height: 100%;
	    width: 100%;
	}


	@media (min-width: 576px) {
		position: relative;
		margin: 1.75rem auto;
		padding-bottom: 50px;	
	}


    > .modal-content {
        height: 100%;
        overflow-y: auto;
    }
    
    .btn-close-tooltip {
        display: none;
    }

    .coverage-help-icon {
        font-size: 4rem;
        text-align: center;
        display: block;
        margin-bottom: 15px;
        color: $primary;
    }

    .coverage-help-title h4 {
        text-align: center;
    }
}

#sentry-modal {
	@media (min-width: 576px) {
		padding-right: 0;	
	}
}