// coverage collapse styles for javascript height application
#coverages-content {	
	
	.even {
		
		background:transparent;
	}
	
	.coverage-header {	
		border-bottom : 2px solid $gray-lighter;	
		font-size: 1.0em;
		color: #4d4d4d;
		background: #f2f2f2;
		margin: 0;
		padding-top: 20px;
		padding-bottom: 10px;
		position: relative;
	}
	
	.coverages > .coverage-column {
		 &:first-child {
			> .coverage-content {
				
				border-left : 2px solid $gray-lighter;
			}
		}
		&:last-child {
			> .coverage-content {
				
				border-right : 2px solid $gray-lighter;
			}
		}
	}	
	&.collapse {
		display		: block;
		visibility	: hidden;
		position	: absolute;
		top			: 0;
		width 		: 100%;
		&.in{ 
			display: block; 
			visibility: visible; 
			position:static; 
		}
		tr.in{ 
			display: table-row; 
			visibility: visible; 
			position: static; }
		tbody.in { 
			display: table-row-group; 
			visibility : visible; 
			position: static; 
		}
	}
	
	label {
		display : block;
	}
	
	// 
	// Coverages Pagination Styles
	//------------------------------
	.viewfinder {
		@include media-breakpoint-up(md) {
			//.box-shadow (inset 0px 0px 10px 2px rgba(50, 50, 50, 0.75));
			border-left : 1px solid #ccc;
			border-right : 1px solid #ccc;
			box-sizing: border-box;
			z-index : 1;
		
			overflow : hidden;
			position : absolute;
		
			.viewfinder-content {
				width : 10000px;
				
				.coverage-column { 
					float 			: left;
					margin 			: 0;
					
					> div,
					> h4 {
						padding-right : 20px;
					}
				}
			}
		}
	}
}

.viewfinder-content {
	.coverage-column { 
		width 			: 155px;
	}
	@include media-breakpoint-up(lg) {
		.coverage-column { 
			width : 204px;
		}
	}
	@include media-breakpoint-up(xl) {
		.coverage-column { 
			width : 249px;
		}
	}
}

.modal {
	.viewfinder-content {
		.coverage-column { 
			width: 		100%;
			padding: 	10px;
			@include media-breakpoint-up(md){
				width: 135px;
				padding: 0;
			}
			@include media-breakpoint-up(lg) {
				width : 204px;
			}
			@include media-breakpoint-up(xl) {
				width : 210px;
			}
		}
	}
}

.coverages{

	> .coverage-column { 
		padding-right: 0;
	}
	//
	// Coverage Pagination Buttons
	//------------------------------------
	.page-left-button,
	.page-right-button {
		height : 0;
		display : none;
		margin : 0;
		padding : 0;
		border : none;
		user-select: none;
		@include media-breakpoint-up(md) {
			position	: absolute;
			height		: auto;
			top			: 0;
			z-index		: 1;
			height		: 100%;
			width		: 26px;
			cursor 		: pointer;
				
			span {
				display : block;
				font-size : 23px;
			}
		}
	}
	.page-left-button {
		left : 0;
	}
	.page-right-button {
		right : 0;
	}
	
	
	.coverage-column{
	
		overflow : hidden;
		
		margin : 0;
		
		@include media-breakpoint-up(md) {
			&:last-child {
				margin : 0 0 0 -15px;
			}
		}		

		.coverage-header{
			height: auto;
		}
	
		.coverage-content{
			
			border-bottom : 2px solid $gray-lighter;	
			padding: 10px $grid-gutter-width;
		
			label {
				margin-bottom: 5px;		
			}
		
			span {
			
				&.description{
					display: block;
					margin-bottom: 5px;
				}
				
				&.subtotal{
					font-weight: 600;
				}
			}
		
			small {
			
				display: block;
				clear : both;
			
				&.row-coverage-term {
					font-weight: 400;
					padding-left: 40px;
				}
			}
		}
	}
}