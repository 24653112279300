$finalsale-paytoday-margin: 0;
$finalsale-paytoday-padding: 0;
$finalsale-paytoday-height: fit-content;

$finalsale-draftdate-border-radius: 5px;

$finalsale-disclaimer-padding-bottom: 15px;

$finalsale-new-payment-display: flex;
$finalsale-new-payment-align-items: center;
$finalsale-new-payment-width: 100%;
$finalsale-new-payment-justify-content: center;

$finalsale-show-moreless-font-size: 11px;

$finalsale-different-autopay-method-margin-top: $form-check-input-margin-y;
$finalsale-different-autopay-method-margin-left: -$form-check-input-gutter;

$finalsale-draftdate-tooltip-top: 10px;
$finalsale-draftdate-tooltip-left: initial;
$finalsale-draftdate-tooltip-right: 0;

$finalsale-top-of-form-divide-border: none;

$finalsale-summary-head-background: #e5e5e5;
$finalsale-summary-head-padding: 20px;

$finalsale-fancyoptions-border: 1px solid #BCBCBC;
$finalsale-fancyoptions-active-headline-color: $brand-primary;
$finalsale-fancyoptions-active-border: 4px solid $brand-primary;
$finalsale-fancyoptions-active-checkbox-font-size: 1.35rem;
$finalsale-fancyoptions-active-checkbox-color: $brand-primary;
$finalsale-fancyoptions-active-checkbox-box-shadow: none;

$finalsale-group-margin-top: 15px;

$finalsale-group-policyholderesigngroup-border-bottom: 1px dotted $hd-medium-grey;
