
.customer-quote-content {
.finalsale-container {

    .selected-indicator-icon {
        width: $selected-indicator-icon-width;
        height: $selected-indicator-icon-height;

        @include media-breakpoint-up(lg) {
            width: $selected-indicator-icon-width-lg;
            height: $selected-indicator-icon-height-lg;
        }
    }

	.premium-and-downpayment-labels {
		text-align: right;
	}
	.billPlanCollapse {
		//adding relative stlying so that tooltips display next to appropriate bill plan offering
		position: relative;
	}

	.policy-term-well {
	  background: $gray-lighter;

	  label {
	      margin-top: 6px;
	  }

	  @include media-breakpoint-down(md) {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
		border: 0;
		box-shadow: none;
		margin-left: -15px;
		margin-right: -15px;

		label {
		 margin-top: 0;
		}
	  }
	}

//
// Draft Date
//----------------------------
	#draft-date-selection {
		margin-top: 15px;
		margin-bottom: 10px;
	}
	
	.col-draft-date {
	
	    @include make-col(12);
	    
	    @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(4);
        }
	}

	#AutomaticPaymentDraftDate {
		margin-right: 10px;
		width: 90%;
	}

	.AutomaticPaymentDraftDate-container {
		display: block;
	}

	#draft-date-client-errors {
		clear: both;
		padding-top: 5px;
	}



	/* custom styling */

		/* content section at top the shows rate state, uw company, effective date, and policy term */
		.summary-head {

			/* push it away from the main page header a bit more */
			@include media-breakpoint-up(lg) {
				margin-top: 20px;
			}

			/* tighten up the labels on the summary head */
			p {
				margin: 0 0 4px;
			}
		}


		/* best price label */
		.term-best-price {
		    font-size: 14px;
		    margin-left: 20px;
			color: $term-best-price-color;
			@include media-breakpoint-down(md) {
				margin-left: 0px;
			}
		}

		/* center tha font stack circle with checkbox */
		.fa-stack {

			margin: 0 auto;
		}
	}

	//
	// selector for the final sale form when a payment option has been added
	//--------------------------------------------------------------------------
	form.first-payment-added {

		/* after a payment method has been added we want to show "Change Method" */
		.first-new-payment {
			display: none;
		}
		.first-edit-payment {
			display: inline-block;
		}
	}
	form.auto-payment-added {
		.auto-new-payment {
			display: none;
		}
		.auto-edit-payment {
			display: inline-block;
		}
	}
	/* before a payment option has been added we need to hide the "Change Method" button and show "Add Method" */
	.first-new-payment, .auto-new-payment {
		display: inline-block;
	}
	.first-edit-payment, .auto-edit-payment {
		display: none;
	}

	//
	// enter payment information section
	//---------------------------------------
	.transaction-details-container {
		margin-bottom: 15px;
	}

	//
	// credit card form iframe and iframe container styling
	//--------------------------------------------------
	.container-iframe {
		height: $container-iframe-height;
		@include media-breakpoint-up(md) {
			height: $container-iframe-height-md;
		}

		.iframe-creditcard {
			height: 100%;
			width: 100%;
			border: 0;
		}
	}

	//
	// pay today input/label
	//-----------------------------
	.pay-today {
		h4, .h4 {
			padding-left: 0;
			margin-top: 0;
		}
		input,
		#pay-today-currency-sybmol {
		    color: $pay-today-currency-symbol-color;
		    font-weight: 	bold;
		    font-size: 		20px;
		}
		input[readonly] {
			padding-left: 	0;
			border: 		none;
			box-shadow: 	none;
		}
		.input-group-addon {

			/* overriding bootstrap padding here so it lines up with content above and below that aren't form elements */
			background: transparent;
			padding-right: 2px;
			padding-left: 0;
		}
	}

	/* payment section - last section of the page */
	.add-payment-container {
		h4 {
			padding-left: 0px;
		}
	}

	/* disclaimers that show below the payment options */
	.payment-options-disclaimer-container, .consent-disclaimer-container {
		font-size: 		$disclaimer-fontsize;
		font-weight: 	$disclaimer-weight;
		color:			$disclaimer-color;
	}

	/* disclaimers at the bottom of the page */
	.disclaimer-container {
		h4 {
			margin-top: 20px;
		}
	}

	/* payment disclaimers at the bottom of the page */
	.payment-disclaimer-container{
		line-height: normal;
		font-weight: bold;

		a {
			text-decoration: underline;
		}
	}

	//
	// tooltip overrides for final sale page
	//----------------------------------------------------
	.page-tooltip {
		right: 0px;
	}

    .policyholder-name{
		text-transform: uppercase;
		margin-bottom: 10px;
		@include media-breakpoint-up(md) {
			margin-bottom: 5px;
			margin-top: 15px;
		}
	}

	#add-payment-message,
	#add-automatic-payment-message {
		color: $state-danger-text;
		font-weight: bold;
	}

}


/** Iframe Modal fix for ios **/
@include media-breakpoint-down(lg) {

	.iframe-modal .page-wrap {

		height: 100%;
		max-height: 100%;
		overflow: hidden;

		.container-iframe {
			height: 1330px;
		}

		.modal-lg.modal-dialog {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;

			.modal-content {
				position: relative;
				height: 100%;
			}

			.modal-body {
				height: 100%;
				width: 100%;
				overflow: scroll;
			}
		}
	}
}

.final-sale-section {
    &.groupPaymentOptionSelection,
    &.policyholderESignGroup,
	&.groupAgreeToPaymentTerms,
	&.groupSinglePaymentMethod {
        margin-top: 15px;

        header {
            h3, h2 {
                border-top: 1px solid $brand-primary;
                padding-top: 15px;
            }
        }
	}
	
    &.groupSinglePaymentMethod,
    &.groupRecurringPaymentMethod {
        @include media-breakpoint-up(md) {
            width: 100%;
            display: inline-block;
            vertical-align: top;
        }
    }
}

.payment-button-container {
	@include make-col-ready();
	@include make-col(6);
	width: auto;
	min-width: 100%,
}

.auto-payments-required,
.auto-payments-not-required {
	//Variabilize
	margin-top: $auto-payments-question-margin-top;
}

.eft-first-payment-different-method-container{
	padding-top: 15px;
}

.show-less,
.show-more {
	width: 100%;
	text-align: center;
}