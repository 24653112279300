// icon overrides
.fa.icon-QuoteEdit:before {
    font-family: "HDFontsIQ";
    content: "\e90b";
}
.fa.icon-contact:before {
    font-family: "HDFontsIQ";
    content: "\e919";
}
.fa.icon-help:before {
    font-family: "HDFontsIQ";
    content: "\e914";
}

// vehicle type icons
.fa.icon-MOTORCYCLE:before {
    font-family: "HDFontsIQ";
    content: "\e905";
}
.fa.icon-PERSON:before {
    font-family: "HDFontsIQ";
    content: "\e92a";
}

.icon-QTE-tooltip:before {
    font-family: "HDFontsIQ";
    content: "\e914";
  }

.icon-mkt-homeowner:before {
    font-family: "HDFontsIQ";
    content: "\e904";
}

.add-entity-icon:before, .fa-plus-circle:before {
    font-family: "HDFontsIQ";
    content: "\e939";
}

.fa-pencil:before {
    font-family: "HDFontsIQ";
    content: "\e932";
}

.fa-print:before {
    font-family: "HDFontsIQ";
    content: "\e92d";
}

/* Vehicle */
.icon-HDQuoteIcons-01:before {
    content: "\e900";
}