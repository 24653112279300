/**
  * Harley Davidson Navigation Overrides
  *
*/
//
// Navigation
// --------------------------------------------------

// navbar contains top navigation and top search (by name, policy, claim number...)
.iq-navbar {
	
	background: $hd-hard-grey;
                
	@include media-breakpoint-down(md) {
    
	    max-height: $navbar-max-height-mobile;
	    transition: $navbar-transition;
	
	    /* Unable to extend BS4 .fixed-top because you may not @extend 
		an outer selector from within @media. You may only @extend 
		selectors within the same directive. */
	    background-color: $iq-navbar-bg;
	    position: fixed;
	    top: 0;
	    right: 0;
	    left: 0;
	    z-index: $zindex-fixed;
        border-bottom: 1px solid $hd-medium-grey;
	}
	
	padding-top: $iq-navbar-padding;
    padding-bottom: $iq-navbar-padding;

	@include media-breakpoint-up(md) {
	    padding-top: $navbar-padding-top-md;
	    padding-bottom: $navbar-padding-bottom-md;
	}
	
	&.navbar-default {
			
		// menu options on screen-xs
		li.visible-xs {
            
        	> ul {
            	padding		: 0px;
            	list-style  : none;
				text-align	: center;
        	}
		}
	}

    .save-and-exit {
        background: none;
        border: none;
        padding: 0;
    }
	
	// just top navigation container	
	.navbar-main {
	
		width: 100%;
		
		.navbar-main-content{
			
			a {
			
				&.btn-iq-phone,
				&.btn-iq-help,
				&.btn-iq-edit {
					color : $iq-navbar-button-color;

					// So the phone number can be displayed on mobile
					@include media-breakpoint-down(sm) {
						font-size: $iq-navbar-button-font-size;
						padding: $iq-navbar-button-padding-xs;
					}	
				}
			}
					
			div {

				&.quote-phone-number {
			
					display: inline-block;
				}

				&.bind-phone-number {
			
					display: none;
				}
			}
		}
	}
	
	// unordered list container for the navigation list items
	.navbar-nav {
		width: 100%;
		
		.fa-stack{
			margin: 0 auto;
			@include media-breakpoint-up(md) {
				display: block;
			}			
		}
		
		// Navigation Items (Quote, Billing, Policies, Claims, Reports)
		> li {
				
			.nav-label {
				text-transform: uppercase;
				font-size: 1.65em;
				vertical-align: middle
			}
			
			@include media-breakpoint-up(md){
				width: 14%;
				
				.nav-label {
					font-size:1em;
				}
			
				&:not(:first-child) {
					.nav-label {
						display: block;
						border-left: 1px solid $brand-primary;
					}
				}
			}
		
			&.visible-xs {
				// dropdown menu button for small screen
				button {
					width	: 100%;
				}
				// menu nav items for small screen			
				li {
					font-size		: 16px;
					padding-bottom	: 0;					
					
					> a {
						width           : 100%;
						height          : 100%;
						display         : block;
						color           : #fff;
						border-bottom   : 1px solid #ccc;
						padding         : 8px 0;
				
						&:hover,
						&:focus {
							color               : $navbar-default-link-color;
							text-decoration     : none;
						}
					}
				}
			}
			
			&.active {
				>a {
					i {
						//background-color: $navbar-default-link-active-color;
					}				
				}
			}
				
			> a {
			
				padding:		2px;
				text-align: 	left;
				width: 			100%;
				
				@include media-breakpoint-up(md) {
					padding: 10px 0 0 0;
					text-align: center;
				}
				
				@include media-breakpoint-up(lg) {
					padding: 0px;				
				}
				
				&:hover {
					i {
						//background-color: $navbar-default-link-hover-color;
					}
				}
				
				i {
					&.fa-stack-1x {
						color: $fontcolor-brand-primary-bg;
					}
				}
				
				> img {
				
					&.img-responsive {
						max-width: 100%;
					}
				}
			}
		}
	}	
}

body {
	
	padding-top: $navbar-max-height-mobile;
	@include media-breakpoint-up(lg) {
	    padding-top: 0;
	}
	
	&.post-bind {
	
		.navbar {
			
			// just top navigation container	
			.navbar-main {
				
				.navbar-main-content{
					div {
						&.quote-phone-number {
						
							display: none;
						}
					
						&.bind-phone-number {
						
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

#topnav-links-column {
	margin-top: 10px;
	
	@include media-breakpoint-up(md){
		margin-top: 0px;
	}
}

.iq-navbar-contact-column {				
	padding: $iq-navbar-button-column-padding;
	flex-direction: $navbar-contact-column-flex-direction;
}

.iq-navbar-edit-column {				
    transition: $navbar-transition;
	padding: $iq-navbar-button-column-padding;
}

.navbar-logo-column {
	margin-top: 0;
    transition: $navbar-transition;

    /* Harley-Davidson logo is too wide to leave it at the mercy of automatic size adjustment */
    img.img-fluid {
        /* iPhone 4/5 and similar */
        min-width: 140px;
        @media screen and (min-width: 360px) {
            /* Galaxy S5 and similar */
            min-width: 180px;
        }
        @media screen and (min-width: 375px) {
            /* iPhone 6/7/8 and similar */
            min-width: 190px;;
        }
        @media screen and (min-width: 410px) {
            /* Pixel 2 and larger */
            min-width: 210px;
        }
    }
}

.environment {
    position: fixed;
    font-weight: 700;
    z-index: 1000;
    top: initial;
    right: 15px;
    display: block;
    bottom: 15px;
	
	@include media-breakpoint-up(md) {
	    display: block;
		right: 46%;
	}
	@include media-breakpoint-up(lg) {
		right: 45%;
	}
	@include media-breakpoint-up(xl){
		right: 40%;
    	width: 240px;
	}
	
	&.PROD {
	
		display:none;
	}

	.stability-smarts {

		.card {
			border: 2px solid black;

			&.degraded {
				border-color: orange;

				.card-header {
					background: orange;
	
					button {
						color: #fff;
					}
				}
			}

			&.down {
				border-color: red;

				.card-header {
					background: red;

					button {
						color: #fff;
					}
				}
			}

			&.up {

				border-color: green;

				.card-header {
					background: green;

					button {
						color: #fff;
					}
				}
			}

			.card-header {
				padding: 0;
				background: #fff;

				h5 {
					margin-bottom: 0;
				}

				button {
					padding: 15px;
					text-align: left;
					width: 100%;

					.stability-name {
						display: none;

						@include media-breakpoint-up(lg) {
							display: inline-block;
						}
					}
				}
			}

			.card-body {

				padding: 0;

				.list-group-item {

					text-align: left;
					background: #fff;

					.fa-circle {
						color: green;
					}

					&.system-degraded {
						.fa-circle {
							color: orange;
						}
					}
	
					&.system-down {
	
						.fa-circle {
							color: red;
						}
					}
				}
			}

		}
	}
}

.navbar-main-content {
    .fa {
        padding-left: .4rem;
    }
}
