body.quote {
	.customer-quote-content{
		.quote-container{
			.score-report-verification-form {
				h4 {
					padding-left: 0;
				}
				.user-entered-info-header{
					padding-top: 20px;
					h4{
						font-weight: bold;
					}
				}
				.user-entered-info-detail {
					background-color: $brand-gray-light;
					padding: 10px 20px;
					margin-top: 25px;
				}
				.quote-form {
					padding-top: 20px;
				}
			}		
		}
	}
}