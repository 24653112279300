.uwadditionalinfo-container {

	/* defect 8355 need to hide the tooltip for effective date on this page */
	.EffectiveDate {				
		.page-tooltip {
			display:none;
		}
	}
	
	#RateTier {
		appearance: normal;
		pointer-events: none;
		cursor: default;
		background: transparent;
		border: 0;
		outline: none;
		box-shadow: none;
		color: $label-color;
	}
	
	.uwadditionalinfo-form {
		.errors-list-container {
			margin-bottom: 0px;
	
			.forms-errors-list {
				margin-bottom: 0px;
	
				li:last-child {
					margin-bottom: 15px;
				}
			}
		}
	}
	
	.addfrinfo {
		&:hover {
			color: none;
		}
		&:disabled {
			color: $white;
		}
	}
	
}
