/**
  * Harley Davidson Date Picker Overrides
  */

//
// Date Picker Overrides
// --------------------------
.datepicker {
	table {
		color: $hd-hard-grey;
		.prev, .next {
			color: $hd-hard-grey;
			cursor: pointer;
		}
		tr {
			td.disabled {
				color: #999999;
			}
			td.today {
				background: #ffffff;
				color: $hd-hard-grey;

				&:hover {
					color: $hd-hard-grey;
					background-color: $primary-light-gray;
					border-color: $primary-light-gray;
				}
			}
			td.active {
				&:hover {				
					&.active {				
						border-color: transparent;
						&:hover {
							border-color: transparent;
						}
					}
				}
				&.active {
					border-color: transparent;
					&:hover {
						border-color: transparent;
					}
				}
			}
		}
	}
}