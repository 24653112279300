//
// Global Styles
// --------------------------------------------------
html {
	position	: relative;
	min-height	: 100%;
}

body {
	// never show horizontal scroll bar
	overflow-x	: 			hidden;
	background-color :		$body-background-color;
	background-image : 		none;
	@include media-breakpoint-up(md) {
		background-image : 		$background-image;
		background-repeat :		no-repeat;
		background-size :		cover;
		background-position :	center;
		background-attachment :	fixed;
	}
}

// the hidden class utility was removed in BS4 but I can't let go of it yet, if time permits
// we can replace this using bootstrap's class .d-none but there's a lot of JS that will need
// be udpated as part of this as well, and a site-wide replacement of "hidden" to "d-none" is
// probably not a great idea.
.hidden {
    display: none !important;
}

//
// Omni test elements
//------------------------
.coverage-container {
    .omnitest {
        .continue-billplan-purchase {
            margin-left: 0;
        }
    }
}
.omnitest {
    .offering-purchase-btn, .local-agent-action, .continue-billplan-purchase, .calculate-coverage-button, .btn-quote-submit {
        font-size: 1em;
        max-width: 110px;
        word-wrap: break-word;
        white-space: normal;
        min-height: 60px;
    }
    .local-agent-action {
        margin-right: 10px;
    }
    .continue-billplan-purchase {
        margin-left: 0;
    }
}

//
// Page Content
// --------------------------------------------------
.content{
	padding-top		: $content-padding-top;
	padding-bottom	: $content-padding-bottom;
	position: relative;
}

//Tooltips
.page-tooltip {
	position: absolute;
	font-size: 2em;
	left: 2px;
	cursor : pointer;
	color : $tooltip-color;

	&:hover {
		text-decoration : none;
	}
}
.tooltip-and-required{
    position: absolute;
	top: 0;
	left: 100%;

    .tooltip-msg-list li+li {
    	margin-top : 10px;
    }
}

.popover-body {
	text-align : left;
	cursor:pointer;

	ul {
		padding-left : 20px;
	}

	//[x] button in Tooltip
	.btn-close-tooltip{
		position: absolute;
		right: 7px;
		top: 7px;
	}
	//Message Displayed in Tooltip
	.tooltip-msg{
		padding-right: 15px;
	}
}

.popover{
	background-color: $tooltip-popover-bg-color;
	border: 0.25px solid $tooltip-popover-bg-color;
	width: 250px;
	border-radius: 0px;
	box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.4);

	@include media-breakpoint-up(sm){
		width: 400px;
	}

	//Common attributes for popovers regardless of their direction
	&.left, &.right, &.bottom, &.top{
		//Override BootStrap Tooltips Z-Index
		z-index: 9;

		.arrow{
			z-index: 			1;

			&:after {
				content: 			"";
				width: 				5px;
				height:				10px;
				border: 			10px solid transparent;
				position:			absolute;
				top: 				-10px;
				left: 				-12px;
				z-index:			1;
			}
		}
	}

	//specific attributes depending on direction of popover
	&.left{
		.arrow{
			border-left-color: 	$tooltip-arrow-color;

			&:after {
				border-left-color: 	$tooltip-arrow-border-color;
			}
		}
	}

	&.right{
		.arrow{
			border-right-color: 	$tooltip-arrow-color;

			&:after {
				border-right-color: 	$tooltip-arrow-border-color;
			}
		}
	}

	&.top{
		.arrow{
			border-top-color: 	$tooltip-arrow-color;

			&:after {
				border-top-color: 	$tooltip-arrow-border-color;
			}
		}
	}

	&.bottom{
		.arrow{
			border-bottom-color: 	$tooltip-arrow-color;

			&:after {
				border-bottom-color: 	$tooltip-arrow-border-color;
				left:	 				0px;
				top:					-8px;
			}
		}
	}
}

//Toggle Buttons
.btn-group[data-toggle="buttons"]{
    .btn,
    .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
    .btn:first-child:not(:last-child),
    .btn:last-child:not(:first-child){
        border-radius: $border-radius-small;
        margin-left: 0;
    }

    .btn-secondary{
        color:  $btn-radio-default-color;
        background-color: $btn-radio-default-bg-color;
    }

    .btn-secondary:hover{
		color: $btn-radio-default-color-hover;
        background-color: $btn-radio-default-bg-color-hover;
    }

    .btn-secondary:focus,
    .btn-secondary.focus,
    .btn-secondary:active,
    .btn-secondary.active{
        background-color: $btn-radio-default-bg-color-active;
		box-shadow: $btn-radio-active-box-shadow;
		color: $btn-radio-default-color-hover;
    }

    //Make Toggle Buttons of Equal Width
     .toggle-btn{
        width: $toggle-button-width;
        margin-right: 15px;
        margin-bottom: 1px;
    }
}

//Iframe Container
.container-iframe{
    iframe{
       border: none;
       width: 100%;
       height: $payment-iframe-height;
       @include media-breakpoint-up(lg) {
            height: $payment-iframe-height-lg-up;
        }
    }
}

//
// Parsley Overrides
//--------------------------------
	// overriding inherited ul/ol styles on parsley elements so we don't get unexpected spacing when they are hidden
.parsley-errors-list {
	margin : 0;
	padding : 0;
}

	// parsley <ul> messes display up on radio buttons
.btn-group {

	position : relative;

	.parsley-errors-list {
		position: absolute;
		top: 35px;
		left: 0;
		width: 250px;
	}
}

// this is the button style "Add Driver" and "Add Vehicle" buttons use when going through quote flow
.btn-add-entity {
	@include button-variant($fontcolor-brand-primary-bg, $btn-add-entity-color);
	border-radius: 5px;
	border :1px solid $btn-add-entity-color;

	i.fa {
		color 		: $brand-complementary-1;
		font-size 	: 1.2em;
	}
}

.add-entity-btn {

	color: $btn-add-new-entity-color;
	background-color: $add-entity-btn-background-color;
	font-size: 15px;
	margin-top: 15px;

	@include media-breakpoint-up(md){
	    margin-top: 0;
	}

	&:hover{
	    color: $btn-add-card-color-hover;
	}

	.icon-circleplus{
	    vertical-align: middle;
	}
}

.btn-remove-entry {
	/* per style guide requirements the remove buttons should look like a link rather than a button */
	color: 					$btn-remove-entry-color;
	text-decoration: 		underline;
	text-decoration-color: 	$btn-remove-entry-color;
	background: 			transparent;
	border: 				transparent;
	font-weight: 			normal;

	&:hover {
		color: 					$btn-remove-entry-color;
		text-decoration: 		underline;
		background: 			transparent;
	}

	i.fa {
		display: none;
		color 		: $btn-remove-entry-icon-color;
		font-size 	: 1.2em;
	}
}

.btn-complementary {
	@include button-variant($complementary-button-bg,$complementary-button-border);
}

.btn-disabled {
	@include button-variant($btn-disabled-bg, $gray-lighter);
	//border-style: dashed;
	&:hover {
		background : $btn-disabled-bg;
	}
}

// This is copied from _button-group.scss from boostrap. 
// We are inserting a div between the btn-group-toggle and btn classes so needed to update the selector
.btn-group-toggle > .billPlanCollapse > .btn,
.btn-group-toggle > .billPlanCollapse .billPlanCollapse > .btn
 {
	//margin-bottom: 0; // Override default `<label>` value

	input[type="radio"],
	input[type="checkbox"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
	}
}

.margin-top-none {
	margin-top : 0 !important;
}
.margin-bottom-none {
	margin-bottom : 0 !important;
}

//
// Disable styles
//

.form-control[readonly],
*[readonly],
.form-control.readonly,
.readonly,
.form-control[disabled],
*[disabled] {
	pointer-events		: none;
	cursor				: default;
	background			:transparent;
	outline: none;
	@include box-shadow(rgba(0, 0, 0, 0.25) 0 0 2px inset);
	color: $readonly-input-color;
}
select.form-control[readonly],
select[readonly],
select.form-control.readonly,
select.readonly,
select.form-control[disabled],
select[disabled] {
	outline: none;
}
.select-empty-value {
	color: $empty-select-color;
	option {
		color: $input-color;
		&[value=""] {
			color: $empty-select-color;
		}
	}
}

.even {
	background-color : $striped-row-background;
}

a.disabled {

	color : $gray-light;
	cursor: $cursor-disabled;

	&:hover {
		text-decoration:none;
	}
}

.checkbox-wrapper {
    @extend .form-check;
}
.checkbox-input {
    @extend .form-check-input;
}
.checkbox-label {
    @extend .form-check-label;
}

//
// MARGIN AND PADDING BOOTSTRAP OVERRIDES
// form element overrides for quote
// in order to reduce the length/height of collection sets, we are making these overrides to bootstrap elements only for quoters, input elements elsewhere on the site shouldn't be affected by these changes
//----------------------------------------------

//This is to remove gutter (margin, padding) on an element
.no-gutter{
  margin-right: 0;
  margin-left: 0;

  [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

//
// terms and conditions
//--------------------
.terms-and-conditions {
	background: 	white;
	padding-bottom:	15px;
	font-size:		$body-fontsize;
	font-weight:	$body-weight;
	color: 			$body-color;
	line-height:	$body-lineheight;
}


//
// Modals
//---------------------
.modal {
	/* radio inputs for how the user wants to retrieve */
	.modal-input-group-addon {
		background: transparent;
	}
}

//
// Old Android scrollbar fix
//------------------------
@include media-breakpoint-down(lg) {
	::-webkit-scrollbar {
	    -webkit-appearance: none;
	}
	::-webkit-scrollbar:vertical {
	    width: 12px;
	}
	::-webkit-scrollbar:horizontal {
	    height: 12px;
	}
	::-webkit-scrollbar-thumb {
	    background-color: rgba(0, 0, 0, .5);
	    border-radius: 10px;
	    border: 2px solid #ffffff;
	}
	::-webkit-scrollbar-track {
	    border-radius: 10px;
	    background-color: #ffffff;
	}
}
.alert-warning{
	background-color	:	lighten($alert-warning-bg-color, 40%);
}

//
// Custom Mixins
// ------------
@mixin make-fancy-column($s, $s-md, $s-xl) {
	justify-content: center;
    flex-direction: column;
	@include make-col-ready();
	@include make-col($s);

	@include media-breakpoint-up(md) {
		@include make-col($s-md);
	}
	@include media-breakpoint-up(xl) {
		@include make-col($s-xl);
	}
}
