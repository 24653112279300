$confirmation-policy-documents-button-text-decoration: underline;
$confirmation-policy-documents-button-text-hover-decoration: underline;
$confirmation-stepnumber-circle-font-size: 2rem;
$confirmation-stepnumber-circle-top: 4px;
$confirmation-purchase-details-active-background: #e5e5e5;
$confirmation-purchase-details-active-color: $hd-hard-grey;
$confirmation-purchase-details-active-border-radius: 0;
$confirmation-purchase-details-border-top: 1px solid $hd-medium-grey;
$confirmation-nextsteps-number-background: $hd-light-grey-2;
$confirmation-nextsteps-body-background: $hd-white;
$confirmation-nextsteps-body-link-color: $hd-burnt-orange;
$confirmation-panels-border-radius: 5px;
$confirmation-panels-border-color: $hd-hard-grey;
$confirmation-panels-border-width: 2px;
$confirmation-panels-margin-bottom: 17px;
$confirmation-panels-header-color: $hd-white;
$confirmation-panels-header-background: $hd-hard-grey;
$confirmation-panels-header-text-transform: uppercase;
$confirmation-panels-header-font-weight: 600;
$confirmation-panels-header-padding: 5px;
$confirmation-panels-body-background: $hd-white;
$confirmation-panels-body-color: $hd-hard-grey;
$confirmation-panels-body-min-height: 64px;
$confirmation-panels-body-link-text-decoration: underline;
$confirmation-success-check-color: $hd-burnt-orange;
$confirmation-success-checkmark-font-size: 36px;
$confirmation-account-signups-checkbox-border: none;