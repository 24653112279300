// --------------------------------------------------
// Bootstrap 3 Variables
// --------------------------------------------------
$brand-primary:                  $hd-orange;
$brand-success:                  #8BC541;
$brand-danger:                   #bd2026;
$brand-info:                     $secondary-bright-blue;
$brand-warning:                  #ffcc00;
$gray:                           $primary-gray;
$gray-light:                     lighten(#000, 60%);   // #999
$gray-lighter:                   $primary-light-gray;
$font-family-serif:              "HD Brand Bold Cond", "Arial Narrow", sans-serif;
$font-size-h1:                   35px;
$font-size-h2:                   19px;
$dropdown-border:                rgba(0,0,0,.15);
$border-radius-base:             0;
$border-radius-small:            5px;
$cursor-disabled:                not-allowed;
$btn-primary-color:              $fontcolor-brand-primary-bg;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             darken($btn-primary-bg, 5%);
$input-border:                   $hd-medium-grey;
$input-color-placeholder:        $hd-hard-grey;
$state-success-text:             darken($brand-success, 20%);
$state-success-bg:               lighten($state-success-text, 60%);
$state-success-border:           lighten($state-success-text, 50%);
$state-info-text:                darken($brand-info, 10%);
$state-info-bg:                  lighten($state-info-text, 69%);
$state-info-border:              lighten($state-info-text, 59%);
$state-warning-text:             darken($brand-warning, 25%);
$state-warning-bg:               lighten($state-warning-text, 70%);
$state-warning-border:           lighten($state-warning-text, 60%);
$state-danger-text:              $brand-danger;
$state-danger-bg:                lighten($state-danger-text, 56%);
$state-danger-border:            lighten($state-danger-text, 46%);
$navbar-default-link-color:      #777;
$label-color:                    #000000;
$pre-border-color:               #ccc;
$hr-border:                      $hd-medium-grey;

// --------------------------------------------------
// End Bootstrap 3 Variables
// --------------------------------------------------

//** Load fonts from this directory.
$harley-font-path:        "../harleydavidson/fonts/";

@font-face {
	font-family: "H-D LETTERPRESS";
	src: 	url("#{$harley-font-path}hdletterpress-webfont.eot");
	src: 	url("#{$harley-font-path}hdletterpress-webfont.eot#iefix") format("eot"),
			url("#{$harley-font-path}hdletterpress-webfont.woff") format("woff"),
			url("#{$harley-font-path}hdletterpress-webfont.ttf") format("truetype"),
			url("#{$harley-font-path}hdletterpress-webfont.svg") format("svg");
}
@font-face {
	font-family: "HD Brand Norm";
	src: url("#{$harley-font-path}NotoSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: "HD Brand Cond";
	src: url("#{$harley-font-path}NotoSans-Condensed.ttf") format("truetype");
}
@font-face {
	font-family: "HD Brand Bold Cond";
	src: url("#{$harley-font-path}NotoSans-CondensedBold.ttf") format("truetype");
}
@font-face {
	font-family: "HD Brand Bold";
	src: url("#{$harley-font-path}NotoSans-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

$font-family-semi-bold: "HD Brand Bold", sans-serif;
$font-family-regular: "HD Brand Norm", sans-serif;
$font-family-condensed: "HD Brand Cond", sans-serif;
$font-family-bold-condensed: "HD Brand Bold Cond", sans-serif;
$font-family-bold: "HD Brand Bold", sans-serif;

//== Logos
$logo-bg: url("#{$context}/images/brand/dairyland/logo-black.png") 4px 8px no-repeat;
$logo-width: 162px;
$logo-height: 76px;

$brand-secondary:		$secondary-blue-gray;
$brand-complementary-1:	#fdbb16;
$brand-failure:			#C1282D;

$font-size-large-lg:	  ceil(($font-size-base * 2));
$fontsize-standard-sm: 	1.0em;
$fontsize-standard: 	1.2em;
$fontsize-standard-lg: 	1.4em;
$fontsize-small-sm:		.6em;
$font-weight-large:		400;
$input-border-color: 			$primary-dark-gray;


//----------------------------------------------------------------
// CUSTOM VARIABLES
// These are custom variables not used in Bootstrap framework
//----------------------------------------------------------------
$background-image: 				none;
$brand-gray-light:				$hd-light-grey-2;
$gray-base-fontcolor: 			#FFFFFF;

$quote-content-background-color: rgba(255,255,255);
$quote-content-box-shadow: none;

$brand-brown-dark:				#513a0c;

$card-padding: 					10px 0 0 0;
$card-border-radius:			0;
$card-bg: 						$hd-white;
$card-active-bg:				$hd-white;
$card-active-border-width:		0;
$card-header-leading:			20px;
$card-header-fontsize:			13px;
$card-header-color:				$hd-burnt-orange;
$card-header-marginbottom:		0px;
$card-header-color-inactive:	$hd-hard-grey;
$card-header-color-hover:		$hd-burnt-orange;

$card-subhead-leading: 			15px;
$card-subhead-marginbottom:		5px;
$card-subhead-fontsize:			15px;
$card-subhead-color:			$hd-burnt-orange;
$card-subhead-color-inactive:	$hd-hard-grey;
$card-subhead-color-hover:		$hd-burnt-orange;

$card-status-leading:			        18px;
$card-status-fontsize: 			        13px;
$card-status-color-complete: 	        $hd-hard-grey;
$card-status-color-incomplete:	        $hd-hard-grey;
$card-status-color-active:		        $hd-burnt-orange;
$card-border-color-active: 				$hd-hard-grey;
$card-status-color-inactive:	        $hd-hard-grey;
$card-status-marginbottom:		        0px;
$card-status-color-hover:		        $hd-burnt-orange;
$card-status-selected-checkmark-color:	$hd-burnt-orange;
$card-status-has-error-color:	        $brand-danger;


$card-border-color:				$hd-hard-grey;
$card-summary-border-color:		transparent;
$card-summary-color:			$hd-burnt-orange;
$card-edit-icon-color:			$hd-burnt-orange;
$card-link-color:				$hd-white;

$disclaimer-fontsize:			11px;
$disclaimer-weight:				normal;
$disclaimer-color:				$hd-hard-grey;
$disclaimer-container-margin-top: 0;
$disclaimer-container-padding-bottom: 15px;
$disclaimer-container-padding-top: 0;
$disclaimer-container-font-family: $font-family-base;
$disclaimer-container-font-weight: normal;
$disclaimer-container-text-transform: none;

$auto-payments-question-margin-top: 1.5rem;

$header2-fontsize:				19px;
$header2-color:					$hd-hard-grey;
$header2-weight:				bold;

$body-fontsize:					14px;
$body-color:					$hd-black;
$body-weight:					normal;
$body-lineheight:				20px;

$tooltip-header-weight:			bold;
$tooltip-header-fontsize:		15px;
$tooltip-header-color:			$brand-primary;
$tooltip-copy-weight:			normal;
$tooltip-copy-fontsize:			15px;
$tooltip-copy-color:			$brand-primary;

$tooltip-content-width:			350px;

$formheader-fontsize:			12px;
$formheader-color:				$hd-hard-grey;
$formheader-bg-color:           transparent;
$formheader-weight:				bold;

$formquestion-copy-weight:		normal;
$formquestion-copy-fontsize:	15px;
$formquestion-copy-color:		$hd-hard-grey;

$paperless-modal-header-weight: bold;
$paperless-modal-header-font-size: 24px;
$paperless-modal-header-margin: 20px;

$calendar-icon-color:  			$hd-burnt-orange;

//-------------------------------------------
// Form Controls
//-------------------------------------------
$quote-question-letter-spacing: -.08em;
$form-placeholder-opacity: .8;

//-------------------------------------------
// Tables
//-------------------------------------------
$table-heading-padding:			10px 8px;

//-------------------------------------------
// Data Tables
//-------------------------------------------
$filter-icon-width:				20px;

$content-padding-top: 				0;
$content-padding-bottom: 			40px;

//-------------------------------------------
// Buttons
//-------------------------------------------
$primary-button-bg:				$hd-burnt-orange;
$primary-button-color: 			#FFFFFF;
$primary-button-hover:			darken($hd-burnt-orange, 20%);
$primary-button-border:		    transparent;

$secondary-button-color:       	#000;//#fff;
$secondary-button-bg:          	$brand-secondary;
$secondary-button-border:      	darken($secondary-button-bg, 5%);

$complementary-button-color: 	#000;
$complementary-button-bg: 		$brand-complementary-1;
$complementary-button-border: 	$brand-complementary-1;

$success-button-bg: 			$brand-success;
$success-button-color: 			#FFFFFF;
$success-button-border: 		$brand-success;

$btn-setting-off: 				#fff;
$btn-setting-off-bg: 			#FF0000;
$btn-setting-off-border: 		darken($btn-setting-off-bg, 5%);

$btn-setting-on: 				#fff;
$btn-setting-on-bg: 			green;
$btn-setting-on-border: 		darken($btn-setting-on-bg, 5%);

$btn-disabled: 					#FFFFFF;
$btn-disabled-bg: 				$primary-dark-gray;
$btn-disabled-border: 			$primary-dark-gray;

$btn-action-color: 			$hd-white;
$btn-action-bg:		 		transparent;
$btn-action-border: 		transparent;
$btn-action-border-radius:  0;
$btn-action-hover: 			darken($hd-burnt-orange, 20%);
$btn-action-hover-color:	$hd-white;
$btn-action-hover-border: 	transparent;

$btn-add-new-entity-color:	$hd-burnt-orange;
$btn-add-card-color:		$hd-burnt-orange;
$btn-add-card-color-hover:	darken($hd-burnt-orange, 20%);
$add-entity-btn-background-color: transparent;

$btn-add-entity-bg:			transparent;
$btn-add-entity-border:		transparent;

$summary-button-margin-top:		20px;

$toggle-button-width: auto;

$add-another-thing-button-font-size: 1.5rem;
//-----------------------------------
// Button Group - radio buttons
//-----------------------------------
$btn-radio-default-color:						$hd-white;
$btn-radio-default-bg-color:					transparent;
$btn-radio-default-color-hover:                 $hd-white;
$btn-radio-default-bg-color-hover:				$hd-hard-grey;
$btn-radio-default-bg-color-active:				transparent;
$btn-radio-default-bg-color-active-box-shadow:	transparent;
$btn-radio-active-box-shadow:                   rgba(54,29,93,0.5);

//-----------------------------------
// Remove button
//-----------------------------------
$btn-remove-entry-color:		$secondary-orange;
$btn-remove-entry-icon-color:	$brand-danger;

//------------------------------------
// Alert
//------------------------------------
$alert-warning-bg-color:		#e0e0e0;
$alert-text-color:				#333333;

//------------------------------------
// Modals
//------------------------------------
$modal-content-link-color:		$hd-burnt-orange;
$modal-header-bg-color:			$hd-white;
$modal-close-btn-variant-color:		$hd-burnt-orange;
$modal-close-btn-variant-bg:		$secondary-blue-gray;
$modal-close-btn-variant-border:	#FFF;

//-----------------------------------
// Quote
//-----------------------------------
$accordion-border:					2px solid #e6e6e6;
$quote-summary-affix-box-shadow:	$hd-black;
$quote-container-bg:				$hd-white;
$quote-side-margin:					15px;

//-------------------------
// Entity Summary
//-------------------------
$summary-edit-entity-card-border-color: transparent;
$summary-edit-entity-card-box-shadow: 0px 0px 3px #AAA;

//-----------------------------------
// Quote Final Sale
//-----------------------------------
$finalsale-payment-option-bg:		transparent;
$finalsale-payment-option-inactive-border-color: #BCBCBC;
$container-iframe-height-md: 1315px;
$container-iframe-height: 1475px;

//-----------------------------------
// Datepicker
//-----------------------------------
$datepicker-bg-color:			#fff;
$datepicker-border-color:		$hd-hard-grey;
$datepicker-day-focus-bg-color:	$hd-burnt-orange;
$datepicker-day-color:			$primary-dark-gray;
$datepicker-day-disabled-color:	$primary-dark-gray;
$datepicker-today-border-color:	transparent;
$datepicker-today-color-hover:	#ffffff;
$datepicker-today-bg-color:		$secondary-blue-gray;
$datepicker-today-bg-color-hover:	$hd-burnt-orange;
$datepicker-range-disabled-bg-color:	$gray-lighter;
$datepicker-td-border-radius:	0;
$datepicker-week-color: $hd-white;
$datepicker-week-border-top: transparent;
$datepicker-week-border-bottom: transparent;
$datepicker-week-background: $hd-hard-grey;
$datepicker-days-switch-color: $hd-hard-grey;
$today-bg: 						$hd-burnt-orange;
$today-color: 					#fff;

//-----------------------------------
// Tooltips
//-----------------------------------
$tooltip-color:						$hd-burnt-orange;
$tooltip-popover-bg-color:			$primary-dark-gray;
$tooltip-popover-content-color:		$primary-dark-gray;
$tooltip-popover-content-bg-color:	#fff;
$tooltip-arrow-color:				$primary-dark-gray;
$tooltip-arrow-border-color:		#fff;
$box-shadow-popover:                0 0px 5px 1px rgba(54,29,93,0.6);

//---------------------------------------
// Navigation
//---------------------------------------
$navbar-padding-top-md:                1.1rem;
$content-padding-top: 				   0;
$content-padding-bottom: 			   40px;
$navigation-link-color:				   $hd-white;
$navbar-bg:                            $gray-900;
$navbar-max-height-mobile:             80px;
$contact-us-button-font-size:          1.0625rem;
$navbar-padding-bottom:                1.675rem;
$navbar-padding-bottom-md:             .5rem;
$navbar-contact-column-flex-direction: row;

//---------------------------------------
// Footer
//---------------------------------------
$footer-height-quote-xs:		65px;//270px;
$footer-height-quote-sm:		50px;//155px;
$footer-height-quote-md:		50px;//120px;
$footer-height-quote-lg:		50px;//112px;
$footer-bg: 					linear-gradient($hd-medium-grey 100px, darken($hd-medium-grey, 35%));
$footer-setting-color: 			$primary-light-gray;
$footer-link-color:				$hd-white;
$footer-anchor-text-decoration: none;

.typography-footer-text {
	font-family: $font-family-bold-condensed;
	font-size: 12px;
	line-height: 18px;
	color: $footer-setting-color;
}

//------------------------------------
// Quote Sidebar
//------------------------------------
$quote-affix-color:                                     $hd-white;
$quote-affix-padding:                                   10px 0 10px 0;
$quote-affix-h4-color:					                $hd-white;
$quote-affix-sidebar-header-border-color:	            initial;
$quote-affix-sidebar-active-arrow-color:	            transparent;
$quote-affix-sidebar-active-arrow-size:                 24px;
$quote-affix-sidebar-active-arrow-top-pos:	            0;
$quote-affix-sidebar-list-data-color:	                #CCC;
$quote-affix-sidebar-active-list-data-color:            #CCC;
$sidebar-border-right-width: 			                0;
$quote-affix-edit-link-color:			                $hd-burnt-orange;
$quote-affix-edit-link-hover-color:		                darken($quote-affix-edit-link-color, 15%);
$sidebar-active-bg: 					                transparent;
$quote-affix-sidebar-purchase-summary-headers-color:    $hd-light-grey-2;
$sidebar-arrow-height: 40px;
$sidebar-arrow-width: 20px;
$quote-affix-sidebar-item-padding-left: 0;

$form-add-btn-align: bottom;


//------------------------------------
// Confirmation Screen
//------------------------------------
$confirmation-panel-body-color:			$hd-white;
$confirmation-step-media-border:		transparent;
$confirmation-step-media-background:	transparent;
$confirmation-step-media-circle-color:	$hd-hard-grey;
$confirmation-step-media-heading-color:	$hd-hard-grey;
$confirmation-list-border:				transparent;
$confirmation-list-border-active:		transparent;
$confirmation-forms-color:				$hd-white;
$confirmation-forms-print-all:			$hd-burnt-orange;
$confirmation-forms-print-all-hover:	darken($hd-burnt-orange, 15%);

//---------------------------------------
// Colors
//---------------------------------------
$label-disabled-highlight:			$primary-dark-gray;
$label-disabled-color:				$hd-white;
$label-disabled-border:				1px solid $primary-dark-gray;
$label-disabled-circle-background:	transparent;
$label-disabled-circle-color:		$hd-burnt-orange;

$label-active-highlight:			$hd-burnt-orange;
$label-active-color:				$hd-burnt-orange;
$label-active-border:				1px solid $hd-burnt-orange;
$label-active-circle-background:	$hd-burnt-orange;
$label-active-circle-color:			$hd-burnt-orange;

$label-inactive-highlight: 			$hd-light-grey-1;
$label-inactive-color:				$hd-light-grey-1;
$label-inactive-border:				1px solid $hd-light-grey-1;
$label-inactive-circle-background:	transparent;
$label-inactive-circle-color:		$hd-light-grey-1;

$secondary-yellow-button-color:		#000;
$secondary-yellow-button-bg:		$secondary-yellow;
$secondary-yellow-button-border:	darken($secondary-yellow, 5%);

$link-reset-coverage:				$hd-burnt-orange;
$quote-coverage-edit-link-color:	$hd-burnt-orange;

$striped-row-background: 		#F2F2F2;
$content-divider-color: 		#999999;

//---------------------------
// Page scroll
//---------------------------
$scrolled-padding-top: .75rem;
$scrolled-padding-bottom: .75rem;

$quote-question-label-color:        $hd-hard-grey;
$readonly-input-color:              rgba(99, 99, 99, 0.9);
$empty-select-color:				#666;
$body-background-color:            	$hd-white;
$summary-card-active-box-shadow:    none;
$add-entity-submit-font-size:       14px;
$add-entity-submit-margin-right:    0px;
$option-message-color:              $hd-medium-grey;
$discount-field-h3-font-size:       18px;
$discount-field-h3-font-family:		"HD Brand Bold", Arial, sans-serif;
$discount-field-h3-color:           $hd-hard-grey;
$coverage-category-label-border-top: 1px dotted $hr-border;
$ajax-loading-icon-image: url("#{$context}/images/common/ajax-loader.gif");
$ajax-loading-width: 480px;
$ajax-loading-height: 80px;
$ajax-loading-background: transparent;
$ajax-loading-icon-opacity: 1;
$ajax-loading-animation: spin 1s linear infinite;
$spinner-border-width: 6px;
$spinner-border-style: solid;
$spinner-border-radius: 50%;
$spinner-border-color: $hd-offwhite;
$spinner-border-top-color: $hd-black;
$spinner-border: $spinner-border-width $spinner-border-style $spinner-border-color;
$spinner-border-top: $spinner-border-width $spinner-border-style $spinner-border-top-color;
$spinner-border-color-whiteout: $hd-hard-grey;
$spinner-border-top-color-whiteout: $hd-light-grey-2;
$spinner-border-whiteout: $spinner-border-width $spinner-border-style $spinner-border-color-whiteout;
$spinner-border-top-whiteout: $spinner-border-width $spinner-border-style $spinner-border-top-color-whiteout;
$term-best-price-color: #b54716;
$quote-affix-xs-sm-background: rgba(66,66,66,0.95);
$confirmation-disclaimer-content:  1px;
$ui-loader-saving-indicator-color: $hd-white;
$affix-quote-summary-text-align: left;
$quote-summary-header-text-align: left;
$modal-control-label-color: $hd-hard-grey;
$quote-affix-label-circle-border: none;
$icon-checkmark-font-size: 45px;
$remove-padding-xs-screen-padding: 0px 30px;
$quote-container-min-height: 500px;
$radio-button-error-border: none;
$pay-today-currency-symbol-color: $hd-hard-grey;
$confirmation-success-check-font-size: 25px;
$print-all-button-container-text-align: left;
$label-font-size-mobile: 13px;
$label-font-size-desktop: 18px;
$container-affix-top: 0;
$container-affix-top-sm: 0;

$payment-iframe-height-lg-up: 800px;
$hanging-paragraph : 15px;

//NAVBAR
$content-padding-top: 				0;
$content-padding-bottom: 			40px;
$navigation-link-color:				#fff;
$contact-us-button-font-size:       1.0625rem;
$iq-navbar-padding:             1.4rem;
$iq-navbar-button-padding-xs: 0.313rem;
$iq-navbar-button-font-size: 1.563rem;
$iq-navbar-button-column-padding: 0rem;
$iq-navbar-bg: $hd-hard-grey;
$iq-navbar-button-color: $hd-white;

//FAQ
$iq-faq-close-size: 0.938rem;
$iq-faq-close-padding: 0.625rem;
$iq-close-top-margin-mod: -1.625rem;
$iq-close-right-margin-mod: -1rem;
$faq-phone-number-TB-padding: 0.625rem;
$faq-phone-number-LR-padding: 1.313rem;
$iq-faq-contact-help-padding: 0.188rem;
$faq-phone-icon-size: 1.188rem;
$faq-phone-number-size: 1.25rem;
$faq-card-border: 1px solid #697179;
$faq-contact-us-color: $hd-hard-grey;
$faq-questions-padding: 0.625rem;
$faq-title-top-padding: 1.563rem;
$faq-title-bottom-padding: 0.313rem;
$faq-down-chevron-size: 0.625rem;

//-----------------
// Effective Date
//-----------------
$icon-calendar-font-size: 35px;
$icon-calendar-right: 25px;
$icon-calendar-top: 8px;

//----------------------
// Driver Page
//----------------------
$incidents-info-border: none;

//---------------
// Coverage Page
//---------------
$coverage-form-margin-right-sm: 0;
$coverage-form-margin-left-sm: 0;
$coverage-form-margin-right: initial;