//
// Application Overrides
//---------------
body.quote {
	.customer-quote-content {
		.quote-container {
			.disclaimer-container {
				h4 {
					font-weight: normal;
					font-family: $font-family-sans-serif;
				}
			}
			.optional-indicator {
				font-style: normal;
				bottom: 6px;
			}
			.errors {
				.errors-list-container {
					padding-left: 0;
				}
			}

			.quote-bottom-links,
			.step-headers-container {
				position:relative;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}
.form-group {
	margin-bottom: 18px;
}

.input-group-container {
	margin-bottom: 4px;
}

#continue-address-selection {
    font-size: 18px;
    padding: 10px 15px;	
}

.save-and-exit-form {
    .btn-action {
        border-radius: $btn-action-border-radius;
        font-size: 18px;
        padding: 10px 15px;
    }
}

//
// Typography
//--------------------
h1, .h1 {
	margin-top: 0;
	font-size:21px;
	font-weight: normal;
}
.text-secondary-orange {
	color: $secondary-orange;
}
h1, .h1 {
	font-family: $font-family-serif;
	text-transform: uppercase;
	font-weight: bold;
}
h2, .h2 {
	text-transform: uppercase;
	font-family: $font-family-serif;
}
h4, .h4 {
  margin-bottom:20px;
}
.brand-header-title, .header-title {
	font-family: $font-family-hdboldcond-arialnarrow;
	&.active {
		text-transform: uppercase;
	}
}
.quote-container {
  form h4, form .h4 {
    font-family: $font-family-hdbold-arialnarrow;
    font-weight: bold;
	}

	.row-form-header .btn-show-summary {
	    font-size: 18px;
        padding: 10px 16px;
	}
}

.dynamic-repeatable-label {
	padding-left: 15px;
	font-size: 16px;
	text-transform: uppercase;
   	font-family: $font-family-serif;
}

.LicenseState {
	font-size: 16px;
	text-transform: uppercase;
   	font-family: $font-family-serif;
}
//
// Inputs
//--------------------------------------------------
#request-retrieve-quote-form {
	span.form-control {
		background: transparent;
	}
}
.form-control {
	border: 			1px solid $input-border;
	background-color: 	$hd-white;
	box-shadow: 		none;
	color: 				$hd-hard-grey;
	font-size: 16px;
	&:focus {
		opacity: 1;
		box-shadow: none;
	}
	&::-moz-placeholder {
	    color: $input-color-placeholder;
	    opacity: $form-placeholder-opacity; // See https://github.com/twbs/bootstrap/pull/11526
	}
	&:-ms-input-placeholder {
		color: $input-color-placeholder;
		opacity: $form-placeholder-opacity;
	} // Internet Explorer 10+
	&::-webkit-input-placeholder  {
		color: $input-color-placeholder;
		opacity: $form-placeholder-opacity;
	} // Safari and Chrome
}
select {
	&.form-control {
		//give full border
		border: 1px solid $input-border;
		color: $hd-hard-grey;
		padding: 15px 5px;
	}
	&.select-empty-value {
		color: $hd-hard-grey;
		option {
			color: $hd-hard-grey;
			&[value=""] {
				color: $gray-light;
			}
		}
	}
}
.radio-buttons {
	.btn-group.btn-group-toggle {
		label.btn {
			color: $hd-black;
			background: $hd-white;
			border: 1px solid $hd-light-grey-1;
			font-size: 18px;
			padding-left: 35px;
			text-transform: none;

			&:before {
				// open circle
				font-family : 'HDFontsIQ';
				content: '\e93c';
				color: $hd-medium-grey;
				font-size: 1em;
				position: absolute;
				left: 10px;
				top: 15px;
			}

			&:hover,
            &:focus {
				background: transparent;

                &:before {
                    color: $hd-burnt-orange;
                }
			}

			&.active {

                border-color: $hd-burnt-orange;
                color: $hd-burnt-orange;

				&:before {
					//checkmark
					font-family : 'HDFontsIQ';
					content: '\e92c';
					color: $hd-burnt-orange;
					font-size: 1.05rem;
					line-height: 0;
					top: 26px;
				}
			}
		}
	}
}

//
//Quote Container Background
// --------------------------------------------------
.quote-container {
	background: $quote-container-bg;
	h4, .h4 {
		color: 			$hd-hard-grey;
		font-family: 	$font-family-hdboldcond-arialnarrow;
		/*font-weight: 	bold;*/
	}
}

body.quote {
	.customer-quote-content {
		.quote-container {
			padding-bottom: 32px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}

// Tooltip Close Button
body.quote {
	.btn-close-tooltip{
		color: $hd-burnt-orange;
	}
}

//
// Content Background Colors
//-------------------------
.bg-row-header {
  @include bg-variant('.bg-row-header', $gray-lighter);
  background:transparent;
}

//
// Icon Overrides
// --------------------------------------------------
.fa.icon-checkmark {
	font-family : 'HDFontsIQ';

	&:before {
		content: "\e92b";
		font-family : 'HDFontsIQ';
	}
}
[name="billplanOption"][type="radio"] {
    top: 0;
}
.icon-circleplus {
	font-family : 'HDFontsIQ';
	font-size: 1em;
	vertical-align: 0;
  &:before {
  	font-family: "HDFontsIQ";
  	content: "\e939";
  }
}

.icon-questionmark.fa.page-tooltip,
.icon-questionmark {
	font-family: 'cfonts';
    color: $hd-burnt-orange;
    font-size: 1.3em;
}

body.quote {
	hr {
		border: 1px dotted $hr-border;
	}
}


.popover-body {
	background-color: $white;
}

.popover {
	border-width:  $popover-border-width;
}

/**
 * Dealer Number Collection Customizations
 *
*/
.dealerSearchOr {
	display: block;
	text-align: center;
	margin-top: 10px;

	&.visible-xs {
		margin-bottom: 15px;
	}
}
.DealerResults.form-group {
	.controls {
		overflow: visible;
		.form-control {
			height: auto;
		}
	}
}

//Add some custom styling for HD Warnings
.alert-warning {
	//Override border
	border-width: 3px;
	border-color: $hd-burnt-orange;
	color: $alert-text-color;
	background-color: $alert-warning-bg-color;
	padding: 0px 25px;
	ul {
		list-style:none;
		padding-left:0px;
	}
}

//
// Bootstrap Select overrides
//-------------------------------------

// Active state
#customer-quote-container .dropdown-menu > .active > a {
	&,
	&:hover,
	&:focus {
		color: $dropdown-link-active-color;
		@include gradient-bg($dropdown-link-active-bg);
	}
}

// Final sale pay plan offerings spacing
.term-sm-best-price-container {
	padding-bottom: 0.5rem;
}

@include media-breakpoint-down(sm) {
	.content {
		padding-bottom: 0px;
	}
}

// allowlist the pages where save and exit and edit quote should be presented to the user
.quote-step-policyinfo, 
.quote-step-addressstandardization, 
.quote-step-vehicle, 
.quote-step-vehicleone,
.quote-step-vehicletwo,
.quote-step-vehiclesummary,
.quote-step-driverone,
.quote-step-driversummary,
.quote-step-drivertwo,
.quote-step-driverthree,
.quote-step-driverfour,
.quote-step-driverfourpowersports,
.quote-step-vehiclepowersports,
.quote-step-vehicleonepowersports,
.quote-step-vehicletwopowersports,
.quote-step-garagingaddressstandardization, 
.quote-step-driver, 
.quote-step-driverassignment, 
.quote-step-underwriting, 
.quote-step-scoreverification,
.quote-step-coverage,  
.quote-step-uwadditionalinfo, 
.quote-step-uwadditionalinfopowersports, 
.quote-step-orderreports, 
.quote-step-reconcile, 
.quote-step-mvr, 
.quote-step-autoloss, 
.quote-step-accountclearance, 
.quote-step-confirmcoverages,
.quote-step-finalsale {
    &:not(.post-bind) {
        #footer {
            .save-and-exit {
                display: block;
            }
        }

		#quote-navigation {
			.btn-iq-edit {
				visibility: visible;
			}
		}
    }
}

.navbar {  
	
	.btn-iq-edit {
		visibility: hidden;
	}
}

button.close-btn {
    margin: 2px;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 0.5rem;
    font-size: 17px;
    position: absolute;
    right: 0;
}

.customer-quote-content .quote-container {
    h2, .h2, h4, .h4  {
        font-size: 16px;
        padding-left: 0;
    }
}

.btn.btn-action {
    border-radius: $btn-action-border-radius;
}
.btn.quote-submit {
    margin-top: 20px;
    margin-bottom: 20px;
}

// Underwriting Page
.additionalinfo-vehicle-container .label-tooltip [aria-label*="InterestType"] {
	display: none;
}

// Reconcile Page
.reconcile-form {
	.btn-primary,
	.btn-secondary,
	.btn-action {
		min-width: 100px;
	}
}

//
// Urgent Notification Banner
//------------------------------
#urgent-notification-banner {
	color: $black;
    background-color: $brand-gray-lightest;
	border-bottom: 1px solid $hd-hard-grey;
}