.modal-content.quoteblock-modal {
	.modal-body {
		padding: 0px;

		p {
			a {
				text-decoration: underline;
			}
		}
	}
}

