//
// Portal Imports
// --------------------------------------------------
@import "../../src/bootstrap/mixins.scss";
@import "../../src/bootstrap/functions.scss";
@import "variables/bootstrap.variables.custom.scss";
@import "variables/bootstrap4.variables.scss";
@import "../../src/sentry/commonvariables.scss";
@import "variables/variables.scss";
@import "../../src/bootstrap/variables.scss";
@import "../../src/bootstrap/mixins.scss";
@import "../../src/bootstrap/nav.scss";
@import "../../src/bootstrap/custom-forms.scss";
@import "../../src/bootstrap/forms.scss";
@import "../../src/sentry/global.scss";
@import "../../src/sentry/splash.scss";
@import "../../src/sentry/parsley.scss";
@import "../../src/sentry/authorization.scss";
@import "../../src/sentry/ajax.scss";
@import "../../src/sentry/navigation.scss";
@import "../../src/sentry/pagescroll.scss";
@import "../../src/sentry/footer.scss";
@import "../../src/sentry/portaldropdownmenu.scss";
@import "../../src/sentry/error/error.scss";
@import "../../src/sentry/policies/policypayment.scss";
@import "../../src/sentry/policies/eft.scss";
@import "../../src/sentry/policies/read-coverages.scss";
@import "../../src/sentry/quote/quote.scss";
@import "../../src/sentry/quote/quote.accountclearance.scss";
@import "../../src/sentry/quote/quote.summary.affix.scss";
@import "../../src/sentry/quote/quote.summary.scss";
@import "../../src/sentry/quote/quote.qualification.scss";
@import "../../src/sentry/quote/quote.vehicle.scss";
@import "../../src/sentry/quote/quote.driver.scss";
@import "../../src/sentry/quote/quote.driverassignment.scss";
@import "../../src/sentry/quote/quote.coverages.scss";
@import "../../src/sentry/quote/quote.cards.scss";
@import "../../src/sentry/quote/quote.score.scss";
@import "../../src/sentry/quote/quote.broadformvehicle.scss";
@import "../../src/sentry/quote/quote.underwriting.scss";
@import "../../src/sentry/quote/quote.orderreports.scss";
@import "../../src/sentry/quote/quote.reconcile.scss";
@import "../../src/sentry/quote/quote.uwadditionalinfo.scss";
@import "../../src/sentry/quote/quote.score.verification.scss";
@import "../../src/sentry/quote/quote.finalsale.scss";
@import "../../src/sentry/quote/quote.finalsale-confirmation.scss";
@import "../../src/sentry/quote/quote.uwerror.scss";
@import "../../src/sentry/quote/quote.quoteblock.scss";
@import "../../src/sentry/bind/bind.scss";
@import "custom/typography.scss";
@import "custom/ajax.scss";
@import "custom/sidenav.scss";
@import "custom/bind.confirmation.scss";
@import "custom/bind.esign.scss";
@import "custom/buttons.scss";
@import "custom/custom.affix.scss";
@import "custom/datepicker.scss";
@import "custom/drivertwo.scss";
@import "custom/fancyoptions.scss";
@import "custom/faq.scss";
@import "custom/forms.scss";
@import "custom/modal.scss";
@import "custom/navigation.scss";
@import "custom/quicklinks.scss";
@import "custom/progressbar.scss";
@import "custom/post-content-buttons.scss";
@import "custom/quote.coverage.scss";
@import "custom/quote.finalsale.scss";
@import "custom/quote.footer.scss";
@import "custom/quote.orderreports.scss";
@import "custom/quote.uwadditionalinfo.scss";
@import "custom/tab-tiles.scss";
@import "custom/tooltip.scss";
@import "custom/pagescroll.scss";
@import "custom/summarystep.scss";
@import "custom/custom.scss";
@import "custom/cards.scss";
@import "custom/fixthese.scss";
@import "custom/icons.scss";
@import "custom/quote.drivers.scss";
@import "custom/vehicleone.scss";
@import "custom/postbind.scss";
@import "../../src/sentry/driver-assignment/driverassignment.scss";
@import "custom/reconcile.scss";

@import "custom/coverage-loader.scss";
@import "../../src/sentry/coverage-tooltips/coverage-tooltips.scss";

// Copies based on their DL counterparts
@import "custom/forms.scss";
@import "custom/offerings.scss";
@import "custom/coverage-form.scss";
@import "custom/coverage-edit-coverages-overlay.scss";

.quote-form {

    .always-displayed-help-message {
        display: block;
    }
}