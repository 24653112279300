@keyframes whiteoutLoaderFadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.whiteout-loader {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-animation-name: whiteoutLoaderFadeIn;
    animation-name: whiteoutLoaderFadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    .calculating-label {
        font-size: 2rem;
        font-weight: bold;
        width: 75%;
        max-width: 280px;
		color: $hd-hard-grey;
    }

    .humongous-dollarsign {
		margin-bottom: 15px;
		color: $hd-hard-grey;
    }

    .savings-label {
        color: $hd-hard-grey;
    }

	.ui-icon-loading {
		top: 50%;
		width: 70px;
		height: 70px;
		border: $spinner-border-whiteout;
		border-top: $spinner-border-top-whiteout;
		background: $spinner-border-whiteout;
	}
}