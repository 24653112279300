// Context
// -------------------------
$context: "";

// Fonts
// -----------------------
$font-family-hdboldcond-arialnarrow:  "HD Brand Bold Cond", "Arial Narrow", sans-serif;
$font-family-hdboldcond-arial: "HD Brand Bold Cond", Arial, sans-serif;
$font-family-hdbold-arialnarrow: "HD Brand Bold", "Arial Narrow", sans-serif;
$font-family-hdcondensed-arialnarrow: "HD Brand Cond", "Arial Narrow", Arial, sans-serif;

// Style Guide Colors
// -------------------------
$hd-black:           #000;
$hd-orange:          #FF6600;
$hd-burnt-orange:    #c2550c;
$hd-hard-grey:       #333;
$hd-medium-grey:     #666;
$hd-light-grey-1:    #999;
$hd-light-grey-2:    #ccc;
$hd-offwhite:		 #eee;
$hd-white:           white;


$primary-dark-blue: 		#003766;
$primary-dark-gray: 		#636363;
$primary-gray: 				#cccccc;
$primary-light-gray: 		#ededed;
$secondary-yellow: 			#ffb114;
$secondary-red: 			#c12820;
$secondary-orange: 			#f57211;
$secondary-bright-blue: 	#0563c5;
$secondary-blue-gray: 		#ccd7e0;
$secondary-light-yellow:	#efdbaf;


$fontcolor-brand-primary-bg   : #FFFFFF;
$brand-gray-lighter:			#EAEAEA;
$brand-gray-lightest:			#F3F3F3;