.payment-container{
	
	.policy-claim-status-closed{
		background-color	: $gray-light;
	}

 	.high-light-text {
 		color: #5cb85c;
 	}
 
	
	//If Min Down Payment Option has only One Option to pay in full
    .radio.payment-full{
        margin: 0 0 20px 0;
        label{
            color: $brand-complementary-1;
            font-weight: bold;
            padding: 0;
            //Hide the Radio button if there is only One Payment option is displayed
            input[type=radio]{
                display: none;
            }
        }
    }
    //Payment Information: Minimum or other Down Payment Amount
    .container-payment-information {
        .row{
            .payment-installment{
                margin-top: 10px;
                .label-down-payment{
                    position: absolute;
                    left: 225px;
                }
            }
            .form-group-other-amount{
                display: inline-table;
                vertical-align: super;
                .controls{
                    display: inline;
                    #payment-other-amount{
                        height: 34px;
                        padding: 0 10px;
                    }
                    ul.parsley-errors-list{
                        position: absolute;
                        top: 37px;
                        left: 0px;
                    }
                }
            }
        }
    }
    
    //Radio Button
    .container-radio-circle{
        margin: 15px 0;
        .radio-inline:nth-of-type(2){
            margin-left: 10px;
        }
        &.radio ul.parsley-errors-list{
            margin-left: 347px;
        }
    }
     
    //Credit/Debit Card or Bank Account Details
    .container-transaction-details{
        padding: 10px 30px;
        margin-left: 0px;
        border: 1px solid #ccc;
        background-color: #f2f2f2;
        .payment-info{
            display: block;
            &.paymentcard-image{
                float: left;
                margin-right: 10px;
                img{
                    height: 40px;
                }
            }
        }
    } 

}