$summary-card-margin-bottom: 15px;
$summary-card-body-padding: 20px 10px;
$summary-delete-icon-color: $hd-black;
$summary-entity-icon-color: $hd-black;
$summary-entity-font-size: $add-another-thing-button-font-size;
$summary-entity-min-width: 4.0rem;
$summary-entity-margin-right: 10px;
$summary-entity-text-align: center;
$summary-add-icon-color: $hd-black;
$summary-add-icon-min-width: 2rem;
$summary-add-icon-margin-right: 0;
$summary-add-entity-card-border-style: dashed;
$summary-h1-margin-bottom: 20px;
$summary-edit-entity-card-a-color: $hd-black;
$summary-edit-entity-card-delete-icon-font-size: 1.25rem;
$summary-edit-entity-card-body-padding-right: 40px;
$summary-card-body-background: transparent;
$summary-card-body-border: none;