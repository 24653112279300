/**
  * Harley Davidson Additional Info Overrides
  */

@import "../variables/uwadditionalinfo.variables.scss";

.uwadditionalinfo-container {

    .card-selection-mark {
        display: none;
        overflow: hidden;
        height: 0;
        width: 0;
    }
    
    .additionalinfo-vehicle-container {
        margin-bottom: $uwaddinfo-vehicle-container-margin-bottom;
    }

	.additionalinfo-vehicle-container,
	.additionalinfo-driver-container {
        border: $uwaddinfo-entity-container-border;
        padding: $uwaddinfo-entity-container-padding;
    }

    .tab-buttons {
        border-bottom: $uwaddinfo-tab-buttons-border-bottom;
        padding-bottom: $uwaddinfo-tab-buttons-padding-bottom;
        margin-bottom: $uwaddinfo-tab-buttons-margin-bottom;

        .entity-card {
            border: $uwaddinfo-entity-card-border;

            .summary-card {
                font-weight: bold;
                border-radius: 0;
                border-color: $summary-edit-entity-card-border-color;
                box-shadow: $summary-edit-entity-card-box-shadow;

                &.active {
                    border: $uwaddinfo-summary-card-active-border;
                    box-shadow: $uwaddinfo-summary-card-active-box-shadow;

                    .label-complete,
                    .label-incomplete {
                        color: $uwaddinfo-summary-card-active-color;
                    }
                }
            }
        }
    }
}
