//
// Quote Overrides
//---------------------------


#quote-sidebar {
	@include media-breakpoint-up(lg) {
		margin-top: -66px;
	}
}

.affix-quote-number {
	text-align: center;
  font-family: $font-family-hdcondensed-arialnarrow;
  margin-bottom: 35px;
}

body.quote {
	.customer-quote-content {
		.container-affix {
			h4.affix-quote-summary {
				font-family: 		$font-family-serif;
				text-transform: 	uppercase;
				color: 				$hd-white;
				text-align: 		left;
				margin-bottom: 18px;
			}
		}
	}
}

body.quote {
	.quote-affix {
		.quote-sidebar {

			h4.affix-quote-number {
				@include media-breakpoint-down(md) {
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
			.sidebar-item {

				margin-top: 12px;

				&.sidebar-active {
					background-color: #383838;
				}

				.quote-affix-purchase-summary-desc {
					h5 {
						color: $hd-white;
					}
					.quote-affix-numbers {
						padding: 0;
					}
				}

				h5 {
					padding-bottom: 4px;
				}

				ul {
					&.sidebar-entry-list {
						margin-left: 0;
						li {
							// body copy 2
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.quote-header-affix {
			.quote-headbar {

				background-color: #000;

				.headbar-item {
					font-family: $font-family-hdcondensed-arialnarrow;
					color: $hd-white;
					&.sidebar-active {
						background-color: #383838;
						border-radius: 0;
					}
					h5 {
						font-size: 13px;
					}
				}
			}
		}
	}
}

body.quote {

	.row-form-header .btn-show-summary {
		width: auto;
	}

	//
	// Quote Affix Sidebar for Desktop
	//-----------------------
	.quote-affix {

		//
		// Quote Sidebar
		//-----------------------
		.quote-sidebar {

			//
			// Post Bind Print Your Documents
			//---------------------------------
			button.form-link {
				text-decoration: none;

				&:before {
			    font-family: "HDFontsIQ";
			    content: "\e92d";
			    font-size: 25px;
			    margin-right: 6px;
				}
			}
		}
	}

	//
	// Quote Affix Sidebar for Desktop
	//-----------------------
	.quote-header-affix {

		//
		// Quote Sidebar
		//-----------------------
		.quote-headbar {

			//
			// Sidebar list item
			//-----------------------
			.headbar-item {

				border-bottom: none; //3px solid $hd-medium-grey;
				height: 50px;
				vertical-align: top;

				> .edit-step-form, .details-container {
					position: relative;
					top: -12px;
				}
				&.sidebar-active {

					> .edit-step-form, .details-container {
						top: -15px;
					}

					&:before {
						// triangle
						content: " ";
						position: absolute;
						left:50%;
				    width: 20px;
				    height: 0;
				    border-bottom: 8px solid $hd-burnt-orange;
				    border-right: 10px solid transparent;
				    border-left: 10px solid transparent;
				    bottom: 0;
				    margin-left: -10px;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.label-circle {
		.fa-stack-1x {
			&:before {
				font-size: 12px;
			}
		}
		i.fa-stack-2x {
			&:before {
				font-size: 21px;
				position: absolute;
				top: 7px;
				left: 0;
				right: 0;
			}
		}
	}
}
.quote-affix-numbers.text-center {
	height: 27px;
}
body.quote {
	.quote-header-affix {
		.quote-headbar {
			.headbar-item {
				min-height: 	50px;
				&.POLICYINFO, &.VEHICLE, &.DRIVER, &.DRIVERASSIGNMENT, &.UWADDITIONALINFO, &.UNDERWRITING, &.COVERAGES, &.ACCOUNTCLEARANCE, &.CONFIRMCOVERAGES, &.FINALSALE, &.ESIGNATURE {
					.quote-affix-numbers {
						span.label-circle {
							strong {
								font-size: 12px;
								line-height: 36px;
							}
						}
					}
				}
			}
		}
	}
}
body.quote {
	.quote-header-affix {
		.header-title {
  		font-family: $font-family-hdcondensed-arialnarrow;
		}
		.sidebar-active {
			.header-title {
				font-family: $font-family-hdboldcond-arialnarrow;
			}
		}
	}
}
body.quote {
	.quote-header-affix {
		.quote-headbar {
			.headbar-item {
				.quote-affix-numbers {
					span.label-circle-complete {
						color:$hd-burnt-orange;
					}
				}
			}
		}
	}
}
body.quote {
	.quote-header-affix {
		.quote-headbar {
			.headbar-item {
				min-height: 	50px;
				&.sidebar-active {
					&.POLICYINFO, &.VEHICLE, &.DRIVER, &.DRIVERASSIGNMENT, &.UWADDITIONALINFO, &.UNDERWRITING, &.COVERAGES, &.ACCOUNTCLEARANCE, &.CONFIRMCOVERAGES, &.FINALSALE, &.ESIGNATURE {
						.quote-affix-numbers {
							span.label-circle {
								strong {
									color: #FFFFFF;
									font-size: 15px;
									line-height: 34px;
									font-family: $font-family-hdboldcond-arialnarrow;
								}
							}
						}
					}
				}
			}
		}
	}
}

body.quote.harleydavidson {

	#brand-VEHICLE,
	#brand-DRIVER,
	#brand-DRIVERASSIGNMENT,
	#brand-UNDERWRITING,
	#brand-COVERAGES.brand-header-title {
		display : none;
	}

	#brand-POLICYINFO.brand-header-title.active {
		display : block;
	}

	#all-POLICYINFO.header-title.active {
		display : none;
	}

	#brand-POLICYINFO.brand-header-title.complete {
		display : none;
	}

	#all-POLICYINFO.header-title.complete {
		display : block;
	}
}
