/**
  * Harley Davidson Buttons Overrides
  */
@import "../variables/buttons.variables.scss";

.btn {
	text-transform: uppercase;
	font-family: $font-family-hdboldcond-arialnarrow;
}

.btn-lg {
	padding: 6px 16px;
	font-size: 17px;
	font-weight: normal;
	border-radius: 5px;
}

.btn-link {
	color: $hd-burnt-orange;
	font-weight: bold;

	&:hover, &:active {
		color: darken($hd-burnt-orange, 20%);
		text-decoration: underline;
	}
}

.btn-primary, .btn-action, .calculate-coverage-button {
	@include button-variant($primary-button-bg,$primary-button-border);
	box-shadow: 0px;
	border-radius: 0px;
	border: 0;
	font-family: $font-family-hdboldcond-arialnarrow;
	font-size: 24px;

	&:hover {
		background: $primary-button-hover;
	}
}

.btn-danger {
	box-shadow: 0px 2px 2px #000;
}

.btn-success {
	@include button-variant($success-button-bg,$success-button-border);
	box-shadow: 0px 2px 2px #000;
}

.btn-action, .calculate-coverage-button {
	padding: 15px 25px;

	&.btn-disabled {
		border-color: 	#636363;
		color: 			#636363;
		&:hover {
			background-color: 	$btn-action-bg;
			border-color: 		#636363;
			color: 				#636363;
		}
	}
}
.btn-action.btn-paperless-modal-submit {
	font-size: 18px;
}

.btn-payment {
	background: $payment-button-background;
	border: $payment-button-border;
	color: $payment-button-color;
	padding-top: $payment-button-padding-top;
    padding-bottom: $payment-button-padding-bottom;
	text-transform: $payment-button-text-transform;
	font-size: $payment-button-font-size;
    border-radius: $payment-button-border-radius;
    font-family: $payment-button-font-family;
    font-weight: $payment-button-font-weight;
    text-transform: $payment-button-text-transform;
	
	&:hover, &:active {
		border: $payment-button-border;
		color: $payment-button-color;
	}

	i {	

		&.icon-circleplus {
			font-size: $button-payment-icon-font-size;
			display: $button-payment-icon-display;
            margin-right: $button-payment-icon-margin-right;
		}
	}
}

button.save-and-exit {
	padding-top: 15px;
	text-transform: none;
}

.add-entity-btn {

	&.addincident {
		color: $hd-burnt-orange;

		&:hover {
			color: darken($hd-burnt-orange, 20%);
		}
	}
}