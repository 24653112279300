/**
  * Harley Davidson Footer Overrides
  */

body.quote {
    .bind-phone-number {
        display: none;
    }
	.quote-bottom-links {
		hr {
			margin-bottom: 22px;
			border: 1px solid #979797;
			border-bottom: none;
		}
	}
}

body.post-bind {
    .quote-phone-number {
        display: none;
    }
}

#footer {

    .footer-save-and-exit-container,
    .footer-disclaimer-container {
        background: $hd-hard-grey;
    }
    font-family: $font-family-hdcondensed-arialnarrow;

	.btn-link {
		color: #FFFFFF;
		border-radius: 0;
	}
	.bg-primary {
		a {
			color: #FFFFFF;
		}
	}

    // allowlist the pages where save and exit should be presented to the user
    .save-and-exit {
        display: none;
    }
	
	.sentry-copyright {
	    text-align: left;
	    p {
	        font-size: 12px;
	    }
	}
	
	ul li {
		@extend .typography-footer-text;
	
		a {
		    text-decoration: none;
		}
	}
}