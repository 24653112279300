//
// Ajax
// --------------------------------------------------

.ui-loader{
	border: 0 none;
	background: $ajax-loading-background;
    height: $ajax-loading-height;
    width: $ajax-loading-width;
    left: 50%;
    margin-left: -($ajax-loading-width/2);
	margin-top: -($ajax-loading-height/2);
    position: fixed;
    top: 50%;
	z-index: 9999999;


	@include media-breakpoint-down(sm) {
		width: $ajax-loading-width-xs;
		margin-left: -($ajax-loading-width-xs/2);
	}

    .ui-icon{
		background-color: transparent;
    	display: block;
    	height: $ajax-loading-height;
    	margin: 0 auto;
    	padding: 1px;
    	width: $ajax-loading-height;
    	opacity: $ajax-loading-icon-opacity;
		border-radius:($ajax-loading-width/2);
    }
}

.ui-icon-loading {
    background: $ajax-loading-icon-image;
}
.ui-loader-transparency{
	&:before {
		content: " ";
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		height: 100%;
		width: 100%;
		background: rgba(99, 99, 99, 0.8);
	}
}
.ui-loader-text {
    width: $ajax-loading-width;
    text-align:center;
    margin-top: 20px;
    color: $ui-loader-saving-indicator-color;

    @include media-breakpoint-down(sm) {
		width: $ajax-loading-width-xs;
	}
}
