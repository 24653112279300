//EFT Enrollment Container
.eft-container{
    .container-payment-type{
	    margin-bottom: 20px;
	}
	.container-transaction-details{
	    .payment-info{
	        display: block;
	    } 
	    .paymentcard-image{
	        float: left;
	        margin-right: 10px;
	        img{
                    height: 40px;
                }
	    } 
	}
}
