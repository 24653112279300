//
// Navigation
// --------------------------------------------------

// navbar contains top navigation and top search (by name, policy, claim number...)
.navbar {

	@include media-breakpoint-down(md) {
	
		max-height: $navbar-max-height-mobile;
		transition: $navbar-transition;
	
		/* Unable to extend BS4 .fixed-top because you may not @extend 
		an outer selector from within @media. You may only @extend 
		selectors within the same directive. */
		background-color: $navbar-bg;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: $zindex-fixed;
	}
	
	/* sometimes in order to "visually" vertical align things in the middle, we need slightly different padding on bottom */
	padding-bottom: $navbar-padding-bottom;
	@include media-breakpoint-up(md) {
		padding-top: $navbar-padding-top-md;
		padding-bottom: $navbar-padding-bottom-md;
	}
	
	&.navbar-default {
			
		// menu options on screen-xs
		li.visible-xs {
			> ul {
				padding		: 0px;
				list-style  : none;
				text-align	: center;
			}
		}
	}
	
	// just top navigation container	
	.navbar-main {
	
		width: 100%;
		
		.navbar-main-content{
			
			a {
			
				&.btn-pl-phone {
					color : $navigation-link-color;

					// So the phone number can be displayed on mobile
					@include media-breakpoint-down(sm) {
						font-size: $contact-us-button-font-size;
						padding: $contact-us-button-padding-xs;
					}	
				}
			}
			
			div {
			
				&.referral-agency {
					
					div {
					
						&.bind-phone-number {
						
							display: none;
						}
					}
				}
			}
		}
	}
	
	// unordered list container for the navigation list items
	.navbar-nav {
		width: 100%;
		
		.fa-stack{
			margin: 0 auto;
			@include media-breakpoint-up(md) {
				display: block;
			}			
		}
		
		// Navigation Items (Quote, Billing, Policies, Claims, Reports)
		> li {
				
			.nav-label {
				text-transform: uppercase;
				font-size: 1.65em;
				vertical-align: middle
			}
			
			@include media-breakpoint-up(md){
				width: 14%;
				
				.nav-label {
					font-size:1em;
				}
			
				&:not(:first-child) {
					.nav-label {
						display: block;
						border-left: 1px solid $brand-primary;
					}
				}
			}
		
			&.visible-xs {
				// dropdown menu button for small screen
				button {
					width	: 100%;
				}
				// menu nav items for small screen			
				li {
					font-size		: 16px;
					padding-bottom	: 0;					
					
					> a {
						width           : 100%;
						height          : 100%;
						display         : block;
						color           : #fff;
						border-bottom   : 1px solid #ccc;
						padding         : 8px 0;
				
						&:hover,
						&:focus {
							color               : $navbar-default-link-color;
							text-decoration     : none;
						}
					}
				}
			}
			
			&.active {
				>a {
					i {
						//background-color: $navbar-default-link-active-color;
					}				
				}
			}
				
			> a {
			
				padding:		2px;
				text-align: 	left;
				width: 			100%;
				
				@include media-breakpoint-up(md) {
					padding: 10px 0 0 0;
					text-align: center;
				}
				
				@include media-breakpoint-up(lg) {
					padding: 0px;				
				}
				
				&:hover {
					i {
						//background-color: $navbar-default-link-hover-color;
					}
				}
				
				i {
					&.fa-stack-1x {
						color: $fontcolor-brand-primary-bg;
					}
				}
				
				> img {
				
					&.img-responsive {
						max-width: 100%;
					}
				}
			}
		}
	}	
}

body {
	
	padding-top: $navbar-max-height-mobile;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
	}
	
	&.post-bind {
	
		.navbar {
			
			// just top navigation container	
			.navbar-main {
				
				.navbar-main-content{
					
					div {
					
						&.referral-agency {
							
							div {
							
								&.quote-phone-number {
								
									display: none;
								}
							
								&.bind-phone-number {
								
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}

#topnav-links-column {
	margin-top: 10px;
	
	@include media-breakpoint-up(md){
		margin-top: 0px;
	}
}

.navbar-contact-column {				
	transition: $navbar-transition;
}

.navbar-logo-column {
	margin-top: 0;
	transition: $navbar-transition;
	@include media-breakpoint-up(md){
		display: block;
	}
	@include media-breakpoint-up(lg){
		margin-top: 14px;
	}
}

.environment {
	position: absolute;
	font-weight: 700;
	z-index: 1000;
	right: 0;
	top: 20px;
	right: 44%;
	display: none;
	
	@include media-breakpoint-up(md) {
		display: block;
		right: 46%;
	}
	@include media-breakpoint-up(lg) {
		right: 45%;
	}
	@include media-breakpoint-up(xl){
		right: 40%;
		width: 240px;
	}
	
	&.PROD {
	
		display:none;
	}

	.stability-smarts {

		.card {
			border: 2px solid black;

			&.degraded {
				border-color: orange;

				.card-header {
					background: orange;
	
					button {
						color: #fff;
					}
				}
			}

			&.down {
				border-color: red;

				.card-header {
					background: red;

					button {
						color: #fff;
					}
				}
			}

			&.up {

				border-color: green;

				.card-header {
					background: green;

					button {
						color: #fff;
					}
				}
			}

			.card-header {
				padding: 0;
				background: #fff;

				h5 {
					margin-bottom: 0;
				}

				button {
					padding: 15px;
					text-align: left;
					width: 100%;

					.stability-name {
						display: none;

						@include media-breakpoint-up(lg) {
							display: inline-block;
						}
					}
				}
			}

			.card-body {

				padding: 0;

				.list-group-item {

					text-align: left;
					background: #fff;

					.fa-circle {
						color: green;
					}

					&.system-degraded {
						.fa-circle {
							color: orange;
						}
					}
	
					&.system-down {
	
						.fa-circle {
							color: red;
						}
					}
				}
			}

		}
	}
}

.navbar-contact-column {
	flex-direction: $navbar-contact-column-flex-direction;
}

.referral-agency {
	margin-left: $referral-agency-margin-left;
	font-size: $referral-agency-font-size;
}
