body.quote {

    .customer-quote-content .quote-container .confirm-coverages .coverage-form {

        #coverage-form-header {
            text-align: left;
            
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .row-form-header {

            .coverage-form-header-headline-container {
                max-width: 75%;

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }
            }
        }
    }

    .customer-quote-content .quote-container .coverage-form {

        sup.pennies {
            font-size: 1rem;
            top: -2rem;
        }

        #reset-coverage-link {
            display: block;
            text-align: center;
        }

        #coverage-form-header {
            text-align: center;
            color: $hd-hard-grey;
        }

        .pros-cons-disclaimer {
            font-style: italic;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .btn-edit-coverages {
            display: block;
            margin: 0 auto 10px;
        }

        .offering-static-purchase-container {
            display: none;
        }

        .btn-show-summary {
            display: none;
        }

        .down-payment {
            text-align: center;	
            font-size: .75rem;
        }

        .offering-button {

            &.active {
                color: white;
            }

            .offering-label {
                @include make-col(12);
                padding-right: 0;
                padding-left: 0;
            }

            small {
                display: block;
                width: 100%;
                font-style: italic;
            }
        }

        .offering-recommended-callout {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .offering-recommended-callout-text {
            position: absolute;
            top: 6px;
            left: 20px;
            font-size: .85rem;
            font-style: italic;
        }

        .offering-recommended-callout-arrow {
            position: absolute;
            top: 0px;
            left: 5px;
        }

        .down-today-content {
            margin: 0 15px;
            padding-top: 15px;
            border-radius: 5px;
            box-shadow: 0px 0px 4px 0px $btn-radio-active-box-shadow;
        }

        .details-alldue-label {
            font-style: italic;
            text-align: center;
        }

        .details-installments-label {
            font-style: italic;
            text-align: center;
        }

        .details-totaldue-label {
            font-style: italic;
            text-align: center;
        }

        .recalculated-down-today-content {
            width: 100%;
        }

        .coverage-recalculate-rate-message {

            h1 {
                font-size: 1.25rem;
            }
        }

        .quote-bottom-links {

            hr {
                display: none;
            }

            .continue-billplan-purchase-container {
                display: flex;
            }

            .calculate-coverage-button {
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }

        .offering-pros-cons-container {

            ul {
                margin-bottom: 0;
            }
            
            li {
                padding-bottom: 10px;
                display: flex;

                &.offering-pro {
                    color: $hd-hard-grey;
                    
                }

                &.offering-con {
                    color: $gray-600;
                }
            }
        }

        .offering-pros-cons-container {
            
            .fa {
                font-size: 1.25rem;
                margin-right: 8px;
            }
        }

        .row-form-header {

            .coverage-form-header-headline-container {
                @include make-col(12);    
            }
        }

        .coverage-billplan-container,
        .coverage-billplan-static-container {

            h3, .h3 {
                display: block;
                text-align: center;
                
                .down-today-amount-value {
                    font-size: 4rem;
                    display: block;
                    margin-top: 0;
                }

                small {
                    font-size: 1rem;
                    display: block;
                    margin-bottom: 15px;
                    color: $hd-hard-grey;
                }
            }
            hr {	
                border-color: $primary-light-gray
            }
        }

        .summary-cards-group {
            @include make-row();
            margin-bottom: 35px;

            .nav-item {
                @include make-col-ready();
                @include make-col(4);
                display: flex;
                align-items: stretch;

                &:not(:last-child) {
                    padding-right: 0;
                    margin-right: 0;
                }

                &:not(:first-child) {
                    padding-left: 0;
                    margin-left: 0;
                }

                &:first-child {

                    .summary-card {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        
                    }
                }

                &:last-child {

                    .summary-card {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        .summary-card {
            width: 100%;
            border: none;
            box-shadow: 0px 0px 4px 0px $btn-radio-active-box-shadow;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            margin-top: 7px;	
            margin-bottom: 7px;
            border-radius: 0px;

            &:before {
                border: none;
            }

            label {
                .fa-check-circle {
                    display: none;
                }
            }

            &.active {
                border: none;
                background: $hd-hard-grey;
                box-shadow: 0px 0px 4px 0px $btn-radio-active-box-shadow;
                border-top-right-radius: 5px;	
                border-bottom-right-radius: 5px;	
                border-top-left-radius: 5px;	
                border-bottom-left-radius: 5px;	
                margin-top: 0px;	
                margin-bottom:0px;
                
                .tab-headline-not-customized {
                    font-size: 1.15rem;
                }

                label {
                    .fa-check-circle {
                        display: block;
                        padding: 3px;
                        font-size: 1.5rem;
                    }
                }
            }

            label.customized {
                font-size: 1.15rem;
            }

            .tab-headline-not-customized {
                text-transform: none;
                text-align: center;
                font-size: 1.15rem;

                small {
                    font-size: .75rem;
                    font-weight: bold;
                    font-family: $font-family-regular;
                }
            }
        }
    }
}

.quote-step-coverage, .quote-step-confirmcoverages {
    
    .footer-disclaimer-container {
        margin-bottom: 135px;
    }
}