// The styles in this file should not be copied over if/when the experiment
// code replaces the default code. These styles are hacky overrides in order
// to minimize the number of handlebars templates that need to be overriden


// to fix this: remove ".container-fluid" immediate descendants of "#quote-content"
#quote-content > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}


// to fix this: remove "container-fluid" class from ".post-content-buttons" elements
form > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}


// to fix this: remove ".container-fluid" immediate descendants of ".*-form" elements
[class*="-form"] > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}


// to fix this: remove ".container-fluid" immediate descendants of ".additionalinfo-*" elements
[class*="additionalinfo-"] > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

// to fix this: in partial.zipinput.handlebars add "row" to form-group
.zip-input > .form-group {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

// the summary buttons should be deleted from the handlebar templates if IdealQuote is merged into main codebase
.btn-show-summary {
    display: none;
}