/**
  * Harley Davidson Final Sale Page Overrides
  */
@import "../variables/finalsale.variables.scss";

body.quote .customer-quote-content .finalsale-container {

    #AutomaticPaymentDraftDate {
        border-radius: $finalsale-draftdate-border-radius;
    }

    .premium-and-downpayment-labels {
		text-align: left;
	}

    #pay-today {
        margin: $finalsale-paytoday-margin;
        padding: $finalsale-paytoday-padding;
        height: $finalsale-paytoday-height;
    }

    .disclaimer {
        @extend %disclaimer-text;

        padding-bottom: $finalsale-disclaimer-padding-bottom;
    }

    .disclaimer-container {

        .h4 {
            @extend %disclaimer-text;
        }
    }

    form:not(.auto-payment-added) {

        .auto-new-payment {
            display: $finalsale-new-payment-display;
            align-items: $finalsale-new-payment-align-items;
            justify-content: $finalsale-new-payment-justify-content;
            width: $finalsale-new-payment-justify-content;
        }
    }

    form:not(.first-payment-added) {

        .first-new-payment {
            display: $finalsale-new-payment-display;
            align-items: $finalsale-new-payment-align-items;
            justify-content: $finalsale-new-payment-justify-content;
            width: $finalsale-new-payment-width;
        }
    }

    .show-less,
    .show-more {

        i {
            font-size: $finalsale-show-moreless-font-size;
        }
    }

    input[name="eft-first-payment-different-method"] {
        margin-top: $finalsale-different-autopay-method-margin-top;
        margin-left: $finalsale-different-autopay-method-margin-left;
    }

    .AutomaticPaymentDraftDate-container .tooltip-and-required {
        top: $finalsale-draftdate-tooltip-top;
        left: $finalsale-draftdate-tooltip-left;
        right: $finalsale-draftdate-tooltip-right;
        color: $hd-burnt-orange;
    }

    .container-iframe {
        height: $container-iframe-height;
    }

    .top-of-form-divide {
        border: $finalsale-top-of-form-divide-border;
    }

    .summary-head {
        background: $finalsale-summary-head-background;
        padding: $finalsale-summary-head-padding;
    }

    .fancy-options-list-group {

        .fancy-options-list-group-item {

            .h4:last-child:not(:first-child) {
                margin-bottom: 0;
            }

            .term-best-price .fa.fa-circle-thin {
                display: block;
            }

            &:before {
                content: " ";
                background-clip: padding-box;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: $finalsale-fancyoptions-border;
            }

            @include box-shadow(none);

            &.active {

                .h4, .h5 {
                    color: $finalsale-fancyoptions-active-headline-color;
                }

                &:before {
                    border: $finalsale-fancyoptions-active-border;
                }
            }
        }
    }
    .active {

        .active-indicator {
            color: $finalsale-fancyoptions-active-checkbox-color;
            box-shadow: $finalsale-fancyoptions-active-checkbox-box-shadow;

            &:before {
                font-size: $finalsale-fancyoptions-active-checkbox-font-size;
            }
        }
        .inputs {

            .fa {
                color: $finalsale-fancyoptions-active-checkbox-color;

                &.icon-checkmark {
                    // use the font-size specific in .active-indicator:before, don't change these
                    font-size: 1em;
                    height: 1em;
                    width: 1em;
                }
            }
        }
    }
}

.final-sale-section {

    &.groupPaymentOptionSelection,
	&.groupSinglePaymentMethod,
    &.policyholderESignGroup,
    &.groupAgreeToPaymentTerms,
    &.groupRecurringPaymentMethod {
        margin-top: $finalsale-group-margin-top;
        width: 100%;
    }

    &.policyholderESignGroup {
        border-bottom: $finalsale-group-policyholderesigngroup-border-bottom;
	}
}
