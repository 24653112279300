//Order Reports
.orderreports-container, .autoloss-container, .mvr-container {

	min-height: 620px;

	.orderreports-form{
	
		.order-reports-warning-icon {
			color: $primary;
		}
	
	    h4 {
	        margin: 0;
	    }
	
		/* name that is placed on top of incident */
		.violation-driver-subhead {
			font-size: 		$tooltip-header-fontsize;
			font-weight: 	$tooltip-header-weight;
		}
		
		.incident-details {
		
			/* description of the incident for example speeding, vehicular manslaughter*/
			.incident-label {
				font-weight:	$formquestion-copy-weight;
				font-size:		$formquestion-copy-fontsize;
				color:			$formquestion-copy-color;
			}
			
			/* date of the incident occurance mm/dd/yyyy */
			.incident-date {
				font-weight:	$formheader-weight;
				font-size:		$formheader-fontsize;
				color:			$formheader-color;				
			}
		}
		

		.row-header {
			padding: 10px 0;
		}

		h5 {
			font-weight:bold;
		}

	    .order-report-wrapper{
	    	padding: 10px 15px;
	    	.controls {
	    		padding-top		:	10px;
	    	}
	    	.radio {
	    		padding-top: 0px;
	    		min-height: 0px;
	    	}
	    }

		.additional-header{
			padding-left		:	0px;
			position			:	relative;
		}

		/* in lieu of <hr> blocks to separate sections we will use a top border, this will
		also prevent visual conflict with the quote content footer (exit/continue buttons) */
		.additional-info {
			margin-bottom		: 14px;
			border-top			: 1px solid $brand-primary;
		}

		.span-block-xs{
		    @include media-breakpoint-down(md){
		        display			: block;
		        margin			: calc(#{$h5-font-size} * #{$headings-line-height}) 0 0 0;
		    }
		}

		.remove-incident-row{
			border				: 1px solid $brand-danger;
			border-radius		: 10px;
		}

		.row-mvr-mismatch-info{
			border-bottom		: 1px solid $brand-gray-light;
		}

		a.tooltips-link{
			text-decoration		: underline;
			position	: relative;
		}
		
		a.tooltips-link {
		
			&:hover .tooltiptext{
				visibility: visible;
			}
			
			.tooltiptext {
				visibility: hidden;
			    position: absolute;
			    width: 276px;		    
			    top: 30px;
			    left: -100px; 
			    background-color: $secondary-blue-gray;
			    color: $primary-dark-blue;
			    text-align: center;
			    padding: 8px;
			    border: 1px solid $primary-dark-blue;	    
			    z-index: 1;
	    		@include box-shadow(0 1px 10px 1px rgba(0,0,0,0.4));
	    		
				&:before {
				   content: "";
				   position: absolute;
			       bottom: 100%;
				   left: 128px;
				   border-width: 12px;
				   border-style: solid;
				   border-color: transparent transparent $primary-dark-blue transparent;
				}
				
				&:after {
				   content: "";
				   position: absolute;
			       bottom: 100%;
				   left: 130px;
				   border-width: 10px;
				   border-style: solid;
				   border-color: transparent transparent $secondary-blue-gray transparent;				
				}	
			}
		}	
		
		.tooltip-and-required{
			&.tooltip-header{
				position		: relative;
				top				: inherit;
				right			: inherit;
			}
		}
		.mvr-mismatch-error{
			margin-top			: -10px;
			margin-left			: -25px;
		}
	}
}