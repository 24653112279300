.template-inactive-radio-button-before {

    &:before {
        font-family: "FontAwesome";
        content: "\f1db";
        color: $primary;
        opacity: .5;
        font-weight: normal;
    }
}

.template-active-radio-button {
    border-color: $secondary;

    &:before {
        font-family: "HDFontsIQ";
        content: "\e92c";
        font-size: 1.05rem;
    }
}

.btn-group-toggle {
    label {

        &:not(.active) {
            @extend .template-inactive-radio-button-before;
        }

        &.active {
            @extend .template-active-radio-button
        }
    }
}

.form-group:focus-within label {
    color: $secondary;
}

.col-form-label {
    padding-bottom: .5rem;
    padding-top: 0;
}

.btn-group-toggle {
    &.stacked-toggles {
        label:not(:first-child) {
            margin-top: 1rem;
        }
    }
    label {
        text-align: left;
        padding-left: .5rem;
        border: 2px solid transparent;
        font-weight: normal;
        white-space: normal;
        padding-left: 1.8rem;
        display: flex;
        align-items: center;

        &:before {
            margin-right: .25rem;
            position: absolute;
            left: .5rem;
        }

        &:not(.active):before {
            font-family: "FontAwesome";
            content: "\f1db";
            color: $primary;
            font-weight: normal;
        }

        &.active {
            border-color: $secondary;
            &:before {
                font-family: "HDFontsIQ";
                content: "\e926";
                font-size: 1.05rem;
            }
        }
    }
}

.additionalinfo-policyinfo-container {
    .label-tooltip {
        display:none;
    }
}

.add-entity:hover {
    text-decoration: none;
}
