//
// Quote Wizard - Driver Step
//-------------------------------

//
// Driver Summary
//-------------------------------
.driver-summary {
	
	
	
	h4 {
		//
		span.vin {
			margin-left	: 2em;
		}
	}
	ul.driver-details {
	
		padding-left	: 0;
		li {
			list-style	: none;
			
			@include media-breakpoint-down(md) {
				div {
					border-bottom: 1px solid #eee;
				}
			}
		}
	}
	
	.driver {
		padding-bottom	: 14px;
		border-bottom	: 1px solid #EEE;		
	}
}

.driver-form, .reconcile-form{
		
	/* incident labels are lengthy and are cut off on small screen so we will apply this to wrap the copy */
	.Incident {
		select {
			white-space: pre-wrap;
			height: auto;			
		}
	}

	.group {
		
		&.license-information,
		&.fr-info-question,
		&.driver-discounts,
		&.incidents-info-question {
			margin-top	: 20px;
			//border-top	: 1px solid #eaeaea;
		}
	}
	
	//Add New Driver Button
    .btn-add-new-entity{
	    background-color: #fff;
	    color: $btn-add-new-entity-color; 
	}	
	
	//Add Accident/Violation Incident
	.row-btn-incident{
        margin-top: 10px;	
	}
	
	//Accident/Violation Incident Section
	.incidents-info{
	    padding: 5px;
	    padding-top: 20px;
	    border-bottom: $incidents-info-border;
	    //Stripes
	    //&:nth-child(even) {
            //background-color: $primary-light-gray;
        //}
	    
	    label{
	        text-align: left;
	    }
	}
	.tooltip-subheader.tooltip-and-required{
	    position				: static;
    	
    	a.tooltips-link{
    		text-decoration		: underline;
    	}
    	
    	// Overriding position and font-size attributes, because this is actually used on text instead of
    	// the ? icon for tooltips/popovers.
    	.page-tooltip {
    		position: relative;
    		font-size: 15px;
    	}
	}
	
	//for the license state dropdown we don't want to default the dropdown to the empty selection value
	.LicenseState {
	
		.select-empty-value {	
			color: $input-color;
			option {
				color: $input-color;
				&[value=""] {
					color: $empty-select-color;
				}
			}
		}
	}
}