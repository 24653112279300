/**
  * Harley Davidson Modal Overrides
  */
  
// Modal Overrides
.modal-content{
	border-radius: 0;
	border: 0;
	min-height: 300px;	
	a {
		color					: $modal-content-link-color;
		text-decoration			: underline;
		text-decoration-color	: $modal-content-link-color;
		font-weight				: bold;
	}

	.modal-body {
		background: $hd-white;

		.addresses-container {
			address {
				font-family: $font-family-sans-serif;
				color: $hd-orange;
				font-size: 19px;
				font-weight: 700;
			}

			.fancy-options-list-group-item.active:before {
				border: 4px solid $secondary-orange;
			}

			.icon-checkmark.active-indicator {
				color: $secondary-orange;
			}
		}
	}

	.modal-header{	
		padding: 0;
		background: $modal-header-bg-color;
		
		@include media-breakpoint-down(md) { 
		    h1 {
		        font-size: 18px;
		    }
		}
		
		//modal title
		.modal-title {
			color: $hd-hard-grey;
	        margin: 0;
			padding-left: 20px;
			padding-top: 15px;
			padding-bottom: 15px;

			.fa-check {
				color : green;
			}
		}
	
		//modal overrides
		.close {
		    opacity: 1;
		    margin: 	2px;
		    height: 	45px;
		    width: 		45px;
		    background: transparent;
			color: 		$hd-hard-grey;
            &:hover {
                color: $hd-hard-grey;
            }
		}
	}
}
