//When the Dropdown Menu is open
.open>.dropdown-toggle.btn-dropdown{
    color: #030303;
    border-color: #00080e;
}
// The dropdown menu (ul)
.portal-menu-dropdown.dropdown-menu {
  font-size: 1.25em;// $font-size-base;
  background-color: $brand-gray-lighter;
  border: 3px solid #363C44;
  top: 118%;
  padding : 0;

    // Dividers (basically an hr) within the dropdown
    .divider {
        @include nav-divider(#363C44);
    }
    // Links within the dropdown menu
    > li > a {
    	@include media-breakpoint-down(md) {    	
			border-left: 3px solid gray;
			margin-left: 25px;
    	}
	    @include media-breakpoint-up(md) {
	    	border-bottom : 1px solid #363C44;
	    }
    }
    
    @include media-breakpoint-up(md) {
	    &:before{
	        width: 0;
	        height: 0;
	        position: absolute;
	        border-left: 12px solid transparent;
	        border-right: 12px solid transparent;
	        border-bottom: 12px solid #363C44;
	        top: -12px;
	        left: 68%;
	        margin-left: -8px;
	        content: " ";
	    }
    }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    background-color: #FFFFFF;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: #4D4D4D;
    background-color: #363C44
  }
}
