//
// Quote Wizard - Underwriting Step
//-------------------------------

.underwriting-container {
	.dynamic-repeatable {
		.form-group{
			&.radio-buttons{
				.tooltip-and-required {
					left: 0;
					@include media-breakpoint-up(md){
						left: 460px;
						top: 25px;
					}
					@include media-breakpoint-up(lg){
						left: 450px;
					}
					@include media-breakpoint-up(xl){
						left: 530px;
					}
				}
			}
		}
	}

    /* the underwriting page can be short and we want to push the Save and Exit/Continue buttons down so they're
    not floating in the middle of the page; there isn't a straight-forward way to make that bit of content "stick"
    to the bottom of the page, so we will simply set a minimum height on it to accomodate */
    @include media-breakpoint-up(lg) {
        #quote-underwriting-layout-form {
            min-height: 500px;
        }
    }

    /* in agent portal transfer source is a radio selection, but in cdp it's a select so we need to make
    sure the entire option is legible on the screen */
    #TransferSource {
        white-space: pre-wrap;
        height: auto;
    }

    .discount-field {

        h3 {

            font-size: $discount-field-h3-font-size;
            font-family: $discount-field-h3-font-family;
            color: $discount-field-h3-color;
        }
    }

    .form-group {

        label.col-form-label {
            text-align:     left;
            font-weight:    normal;

            .required-indicator {
                display:    none;
            }
        }

        .radio {
            label {
                padding-bottom: 8px;
                line-height:    1.5;
            }
        }
    }
}
