.qualification-container {

	label.col-form-label {
		text-align:left;
	}

	//Quote Qualification Page
	.group-agency-information {

    	//Quote Qualification Buttons
    	.lob {

    		label {

    			//hide the product label
    			&.col-form-label {

    				display : none;
    			}

    			//an individual label is disabled
    			&.not-available {

    				//disabled
	                @include button-variant(#d0d2d2, #f3f3f3, #f3f3f3);

	                cursor: $cursor-disabled;
    				pointer-events: none; // Future-proof disabling of clicks
    			}
    		}

        	.btn-group {

        		width: 100%;

    	        label {

                	margin-right:	$grid-gutter-width;
                	margin-bottom:	$grid-gutter-width;
                	padding-top:	30px;
					padding-bottom: 30px;

					&.btn-qualification{
						border-radius: 8px;
        			}
    	        }
    	    }

    	    &[disabled] {

    	    	.btn-group {

	    	        label {

	                	//button group is disabled as a whole
	                	&.btn-qualification{

	                		//disabled
	                		@include button-variant(#d0d2d2, #f3f3f3, #f3f3f3);

	                		cursor: $cursor-disabled;
    						pointer-events: none; // Future-proof disabling of clicks
    	    			}
	    	        }
	    	    }
    	    }
    	}
	}
}
