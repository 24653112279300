$button-payment-icon-font-size: $add-another-thing-button-font-size;
$button-payment-icon-display: inline-block;
$button-payment-icon-margin-right: 4px;
$payment-button-background: transparent;
$payment-button-border: $card-border-width dashed $card-border-color;
$payment-button-color: $hd-hard-grey;
$payment-button-padding-top: 20px;
$payment-button-padding-bottom: 20px;
$payment-button-text-transform: none;
$payment-button-font-size: 1rem;
$payment-button-border-radius: 0;
$payment-button-font-family: $font-family-regular;
$payment-button-font-weight: 700;
$payment-button-text-transform: uppercase;
