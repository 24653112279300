//
// Quote Wizard - Vehicle Step
//-------------------------------
.vehicle-form,.reconcile-form{
    .label-or{
		width:50%;
        margin: 25px 0;
        text-align: center;
        font-weight: bolder;
        font-size: 1.2em;
    }
    .btn-add-new-entity{
	    background-color: #fff;
        color: $btn-add-new-entity-color; 	    
	}
	.text-error {
		display : none;
	}
	.select-error {
		display : block;
	}
	
	#VehicleModel[type="text"] + ul .text-error{
		display : block;
	}
	
	#VehicleModel[type="text"] + ul .select-error{
		display : none;
	}
	
	//for the owned, financies, leeased dropdown we don't want to default the dropdown to the empty selection value
	.InterestType {
	
		.select-empty-value {	
			color: $input-color;
			option {
				color: $input-color;
				&[value=""] {
					color: $input-color;
				}
			}
		}
	}
}