.quote-authorization-content {
	
	/* labels for the radio buttons, "By personal info" and "By quote number" */
	.form-control {				
		border: 0;
		@include box-shadow(none);
	}
	
	/* radio inputs for how the user wants to retrieve */
	.input-group-addon {					
		background: transparent;
	}
}

/* retrieve quote info collection modals */
.retrieve-modal {
		
	/* radio inputs for how the user wants to retrieve */
	.input-group-addon {
		
		label {
		
			width: 115px;
			text-align: right;
			margin-bottom: 15px;
		}
	}	
}