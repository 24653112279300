//
// Ajax
// --------------------------------------------------

//---------------------------------------
// Animations
//---------------------------------------
@keyframes spin {0%{ transform: rotate(0deg); } 100% { transform: rotate(360deg); } };

.ui-icon-loading {
    position: relative;
	border: $spinner-border;
	border-radius: $spinner-border-radius;
	border-top: $spinner-border-top;
	width: $ajax-loading-width;
  	height: $ajax-loading-height;
  	animation: $ajax-loading-animation;
  	background: none;
}

