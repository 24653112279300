$orderreports-tooltiptext-width: 96%;
$orderreports-tooltiptext-left: 2%;
$orderreports-tooltiptext-top: 75px;
$orderreports-tooltiptext-font-size: $popover-font-size;
$orderreports-tooltiptext-color: $secondary;
$orderreports-tooltiptext-background: white;
$orderreports-tooltiptext-border: none;
$orderreports-tooltiptext-box-shadow: $box-shadow-popover;

$orderreports-additionalinfo-border: none;

$orderreports-report-table-striped-background-color: #e5e5e5;

$orderreports-incidentdetails-label-color: $hd-hard-grey;