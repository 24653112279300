#quote-header-affix {
    color: $hd-white;
    font-weight: bold;
    text-align: center;
    background: $hd-medium-grey;

    #progress-bar-categories-container {
        text-transform: capitalize;
    }

    .active {
        color: $hd-white;
        background: $hd-burnt-orange;
    }

    .progress {
        height: 8px;
    }

    .progress-bar {
        background: $secondary;
    }

    .col {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    #progress-bar-steps-container {
        border-top: 1px solid $hd-white;
    }
}