/**
  * Harley Davidson Fancy Options Overrides
  */


//
// Final Sale Bill Plans Column Definitions
// -------------------------------
.fancy-options-column {
	&.fancy-options-column-1 {
		@include make-fancy-column(2, 2, 2);
	}
	&.fancy-options-column-2 {
		@include make-fancy-column(10, 10, 10);

		h3 {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}

.fancy-options-list-group {
	
	.fancy-flex-container {
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}

	.fancy-options-list-group-item {
		border-radius: none;
		text-transform: none;
        display: block;
		font-family: $font-family-serif;

		&.active {
			box-shadow: none;
		}

		.plan-installments-description {
			font-size: 12px;
		}
		.fa {
			&.fa-circle-thin {
				display: none;
			}
		}

		h3 {
			strong {
				padding-right: 5px;
			}
		}
	}
}

.payment-options-disclaimer-container {
	text-align: right;
}