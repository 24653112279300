@mixin parsley-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .col-form-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline  {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
    border-color: $border-color;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075); // Redeclare so transitions work

    &:focus {
      border-color: darken($border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
      @include box-shadow($shadow);
  }
}

input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  @include parsley-validation($state-success-text, $state-success-border, $state-success-bg);
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  @include parsley-validation($state-danger-text, $state-danger-border, $state-danger-bg);
}

.parsley-errors-list {

	padding-left	: 0;
	color 			: $state-danger-text;

	li {

		list-style	: none;
	}
}
.controls .parsley-errors-list {

	float:left;
}
.navbar .parsley-errors-list{
	position:absolute;
	top:34px;
	z-index:1;

	opacity: 0;

	transition: all .3s ease-in;

	&:after {
		border: solid transparent;
		border-color: rgba(68,68,68,0);
		border-bottom-color: $state-danger-bg;
		border-width: 8px;
		top: -16px;
		left: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		margin-left: -8px;
	}
}

.parsley-errors-list.filled {
	background:transparent;
	padding:10px;
	margin-top:5px;
	padding-top:0;
	padding-left:0;
}

//
// Warnings
// -------------------------------------------
.parsley-warning-list {

	padding-left	: 0;
	color 			: $state-warning-text;

	li {

		list-style	: none;
	}
}

.parsley-warning-list.filled {
	opacity: 1;
	border-radius: 5px;
	background:$state-warning-bg;
	padding:10px;
	margin-top:5px;
}
