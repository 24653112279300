body {

	&.post-bind {

		@include media-breakpoint-down(md) {

			#esig-iframe-container {
				overflow-x: scroll;
				-webkit-overflow-scrolling: touch;
			}

			#quote-container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	#customer-bind-container {

		#quote-affix {

			@include media-breakpoint-down(md) {

				position: absolute;
			    top: 0px;
			    width: 95%;
			    background: $quote-affix-xs-sm-background;
			    left: 0px;
			    margin-left: 15px;
			    min-height: 150px;
			    z-index: 2;
			}

			.btn-close-summary {
				cursor: pointer;
			    position: absolute;
			    right: 16px;
			    z-index: 10000000;
			}
		}

		.btn-show-summary {
			margin-top: 10px;
		}
	}

	.esign-loading-message {

	    width: 100%;
	    margin-top: 50px;
	    text-align: center;
	    margin-left: -50%;
	}

	@include media-breakpoint-down(lg) {


		#esigIframe {
			//this forces the iframe to be at least minwidth of 801px because docusign sucks and we have to force the size for them
			//min-width: 801px !important;
		}
	}

}

// Post bind
.preference-enrollment-checkbox {
    &::before,
    &::after {
      top: $preference-enrollment-checkbox-top;
      left: $preference-enrollment-checkbox-left;
    }
}

/**
 * Applies styles to the custom control label when the custom control input is disabled.
 */
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input.disabled ~ .custom-control-label {
  &::before {
    @include gradient-bg($custom-control-indicator-checked-bg);
    opacity: 0.5; // Adjust opacity to indicate disabled state
  }
  &::after {
    filter: grayscale(100%); // Apply grayscale filter to gray out the image
    opacity: 0.5; // Adjust opacity to indicate disabled state
  }
}

.preference-label {
	font-weight: bold;
	font-size: 14px !important;
}

.confirmation-steps .media-heading {
	font-size: 18px !important;
}