/**
  * Harley Davidson Tab Tiles Overrides
  */

// Add Entity Card
.post-content-buttons {
	.add-entity-submit {
		font-family: $font-family-hdboldcond-arialnarrow;
		font-size: 18px;
		@include media-breakpoint-up(md) {
		    font-size: 24px;
        }
		padding-top: 20px;
		padding-bottom: 20px;
		&:hover {
			color: $hd-burnt-orange;
			text-decoration: underline;
		}
	}
}

// Tabs Tiles Overrides
.quote-container {
	.entity-card {
		&.active {
			.summary-card {
				border: 0; //4px solid $hd-burnt-orange;
			}
		}
		.summary-card {

			@include media-breakpoint-down(md) {
				margin-bottom: 6px;
			}

			&.active {
				border: 0; //4px solid $hd-burnt-orange;
			}

			a, a:hover {
				text-decoration: none;
			}

			.card-info {
				font-weight: bolder;
				
				&.container-card-edit-delete {

					.btn-link {

						padding-top: 0;
						padding-bottom: 0;
						text-transform: none;

						span {
							text-decoration: underline;
						}
					}
				}
			}
			.card-selection-mark{
				.icon-checkmark {
					margin-bottom: -22px;
					font-family : 'HDFontsIQ';

					&:before {
						font-family : 'HDFontsIQ';
						font-size: 35px;
						content: "\e92b";
					}
				}
			}

			.label-complete,
			.label-incomplete,
			.info-sub-header,
			.info-header {
				margin: 0;
			}
		}
	}

	.card-selection-mark {
		font-size: 30px;
	}

	.tab-headline-not-customized {
		font-size: 24px;
	}
	.card-container {

		clear: both;
		overflow: hidden;
		margin-bottom: 15px;
		margin-left: 15px;
		border-bottom: 3px solid $hd-white;

		.entity-card {
			border: 0 transparent;
			.icon-circleplus {
				font-family : 'HDFontsIQ';
				display: block;
				font-size: 32px;

				&:before {
					font-family : 'HDFontsIQ';
					content: "\e939";
				}
			}
		}
		// Add Cards Button
	  //----------------
	  .summary-card{
	  	&.container-add-btn{
	    	background: transparent;

	    	.add-card {
		    	background: transparent;
	    		padding-top: 5px;
	    		padding-bottom: 5px;
					width: 100%;

					@include media-breakpoint-up(md) {
						min-width: 90%;
					}

	    		@include media-breakpoint-up(md) {
	    			border: 2px dashed $hd-burnt-orange;
	    		}
	    	}
	    }
	  }
	  @include media-breakpoint-up(md) {
    	.summary-card {
      	margin-bottom: 0;
        .info-sub-header, .info-header {
         	font-weight: bold;
        }
        .info-header {
         	font-size: 15px;
        }
        .container-status-completion{
         	font-size: 13px;
        }
      }
	  }
    .entity-card {
    	padding-left: 0;
    	padding-right: 0;
    }
	}
}
