/**
  * Harley Davidson Confirmation Page Overrides
  */
@import "../variables/bind.confirmation.variables.scss";

.customer-quote-container {

	.confirmation-container {

        .groups-container {
            background-color: $quote-container-bg;
            padding-left: $quote-side-margin;
            padding-right: $quote-side-margin;
        }

        a.policy-documents {
            text-decoration: $confirmation-policy-documents-button-text-decoration;
            cursor: pointer;

            &:hover,
            &:focus {
                text-decoration: $confirmation-policy-documents-button-text-hover-decoration;
            }
        }

        .confirmation-disclaimer-content hr {
            display: none;
        }

        .fa-circle-thin:before {
            font-size: $confirmation-stepnumber-circle-font-size;
            top: $confirmation-stepnumber-circle-top;
        }

		.confirmation-list-details {

			.list-group {

				.list-group-item {

					&.active {
						background: $confirmation-purchase-details-active-background;
						color: $confirmation-purchase-details-active-color;
                        border-radius: $confirmation-purchase-details-active-border-radius;
					}
				}

				.list-group-data-row {
					border-top: $confirmation-purchase-details-border-top;
					display: flex;
				}
			}
		}
		.confirmation-steps {

			.media {
                background: $confirmation-nextsteps-number-background;

				.media-left {
					align-self: center;
				}

				.media-body {
                    background: $confirmation-nextsteps-body-background;

					a {
						color: $confirmation-nextsteps-body-link-color;
					}
				}
			}
		}
		.confirmation-panel-section {

			.card {
				border-color: $confirmation-panels-border-color;
				border-radius : $confirmation-panels-border-radius;
				border-width : $confirmation-panels-border-width;
				margin-bottom: $confirmation-panels-margin-bottom;

				.card-header {
					color: $confirmation-panels-header-color;
					background: $confirmation-panels-header-background;
   				    text-transform: $confirmation-panels-header-text-transform;
					font-weight: $confirmation-panels-header-font-weight;
					padding: $confirmation-panels-header-padding;
				}

				.card-body {
					height: 100%;
					background: $confirmation-panels-body-background;
					color: $confirmation-panels-body-color;
					min-height: $confirmation-panels-body-min-height;

					a {

						&.id-cards {
							cursor: pointer;
					        text-decoration: $confirmation-panels-body-link-text-decoration;

                            &.disabled {
                                cursor: none;
                                text-decoration: none;
                            }
						}
					}
				}
			}
		}
	}
	.confirmation-success-check {
		color: $confirmation-success-check-color;

		.icon-checkmark {
			font-size: $confirmation-success-checkmark-font-size;
		}

		&.fa-stack {
			height: 2.5em;
		}
	}
}

.bill-reminder-checkbox-addon,
.paperless-checkbox-addon,
.docnotifications-checkbox-addon {
	border : $confirmation-account-signups-checkbox-border;
}